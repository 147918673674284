@import '../../assets/styles/partials/variables.scss';

/*********************
Bonus

#Form
#Dark Mode

*********************/

/*********************
Form
*********************/
.bonus_create{
    h4 {
        color: $cl-white;
    }
    form {
        padding: 25px;
        .form-row {
            margin-bottom: 24px;
        }
        label {
            max-width: 100% !important;
        }
        .table-data_name {
            width: 55%;
            span {
                width: 80%;
                display: inline-block;
                line-height: 1.3rem;
            }
            p {
                line-height: 1.2rem;
            }
        }
        .deal-deck-wrapper {
            > div {
                margin: 25px 30px;
                padding: 16px;
                border: 5px dotted $cl-gray;
            }
            .bonus-deck {
                margin: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                .bonus-card  {
                    margin: 0 0 16px;
                }
            }
            .deal_preview{
                background-color: rgba($color: $cl-black, $alpha: 0.1);
                border-radius: 5px;
                padding: 10px;
                .deal_additiona-info {
                    display: flex;
                    align-items: center;
                    > div {
                        display: flex;
                        align-items: center;
                        strong {
                            margin-right: 10px;
                        }
                    }
                }
                img {
                    width: 100%;
                    max-width: 80px;
                }
                div {
                    position: relative;
                    display: inline;
                    margin-right: 16px;
                    font-size: 0.75rem;
                    // &:after {
                    //     content: "";
                    //     position: absolute;
                    //     top: 0;
                    //     right: -8px;
                    //     display: block;
                    //     width: 1px;
                    //     height: 100%;
                    //     background-color: rgba($color: $cl-white, $alpha: 0.5);
                    // }
                    &:last-child:after {
                        display: none;
                    }
                }
                .media-body {
                    color: $cl-light-gray;
                    h6 {
                        margin-bottom: 5px;
                        font-size: 0.9375rem;
                        font-weight: bold;
                    }
        
                    p {
                        font-size: 0.8125rem;
                        line-height: 0.9375rem;
                        color: #9D9D9D;
                    }
                }
                
                min-height: 100px;
                padding: 20px;
                border: none;
                .media-body {
                    max-width: auto;
                }
            }
        }
    }

    .bonus_deal-look-up {
        margin-bottom: 24px;
        .dropdown-search {
            width: 95%;
            margin: 20px 1.5rem;
            margin-bottom: 20px;
            color: $cl-white;
        }
        #dropdown-custom-components:not(.btn-link),
        #dropdown-custom-components:not(.btn-link):hover,
        #dropdown-custom-components:not(.btn-link):active {
            width: 100%;
            border-color: #434351;
            color: #ffffff;
            background-color: #2c2e37;
            border-radius: 0.25rem !important;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 15px;
            border: 1px  solid  #434351;;
            box-shadow: none;
            overflow: hidden;
    
        }
        .dropdown-menu.show {
            height: 400px;
            width: 100%;
            overflow: auto;
            background-color: lighten($color:  $cl-light-purple, $amount: 5%);
            box-shadow: #000000 0px 13px 25px;
            border: 0;
            margin-top: 15px;
        }
        .dropdown-item {
            display: flex;
            overflow: hidden;
            // margin-bottom: 24px;
            padding: 20px 30px 24px;
            &:nth-child(odd) {
                background-color: rgba($color: $cl-dark-purple, $alpha: 0.4);
            }
            &:hover {
                background-color: rgba($color: $cl-white, $alpha: 0.09);
            }
            .deal_image {
                background: black;
                width: 50px;
                height: 50px;
                margin-right: 18px;
                background-size: cover;
                background-repeat: no-repeat;
            }
            .deal_content {
                width: 90%;
                font-size: 0.9375rem;
                color: $cl-light-gray;
                p {
                    margin-bottom: 5px;
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    font-weight: 500;
                    color: $cl-white;
                }
                .deal_content-information {
                    font-size: 14px;
                    small {
                        float: left;
                        margin: 0 10px;
                        // border-right: 1px solid $cl-light-gray;
                        .flag-container i {
                            font-size: 27px;
                        }
                        &:first-child {
                            margin-left: 0;
                        }
                    }
                }
            }
        }
        input {
            width: 100%;
        }
    
    }

}


/*********************
Form
*********************/
.admin_wrapper--dark { 
    .bonus_create{
        form {
            .deal_preview {
                
                background-color: rgba($color: $cl-white, $alpha: 0.1);
            }
        }
    }  
}
