/*********************
Bootstrap
*********************/
@import "../../../../node_modules/bootstrap/scss/functions";
@import "../../../../node_modules/bootstrap/scss/_variables";
@import "../../../../node_modules/bootstrap/scss/mixins/_breakpoints";

/*********************
Font-Family
*********************/
// @import url('https://fonts.googleapis.com/css?family=Montserrat:400,500,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800&display=swap');


/*********************
Colors
*********************/
$bg-color: blue;
$cl-blue: #0984e3;
$cl-light-blue: #E6F4FA;
$cl-green: #639F42;
$cl-light-green : lighten($cl-green, 25%);
$cl-dark-green : darken($cl-green, 10%);
$cl-orange: #eca52f;
$cl-gray: #7D7D7D;
$cl-light-gray: #e2e2e2;
$cl-black: #313131;
$cl-white: #ffffff;
$cl-red : #d63031;
$cl-yellow : #FFCF5C;
$cl-dark-yellow : darken($color: $cl-yellow, $amount: 20%);



$cl-white-86: lighten(black, 87%);
$cl-white-60: lighten(black, 60%);
$cl-white-38: lighten(black, 38%);

$cl-dark-purple : #212229;
$cl-light-purple : #2c2c35;


$cl-disabled: lighten($color: $cl-gray, $amount: 35%);
$link-hover : darken($cl-light-green, 40%);
$cl-border : lighten($cl-gray, 45%);
$box-shadow-hover:  rgba(45,45,45,0.06) 0px 2px 2px, 
                    rgba(49,49,49,0.06) 0px 4px 4px, 
                    rgba(42,42,42,0.06) 0px 8px 8px,
                    rgba(32,32,32,0.06) 0px 16px 16px, 
                    rgba(49,49,49,0.06) 0px 32px 32px,
                    rgba(35,35,35,0.06) 0px 64px 64px;
$box-shadow:   rgba(45, 45, 45, 0.2) 0px 7px 10px;


/*********************
Custom Breakpoints
*********************/

$screen-sm-min: 800px;
$screen-xs-max: ($screen-sm-min - 1);
$screen-md-min: 992px;
$screen-sm-max: ($screen-md-min - 1);
$screen-lg-min: 1200px;
$screen-md-max: ($screen-lg-min - 1);


// Small devices
@mixin sm {
    @media (min-width: #{$screen-sm-min}) {
        @content;
    }
}
 
 // Medium devices
@mixin md {
    @media (min-width: #{$screen-md-min}) {
        @content;
    }
}
 
 // Large devices
@mixin lg {
    @media (min-width: #{$screen-lg-min}) {
        @content;
    }
}
 
 // Extra large devices
@mixin xl {
    @media (min-width: #{$screen-xl-min}) {
        @content;
    }
}

// Custom Width
@mixin media-breakpoint-custom-width($screen) {
    @media (min-width: $screen+'px') {
        @content;
    }
}

// Small height
  @mixin media-breakpoint-short-height {
    @media (max-height: $screen-sm-min) {
        @content;
    }
}