@import '../../assets/styles/partials/variables.scss';

/*********************
Bonus Manage

#Table

*********************/

/*********************
#Table
*********************/

.bonus_manage table{
    th {
        width: auto !important;
    }
    td {
        width: auto;
        vertical-align: top !important;
        
        &:not(.table-data_description) {
            padding-top: 40px;
        }
        .btn-sm {
            padding: 0px 20px;
            margin-left: 5px;
            font-size: 0.75rem;
        }
        &.table-data_link {
            button {
                font-size: 13px;
                margin-right: 0;
                padding: 0.3125rem 1.25rem;
            }
        }
        &.table-data_created-at {
            span {
                display: block;
                &:last-child {
                    opacity: 0.4;
                }
            }
        }
        &.table-data_button {
            .btn {
                margin: 0
            }
        }
        &.table-data_notes {
            min-width: 200px;
            // text-align: center;
            .data_notes-wrapper {
                background-color: rgba($color: #ffffff, $alpha: 0.1);
                margin-bottom: 10px;
                text-align: left;
                padding: 10px;
                opacity: 0.4;
            }

            &.--empty {
                .data_notes-wrapper {
                    display: none;
                }
            }
        }
        &.table-data_description {
            max-width: 400px!important;
            .description-text {
                font-size: 0.75rem;
                margin-bottom: 16px;
                letter-spacing: 1px;
                max-height: 200px;
                overflow: auto;
                cursor: pointer;
                &:active {
                    background-color: rgba($color: $cl-light-purple, $alpha: 1.0);
                }
            }
            .description-extra {
                margin-bottom: 5px;
            }
            .star-rating-container {
                label {
                    margin-right: 2px;
                }
            }
            em {
                opacity: 0.3;
            }
        }
    }
 
    .data-profile_user {
        .data-profile_user-detail {
            margin-bottom: 15px;
            .user-name { 
                a {
                    opacity: 1;
                }
            }
        }
        .data-profile_additional-info {
            li.amz {
                font-size: 12px !important;
                text-transform: capitalize!important;
            }
        }
    }
}

.copy-paste_alert {
    position: fixed;
    background-color: $cl-green;
    right: 10px;
    bottom: 10px;
    min-width: 100px;
    padding: 10px 20px;
    color: white;
    border-radius: 5px;
    &.--hide {
        display: none;
    }
}