@import '_variables.scss';

.questionnaire {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    // margin-top: 100px;
    margin-bottom: 100px;
    height: auto;
    @include media-breakpoint-up(md) { 
        margin-bottom: 0;
        padding: 0;
    }
    @include media-breakpoint-up(xl) { 
        margin-top: 80px;
        height: 87vh;
    }
    @include media-breakpoint-short-height {
        // margin-top:100px;
        height: 100%;
    }
    @media 
    (-webkit-min-device-pixel-ratio: 2), 
    (min-resolution: 192dpi) { 
        height: 100%;
    }
    
    &.loading {
        height: 70vh;
    }
    h1 {
        color: $cl-green;
        font-weight: 800;
        margin-bottom: 0;
        font-size: 1.5625rem;
        line-height: 2rem;
        @include media-breakpoint-up(md) { 
            margin-bottom: 16px;
            font-size: 1.875rem;
            line-height: 2.1875rem;
        }
    }

    .btn.purchase_cta-button {
        font-size: 11px;
        color: white;
        font-weight: 600;
        padding: 5px 20px;
        pointer-events: none;
        &.--AMAZON {
            background: #f4d078;
            background: -webkit-linear-gradient(top,#f7dfa5,#f0c14b);
            background: linear-gradient(to bottom,#f7dfa5,#f0c14b);
            border:1px solid #a88734;
            font-weight: 400;
            color:  #111111;
            text-transform: capitalize;
            position: relative;
            min-width: 130px;
            padding-left: 30px;
            &:before {
                content: "";
                position: absolute;
                left: 5px;
                background-position: center;
                background-color: #333e46;
                background-image: url("https://i.imgur.com/cm8qsX0.png");
                background-repeat: no-repeat;
                width: 15px;
                height: 15px;
                background-size: contain;
                border-radius: 4px;
                border: 1px solid  #333e46;
            }
        }
        &.--TARGET {
            border-radius: 4px;
            background-color: rgb(204, 0, 0);
            text-transform: capitalize;
        }
        &.--WALMART {
            background-color: #0072CE;
            text-transform: capitalize;
            border-radius: 3rem;
        }
        &.--IHERB {
            background-color: #f38a00;
            background-image: linear-gradient(to bottom,#f38a00 0,#ef6c00 100%);
            border-radius: 5px;
        }

        &.--ANTHROPOLOGIE {
            letter-spacing: .24rem;
            
            text-transform: uppercase;
            cursor: pointer;
            font-weight: 500;
            background-color: #4b5666;
        }
    }

    form {
        @include media-breakpoint-up(md) {
            padding-right: 20%;
        }
        .form-label{
            color: $cl-gray;
            font-weight: 500;
            margin: 10px 0 5px;
            font-size: 1rem;
            width: 100%;
        }
        textarea {
            &::placeholder { 
                color:$cl-light-gray;
                opacity: 1; 
                }
              
            &:-ms-input-placeholder {
                color:$cl-light-gray;
                }
              
            &::-ms-input-placeholder {
                color:$cl-light-gray;
                }
            min-height: 150px;
        }
        .star-rating-label {
            .fa {
                font-size: 1.875rem;
                // color: $cl-gray;
                border: $cl-gray;
                margin-right: 5px;
            }
            .fa-star {
                color: $cl-yellow;
            }
        }
    }
    

}