@import '../../assets/styles/partials/variables.scss';

/*********************
Bonus

#General
#Point status
#Section : How To
#Section : FAQ
*********************/

/*********************
General
*********************/
.page_bonus {
    main {
        max-width: 100%;
        padding: 0;
        margin-bottom: 70px;
    }
}

/*********************
#Point status
*********************/
.page_bonus .point-status {
    box-shadow: $box-shadow;
}

/*********************
Section : How To
*********************/
.how-to {
    background: linear-gradient(180deg, $cl-white 65%, lighten($cl-gray, 55%)65%);
}

.video-player {
    background-color: $cl-gray; 
    box-shadow: 0px 95px 35px -77px rgba(0, 0, 0, 0.35);
    height: 500px;
    margin: auto; 
    width: 100%;
}

/*********************
Section : FAQ
*********************/
.faq {
    background: lighten($cl-gray, 55%);
    padding: 40px 0 20px ;
    
    .accordion {
            padding: 20px;
   
    }
}