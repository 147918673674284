@import '../../assets/styles/partials/_variables.scss';

/*********************
Page Review

#General
#Review 1
#Review 2
#Review 3
*********************/

/*********************
#General
*********************/

.page_review footer {
    margin-top: 20px;
    position: relative;
}



.page_review {
    // .questionnaire { 
    //     @include media-breakpoint-up(xl) {
    //         height: 86vh;
    //     }
    // }
    .btn-link.skip-btn {
        color: $cl-green;
        float: right;
        font-size: 14px;
        padding: 0;
        margin: 10px 0;
        text-decoration: underline;
        text-align: right;
    }
    p.subtitle {
        margin-bottom: 6px;
        font-weight: 500;
    }
    .point_status-wrapper {
        display: inline;
        > span {
            font-weight: bold;
        }
    }
    .product-image {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        .product-image_description {
            background-color: #F8F8F8;
            font-size: 12px;
            font-weight: normal;
            line-height: 19px;
            padding: 16px;
            margin-bottom: 40px;
            strong {
                display: block;
            }
        }
        img {
            padding: 20px;
            max-width: 40%;
            @include media-breakpoint-up(md) {
                max-width: 100%; 
                // width: 100%;
            }
        }
        @include media-breakpoint-up(md) {
            margin-bottom: 0;
            padding: 40px;
        }
    }
    .star-rating-container {
        label {
            margin-right: 5px;
        }
    }
}



/*********************
#Review 1
*********************/
.page_review .point-status {
    display: inline-block;
    border: none;
    padding: 0;
    margin: 0 0 5px;
    &.point-status_step-2 {
        display: flex;
        align-items: center;
        .additiona-points_title {
            color: $cl-green;
            margin-right: 10px;
        }
        .additional-points_value {
            color: $cl-green;
        }
    }
    .point_status-wrapper {
        justify-content: flex-start;
        color:$cl-light-gray;
    }
    .icon-points {
        font-size: 1.5625rem;
        margin-right: 5px;
    }
   
}

.review-1 {
    height: 82vh;
    .btn-group-toggle {
        label {
            margin: 0 !important;
            &:first-child {
                border-top-right-radius: 0 !important;
                border-bottom-right-radius: 0 !important;
            }
            &:last-child {
                border-top-left-radius: 0 !important;
                border-bottom-left-radius: 0 !important;
            }
        }
    }
    .step_help {
        margin: 15px 0 35px ;
    }
}

/*********************
#Review 2
*********************/
body {
    counter-reset: section; 
}
.review-2 {
    form {
        padding: 0;
     
    }
    .brands {
        display: inline-block;
        padding-left: 0;
        margin-left: 0;
        list-style-type: none;
        margin-bottom: 16px;
        li {
            float: left;
        }
    }

    .review_have-you-submitted {
        display: flex;
        align-items: center;
        margin-bottom: 70px;
        margin-top: 10px;
        .form-check {
            margin-right: 45px;
        }
    }
  
    // .form-group {
     
    //     &.share_review_step-1 {
    //         margin-bottom: 20px;
    //     }
    //     &.share_review_step-2 {
    //         margin-bottom: 30px;
    //         display: flex;
    //         align-items: center;
    //         justify-content: space-between;
    //         label {
    //             width: 60%;
    //             padding-right: 5px;
    //             float: left;
    //         }
    //         .btn--amazon {
    //             display: inline-block;
    //             border-color: transparent;
    //             background: #f4d078;
    //             // background: -webkit-linear-gradient(top,#f7dfa5,#f0c14b);
    //             // background: linear-gradient(to bottom,#f7dfa5,#f0c14b);
    //             font-weight: 500;
    //             width: 100%;
    //             max-width: 180px;
    //             &:hover {
    //                 background: darken($color: #f4d078, $amount: 10%)
    //             }
    //         }
          
    //     }
    //     p {
    //         font-size: 0.875rem;
    //         line-height: 1.2rem;
    //     }
    // }
    // .form_step label::before{
    //     counter-increment: section;
    //     content: counter(section) ". ";
    // }
    // .btn--copy {
    //     float: right;
    //     position: absolute;
    //     border-top-left-radius: 0;
    //     border-top-right-radius: 0;
    //     border-bottom-left-radius: 0;
    //     padding: 7px 10px;
    //     font-weight: 500;
    //     color: $cl-white;
    //     // background: transparent;
    //     font-size: 9px;
    //     display: flex;
    //     align-items: center;
    //     svg {
    //         font-size: 13px;
    //     }
    //     bottom: 0;
    //     right: 0;
    //     &:hover,
    //     &:focus,
    //     &:active {
    //         background-color: $cl-dark-green;
    //         color: $cl-white;
    //     }
    // }
    // .copy-text_wrapper {
    //     position: relative;
    //     textarea {
    //         font-size: 0.875rem;    
    //     }
    //     .copy-text_button-wrapper {
    //         transition: all 2s ease;
    //         display: none;
    //         position: absolute;
    //         top: 0;
    //         left: 0;
    //         width: 100%;
    //         height: 100%;
    //         align-items: center;
    //         justify-content: center;
            
    //         &.copy-text_button-wrapper--show {
    //             display: flex;
    //             background : rgba(255,255,255, 0.5);
    //         }

    //         .share_review_alert-msg {
    //             font-weight: 400;
    //             font-size: 20px;
    //             margin-bottom: 0;
    //         }
    //     }
    // }


}

/*********************
#Review 4
*********************/
.review-4 {
    &.questionnaire {
        margin-top: 50px;
        height: initial;
        // @include media-breakpoint-up(lg) {
        //     margin-top: 100px;
        // }
    }
    .content-header {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin-bottom: 24px;
        padding: 30px ;
        @include media-breakpoint-up(lg) {
            padding: 10px 200px;
        }
    }
    .important-msg {
        color: $cl-gray;
        padding: 24px 0 ;
        font-size: 14px;
        font-weight: normal;
        font-style: italic;
        width: 100%;
        display: block;
        text-align: center;
        border-top: 1px solid $cl-light-gray;
        border-bottom: 1px solid $cl-light-gray;
        margin: auto 19% 40px;
    }
    .scroll-to {
        text-align: left;
        padding-left: 0;
        // margin-top: 24px;
        padding: 0;
        letter-spacing: 0.3px;
        font-size: 14px;
        font-style: italic;
    }
    .instruction {
        .row {
            margin-bottom: 40px;
            @include media-breakpoint-up(lg) {
                margin-bottom: 100px;
            }
        }
        .instruction_image {
            padding: 10px;
            box-shadow: 0px 14px 22px rgba(0, 0, 0, 0.08);
            img {
                width: 100%;
            }
        }
        .instruction_content {
            position: sticky;
            top: 100px;
            padding-right: 10%;
            h5 {
                font-weight: 900;
                margin-bottom: 16px;
            }
            p {
                margin-bottom: 8px;
            }
            color: $cl-gray;
        }

        .instruction_content-inspiration {
            font-size: 14px;
            line-height: 29px;
            letter-spacing: 0.03em;
            color: #818181;
            margin-bottom: 10px;
            h6 {
                margin-bottom: 16px;
                margin-top: 24px;
                color: #81a1c1;;
                font-style: italic;
            }
            .inspirational-text {
                background-color: #e5eff9;
                padding: 20px;
                max-height: 300px;
                overflow: auto;
                border-radius: 10px;
                padding: 20px 20px 35px;
                p {
                    font-weight: 600;
                    font-size: 15px;
                    margin-bottom: 10px;
               
                }
                blockquote {
                    font-style: italic;
                    font-style: 16px;
                    font-weight: 400;
                    font-weight: 400;
                    word-wrap: break-word;
                }
            }
        }
     
        .instruction_content-navigation {
            display: flex;
            align-items: center;
            flex-direction: column;
            margin: 24px auto 60px;
            .btn-submit {
                min-width: 300px;
                display: block;
            }
        }

        &.share-through-amazon {
            padding-top: 40px;
            border-top: 1px solid $cl-light-gray;
            display: none;
            @include media-breakpoint-up(lg) {
                padding-top: 100px;
            }
            &.--show {
                display: block;
            }
        }
    }
}

/*********************
#Review 5
*********************/
.review-5 { 
    &.questionnaire {
        height: 85vh;
    
    }
    section {
        max-width: 500px;
    }
    .point-status {
        margin: 0 0 25px;
        .point_status-wrapper {
            justify-content: center;
            > span {
                color: $cl-green;
                font-weight: 500;
                font-size: 1.25rem;
            }
        }
    }
    .bonus-review_footer {
        margin: auto;
        display: flex;
        flex-direction: column;
        max-width: 400px;
        a {
            display: inline-block;
        }
        .btn-link {
            margin-top: 20px;
        }
    }
    .disclaimer {
        margin-bottom: 42px;
    }
    .point_status-wrapper {
        display: inline-block;
    }
}