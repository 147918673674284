@import '../../assets/styles/partials/_variables.scss';

/*********************
Mobile Nav

#General
#Dark Mode
*********************/

/*********************
General
*********************/
nav.nav-mobile {
    background-color: transparent;
    justify-content: end;
    border: none;
    &.nav-mobile--expanded {
        box-shadow: rgba(2, 2, 2,0.2) 0px 32px 23px;
    }
    .navbar-toggler {
        padding-left: 5px;
        border: 0;
        .navbar-toggler-icon {
            &:after,
            &:before {
                content: '';  
                background-image: none;
                background-color: $cl-dark-purple;
                border-radius: 3px;
                display: block;
                height: 5px;
                margin: 7px 0;
                transition: all .2s ease-in-out;
            }  
            &::before {
                margin-top: 5px;
            }  
        }
        &:focus {
            outline: none;
        }
    }
    .navbar-collapse  {
        padding: 20px 7px;
        h6 {
            font-weight: normal;
        }
        .list-group-item {
            background: transparent;
            border: none;
            padding:0;
            &.active {
                background-color: $cl-dark-purple;
                padding-left: 20px;
                &::before {
                    content: "";
                    position: absolute;
                    width: 10px;
                    height: 100%;
                    top: 0;
                    left: 0;
                    background-color: $cl-green;
                }
            }
            a {
                display: inline-block;
                width: 100%;
                font-size: 1rem;
                padding: 7px 0;
                &:hover {
                    text-decoration: none;
                }
            }
            &:last-child {
                margin-bottom: 16px;
            }
        }
    }
}


/*********************
General
*********************/
nav.nav-mobile.nav-mobile--dark-mode {
    background-color: $cl-light-purple;
    .navbar-brand {
        color: $cl-light-gray;
        text-transform: capitalize;
    }
    .navbar-toggler {
        .navbar-toggler-icon {
            &:after,
            &:before {
                background-color: #fff;
            }    
        }
        &:focus {
            outline: none;
        }
    }
    .navbar-collapse  {
  
        .list-group-item {
            a {
                color: $cl-white;
            }
           
        }
    }
}