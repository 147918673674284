@import '../../assets/styles/partials/variables.scss';

/*********************
Admin

#General
#Button
#Table Search
#Table
#Table : Manage Invite
#Table : Bonus Invite
#Form
#Email
#Tooltip
#Page : Export
#Dark Theme
    - General 
    - Button
    - Table
    - Form
*********************/

/*********************
General
*********************/
$animationTiming : 0.3s;

.page_admin { 
    html {
        overflow: scroll;
        overflow-x: hidden;
    }
    ::-webkit-scrollbar {
        width: 5px;  /* Remove scrollbar space */
        background: transparent;  /* Optional: just make scrollbar invisible */
    }
    /* Optional: show position indicator in red */
    ::-webkit-scrollbar-thumb {
        background: rgba($color: white, $alpha: .3);
    }

    font-size: 12px;
    letter-spacing: 1.5px;
    font-family: 'Montserrat', sans-serif;
    main {
        margin: 0;
        padding: 60px 20px 20px;
        @include media-breakpoint-up(md) { 
            padding: 20px 15px;
            height: auto;
            // height: 100vh;
        }
        background-color: #efefef;
        section:not(.deal_create) {
            overflow: auto;
        }
    }
    h5 {
        color: $cl-light-green;
        margin-bottom: 16px;
    }
    .alert {
        text-transform: capitalize;
    }
    .deal_create_product-image-tool-tip {
     
        .tooltip-inner {
            display: flex;
            padding: 15px;
            max-width: initial;
            img {
                margin: 5px;
                max-width: 214px;
            }
          
        }
    }
    .star-rating-container {
        font-size: 1.3rem;
    }
    .auto-email-invite-header {
        display: flex;
        align-items: center;
        .auto-review-toggle {
            margin-top: 0;
        }
    }
    .toggle-switch {
        margin-left: 24px;
        .react-switch-bg {
            background-color: rgba(0, 0, 0, 0.2) !important;
        }
        &.--checked {
            .react-switch-bg {
                background-color: $cl-green !important;
            }
            .react-switch-handle {
                background-color: white !important;
            }
        }
    }
    .badge {
        color: $cl-light-purple;
        font-weight: 500;
        padding: 0.3125rem 0.625rem;
        text-transform: capitalize;
        // border-radius: 50px;
        &.badge-success {
            // padding-left: 5px;
            background-color: lighten($color: $cl-light-green, $amount: 5%);
            color: darken($color: $cl-green, $amount: 20%);
            // &:before {
            //     content: "";
            //     width: 10px;
            //     height: 10px;
            //     margin-right: 5px;
            //     border-radius: 50%;
            //     background-color: darken($color: $cl-green, $amount: 20%);
            //     display: block;
            //     float: left;
            // }
        }
        &.badge-warning {
            // padding-left: 5px;
            background-color: lighten($color: $cl-orange, $amount: 5%);
            color: darken($color: $cl-orange, $amount: 30%);
            // &:before {
            //     content: "";
            //     width: 10px;
            //     height: 10px;
            //     margin-right: 5px;
            //     border-radius: 50%;
            //     background-color: darken($color: $cl-orange, $amount: 30%);
            //     display: block;
            //     float: left;
            // }
        }
    }
    section {
        // overflow: auto;
        transition: all $animationTiming ease;
        border-radius: 5px;
        box-shadow: 0 15px 20px -10px rgba(0, 0, 0, 0.1);
        padding: 10px;
        height: auto;
        margin-bottom: 40px;
        
        @include media-breakpoint-up(lg) { 
            padding: 20px 10px;
        }
        background-color: $cl-white;
        .section-header {
            display: flex;
            padding: 8px 10px 21px;
            justify-content: space-between;
            .header-title {
                font-weight: normal;
                font-size: 28px;
            }
            h4 {
                color: $cl-gray;
                
            }
            .table-search_wrapper {
                .label {
                    text-align: right;
                    width: 200px;
                }
                .custom-select {
                    width: 100%;
                }
            }
        }
        &.--edit-mode {
            margin-bottom: 140px;
            .controller{
                position: fixed;
                bottom: -94px;
                left: 0;
                width: 100%;
                background: $cl-green;
                padding: 20px 10%;
                display: flex;
                justify-content: flex-end;
                transition:  all 0.09s ease-in-out;
                .btn-success {
                    color: $cl-green;
                    background: white;
                }
                .btn-cancel {
                    color: $cl-white;
                    
                }
                &.--edit-changes {
                    bottom: 0;
                }
            }
        }
    }
    .admin_wrapper {
        transition: all $animationTiming ease;
        margin: 0;
    }
    .admin_sidebar {
        position: sticky;
        top: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 0;
        height: 100vh;
        overflow: auto;
        ::-webkit-scrollbar {
            width: 0px;  /* Remove scrollbar space */
            background: transparent;  /* Optional: just make scrollbar invisible */
        }
        /* Optional: show position indicator in red */
        ::-webkit-scrollbar-thumb {
            background: white;
        }
    }
    .admin_subpage {
        main {
            padding: 20px 15px ;
        }
        .sub-page_breadcrumb {
            margin-top: 12px;
            margin-bottom: 5px;
        }
        h2 {
            font-size: 1.5625rem;
            margin-bottom: 24px;
        }
        .admin_sidebar {
            position: -webkit-sticky; 
            position: sticky;
            top: 0;
            background-color: lighten($color: $cl-light-gray, $amount: 30%);
            box-shadow: inset -15px 0 30px -12px rgba(000, 000, 000, 0.2);
        }
        .toggle_switch_label{
            display: none
        }
    }
    .spinner {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 80vh;
    }
}

/*********************
#Button
*********************/
.page_admin .btn:not(.btn-link) {
    border-radius: 100px;
    padding: 0.9375rem 2.5rem;
    margin-right: 20px;
    transition: all $animationTiming ease;
    &.btn-sm {
        padding: 0.25rem 1.5rem;
        font-size: 0.75rem;
    }
    &:hover  {
        box-shadow: 0 11px 13px -7px rgba(77, 123, 51, 0.5);
    }
    &.btn-danger {
        &:hover  {
            box-shadow: 0 11px 13px -7px rgba(244, 67, 54, 0.33);
        } 
    }
    &.btn-link {
        color: $cl-white;
    }
    // &.btn-primary {
    //     &:hover:active  {
    //         background: #0a519e;
    //     } 
    // }
    &.btn-success {
        padding: 0.9375rem 2.5rem ;
        &.btn-sm {
            padding: 0.425rem 1.4625rem;
        }
    }
    .fa-sm {
        margin-right: 8px;
    }
    &.btn-outline-success {
        border-color: $cl-green;
        color: $cl-green;
    }

}

.page_admin .btn-outline-dismiss {
    border: none;
    color: $cl-light-gray;
    font-weight: bold;
    padding: 0.9375rem 1rem;
    &:hover {
        background-color: transparent;
        color: $cl-white;
        text-decoration: underline;
        box-shadow: none !important;
    }
}
/*********************
#Table Search
*********************/
.table-search_wrapper {
    position: relative;
    margin:6px 0 30px;
    &.--no-search {
        display: flex;
        justify-content: flex-end;
        .custom-select {
            min-width: 150px;
        }
        .table-search_filter-wrapper {
            margin-right: 15px;
            flex-direction: column;
            align-items: flex-start;
            &:last-child {
                margin-right: 0;
            }
            span {
                width: 100%;
                margin-bottom: 5px;
            }
        }
    }
    i {
        position: absolute;
        top: 18px;
        right: 20px;
        color: $cl-gray;
    }
    .table-search_input {
        border: none;
        margin-bottom: 0;
        background-color: rgba($color: $cl-light-gray, $alpha: .2);
        border-radius: 50px;
        padding: 1.875rem 1.25rem;
        transition: all 0.1s ease-in ;
        &:focus {
            transform: scale(1.01, 1.01);
            color: $cl-black;
            background-color: rgba($color: $cl-white, $alpha: 0.9);
            box-shadow: 0 15px 20px -10px rgba(0, 0, 0, 0.2);
        }
    }
    .table-search_filter-wrapper {
        display: flex;
        font-size: 12px;
        font-weight: 300;
        justify-content: flex-end;
        align-items: center;
        padding: 20px 5px 0 ;
        >  span {
            margin-right: 10px;
        }
        $selectWidth : 15%;
        .custom-select {
            position: relative;
            width:  $selectWidth;
            padding: 0;
            background: none;
            border: none;
           
        }
          
        .custom-select select {
            text-transform: capitalize;
            // background-color: $cl-light-purple;
            color: white;
            border: none;
            font-size: 0.875rem;
            box-shadow: 0 5px 25px rgba(0, 0, 0, 0.2);
            -webkit-appearance: button;
            appearance: button;
            outline: none;
            border-radius: 50px; 
            overflow: hidden;
            font-weight: 500;
            background-color: rgba(255, 255, 255, 0.1);
            padding: 0 10px;
            option {
                padding: 10px 0;
                background-color: $cl-light-gray;
                color: $cl-black;
            }
            // padding: 1.875rem 1.25rem;
        }
          
          .custom-select::before {
            // content: "\f13a";
            // font-family: FontAwesome;
            position: absolute;
            top: 0;
            right: 5px;
            width:  100%;
            text-align: right;
            font-size: 24px;
            line-height: 35px;
            color: rgba(255, 255, 255, 0.5); 
            pointer-events: none;
            border-radius: 50px;
            padding-right: 10px;
          }
          
          .custom-select:hover::before {
            color: rgba(255, 255, 255, 0.6);
            background-color: rgba(255, 255, 255, 0.2);
          }
          
          .custom-select select option {
            padding: 30px;
          }
    }

    
}

/*********************
#Table
*********************/
.page_admin .table {
    margin-bottom: 0;
    &.-highlight {
        tbody {
            tr:hover {
                background-color: lighten($color:  $cl-light-gray, $amount: 30%);
                cursor: pointer;
            }
        }
    }
    thead {
        background-color: transparent;
        border: 0 ;
        border-radius: 5px;
        th {
            // max-width: 40%;
            width: auto;
            color: $cl-light-gray;
            border: 0;
            opacity: .8;
            text-transform: uppercase;
            font-size: 0.75rem;
            font-weight: 400;
        }
    }
    tbody {
        transition: all $animationTiming ease;
        td {
            width: auto;
            font-size: 0.75rem;
            line-height: 1.25rem;
            color: $cl-gray;
            border-color: lighten($color: $cl-light-gray, $amount: 30%);
            vertical-align: middle;

        }
    }
    tfoot {
        th {
            &.load-more_wrapper {
                text-align: center;
                button {
                    margin: 0;
                }
                p {
                    opacity: 0.6;
                    font-size: 12px;
                    margin-top: 10px;
                }
            }
        }
    }
    
    .table-data_analysis {
        // display: flex;
        // flex-direction: column;
        // text-align: center;
        .btn-link {
            color: #6c757d;
            text-decoration: underline;
            font-size: 10px;
            display: block;
            width: 100%;
            margin: auto;
        }
    }
    .table-data_action-bar {
        min-width: 80px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        font-size: .875rem;
        a,
        .btn-link {
            color: $cl-light-gray;
            margin-left: 6px;
            > span {
                color: $cl-light-green;
                display: inline-block;
                position: relative;
            }
            &:hover > span {
                color: darken($cl-green, 10%);
            }
        }
        .btn-link {
            margin-right: 2px;
            display: inline-block;
            border-radius: 0;
            padding: 0;
            &:hover {
                box-shadow: none;
            }
        }
        .badge {
            background-color : $cl-light-blue;
            font-size: 0.5625rem;
            font-weight: normal;
            position: absolute;
            top: -7px;
            right: -10px;
            width: 20px;
            z-index: 1;
            display: flex;
            height: 20px;
            padding: 0;
            text-align: center;
            color: $cl-black;
            align-items: center;
            justify-content: center;
      
        }
        .action-bar_extra {
            .btn-link {
                font-size: 0.8125rem;
                background-color: transparent;
                margin: 0 0 0 13px;
                &::after {
                    display: none;
                }
            }
            .dropdown-menu {
                padding: 0;
                border-radius: 0;
                .dropdown-item {
                    margin: 0;
                    font-size: 0.875rem;
                    padding: 10px 20px;
                    border-radius: 0;
                    color: $cl-gray;
                    &:hover,
                    &:active {
                        border-radius: 0;
                        background-color: lighten($color: $cl-light-gray, $amount: 30%);
                        box-shadow: none;
                    }
                    &.delete {
                        color: $cl-red;
                    }
                    svg {
                        margin-right: 5px;
                    }
                }
                .dropdown-divider {
                    margin: 0;
                }
            }
        }
    }
    // Table Data
    .table-data_live_amount {
        button {
            position: relative;
            font-size: 0.75rem;
            color:$cl-white;
            padding: 0 0 5px;
            &:hover {
                text-decoration: none;
                &:after {
                    border-bottom: 2px dotted rgba($color: $cl-white, $alpha: .9);
                }
                &.--error:after {
                    border-bottom: 2px dotted rgba($color: $cl-red, $alpha: .9);
                }
            }
            &:after {
                content: "";
                position: absolute;
                width: 100%;
                left: 0;
                bottom: 0;
                border-bottom: 2px dotted rgba($color: $cl-white, $alpha: .4);
            }
            &.--error:after {
                border-bottom: 2px dotted rgba($color: $cl-red, $alpha: .4);
            }
        }
    }

    .table-data_name {
        // width: 100%;
        max-width: 320px;
        p.small {
            margin-top: 5px;
            font-size: 0.6875rem;
            line-height: 1rem;

            a {
                color: $cl-white-60;
                font-size: 0.625rem;
                &:hover {
                    color: $cl-green;
                    text-decoration: underline;
                }
            }
        }
        p:first-child {
            margin-bottom: 0;
            width: 200px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
    .table-data_retail-logo {
        text-align: center; 
        img {
            max-width: 50px;
            background-color: white;
            padding: 6px;
            border-radius: 4px;
        }
    }
    .flag-container {
        margin-top: 7px;
        i {
            font-size: 35px;
        }
    }
    .table-date {
        min-width: 120px;
    }
    .table-data_asin {
        a {
            font-size: 10px;
            color: $cl-gray;
            &:hover {
                color: $cl-light-green
            }
        }
    }
    .table-data_street-light {
        div {
            margin: auto;
            border: 1px solid $cl-dark-purple;
            background-color: $cl-light-purple;
            height: 20px;
            width: 20px;
            border-radius: 50%;
        }
        &.--on {
            div {
                background-color: $cl-green;
                border: 1px solid transparent;
            } 
        }
    }
    .table-data_status {
        text-align: center;
        .coming-soon {
            margin-top: 2px;
            font-size: 8px;
            opacity: .7;
            display: block;
        }
    }

    .data-profile {
        // max-width: 250px;
        min-width: 250px;
        display: flex;
        p {
            line-height: 13px;
            word-break: break-all;
        }
        a {
            opacity: 0.5;
            &:after {
                height: 0 !important;
            }
        }
        .data-profile_img {
            position: relative;
            width: 70px;
            height: 70px;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            border-radius: 50%;
            margin-right: 35px;
            .region {
                position: absolute;
                right: -10px;
                bottom: 5px;
                
                i {
                    font-size: 30px;
                    height: 10px;
                    box-shadow: 6px 20px 15px 4px rgba(0, 0, 0, 0.30);
                    display: flex;
                }
            }
        }
        
        .data-profile_user-detail {
            margin-bottom: 24px;
            .user-type .badge {
                font-size: 9px;
                padding: 1px 10px;
                border-radius: 2px;
                margin-bottom: 2px;
            }
            .user-name {
                font-size: 16px;
            }
            .user-email {
                color: #939BA1;
                font-weight: normal;
                font-size: 12px;
            }
        }
        .data-profile_additional-info {
            color: #939BA1;
            list-style-type: none;
            padding: 0;
            font-size: 11px;
            margin: 0 0 24px;
            i  {
                margin-right: 8px;
            
            }
            li {
                word-break: break-all;
                margin-bottom: 8px;
                padding-left: 1.5em;
                text-indent: -1.9em;
                line-height: 15px;
                &.amz {
                    font-size: 10px;
                    letter-spacing: 1px;
                    text-transform: lowercase;
                }
            }
        }
    }

    .image-wrapper {
        background-size:contain;
        background-repeat: no-repeat;
        background-color: white;
        background-position: center;
        height: 50px;
        width: 50px; 
        img {
            width: 100%;
            height: 50px;
            padding: 5px;
            object-fit: contain
        }
    }
}

/*********************
#Table : Manage Invite Stats
*********************/
.admin_invite-stats {
    tbody {
        tr:last-child {
            background: lighten($color:$cl-light-gray, $amount: 35%);
            td {
                font-weight: bold;
            }
        }
    }
}

.bonus_invite {
    margin-bottom: 100px;
    .form-check {
        label {
            max-width: 100%;
        }
    }
    .table{
        td  {
            width: 15%;
        }
    }
    
}

/*********************
#Table : Manage Invite
*********************/
.admin_manage-invite {
    .question-item,
    .source-item {
        width: 250px;
        // max-width:250px;
        font-size: 12px;
        margin-bottom: 10px;
        overflow-wrap: break-word;
        word-wrap: break-word;
        hyphens: auto;
        strong {
            opacity: .4;
            line-height: 0.625rem;
            display: block;
            margin-bottom: 3px;
        }
    }

    .table tbody td {
        vertical-align: top ;
        &:not(:first-child) {
            padding-top: 30px;
        }
    }
}

/*********************
#Table : Bonus Invite
*********************/
.bonus_invite {
    tr {
        &.--highlight {
            background: rgba($color: white, $alpha: 0.1);
        }
    }
    .controller {
        background-color: $cl-dark-purple;
        box-shadow: 0 15px 20px 20px rgba(0, 0, 0, 0.2);
        position: fixed;
        bottom: 0;
        width: 100%;
        padding: 20px 20px!important;
        display: flex;
        justify-content: flex-end;
        left: 0;
        z-index: -1;
        opacity: 0;
        transition: all .3s ease-in-out;
        &.--show {
            opacity: 1;
            z-index: 2;
        }
    }
    .change-verbiage {
        font-weight: normal;
        font-size: 12px;
        display: flex;
        align-items: center;
        padding: 0 20px;
    }

}

/*********************
#Email
*********************/
.email_template {
    height: 93vh;
}

/*********************
#Tooltip
*********************/
.tooltip {
    .auto-invite-tooltip {
        text-align: left;
        p {
            margin-bottom: 0;
            font-weight: bold;
        }
        span {
            margin-bottom: 10px;
            display: block;
        }
    }
    .data_live_amount_tooltip {
        padding: 10px ;
        text-align: left;
        font-size: 12px;
        .item {
            margin-bottom: 10px;
            p.label {
                font-weight: bold;
                color: lighten($color: $cl-light-gray, $amount: 50%);
            }
            p {
                color: lighten($color: $cl-light-gray, $amount: 20%);
                font-weight: 500;
                margin-bottom: 3px;
                line-height: 16px;
            }
            
            ul {
                color: $cl-light-gray;
                list-style-type: none;
                padding: 0;
                margin: 0;
            }
            .--error {
                color: $cl-green;
            }
            .shipping {
                font-weight: 500;
                color: $cl-red;
                &.--shipped {
                    color: $cl-green;
                }
            }
        }
    }
}


/*********************
#Form
*********************/
.page_admin form {
    color: $cl-gray;
    transition: all $animationTiming ease;
    select {
        &:focus {
            color:$cl-gray;
        }
    }
    .deal-deck-wrapper {
        > div {
            border: 5px dotted $cl-light-gray;
        }
    }
    label {
        color: $cl-gray;
        max-width: 40%;
        opacity: .8;
        text-transform: uppercase;
        font-size: 0.8125rem;
        font-weight: 500;
    }
    .controller {
        padding: 20px 0 ;
        margin-bottom: 0;
        float: right;
        button:last-child {
            margin-right: 0;
        }
        .btn-link  {
            padding-left: 15px;
            padding-right: 0;
            &:hover {
                box-shadow: none
            }
        }

    }
    
    sup {
        font-size: 20px;
        top: 0;
    }

    .list-group {
        .list-group-item {
            color: $cl-light-gray;
            background-color: lighten($color: $cl-light-gray, $amount: 30%);
        }
    }
    .form-control[readonly],
    .form-control:disabled {
        background-color: rgba($color: $cl-light-gray, $alpha: .2)
    }
}

/*********************
#Page : Export
*********************/
.page_admin .export {
    text-align: center;
    padding: 40px 0;
    width: 80%;
    margin:  40px auto ;
    &.--single {
        position: relative;
        top: 50%;
        transform: translateY(-90%);
    }
}

/*********************
#Dark Theme
*********************/
$border-color : lighten($color: $cl-dark-purple, $amount: 10%);

.admin_wrapper--dark {
    background-color: $cl-dark-purple;
    /*********************
    - General
    *********************/
    section {
        background-color: $cl-dark-purple;
        box-shadow: 0 15px 20px -10px rgba(0, 0, 0, 0.2);
        
        .section-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .header-title {
                color: $cl-white-86;
            }

        }
    }
    main {
        background-color: $cl-light-purple;
    }
    .spinner > div {
        background-color: $cl-light-green;
    }
    &.admin_subpage {
        .sub-page_breadcrumb {
            color: rgba($color:  $cl-white, $alpha: 0.5)
        }
        h2 {
            color: $cl-white;
        }
        h3 {
            color: rgba($color:  $cl-white, $alpha: 0.8)
        }
        .admin_sidebar {
            background-color:$cl-dark-purple;
            box-shadow: inset -15px 0 30px -12px rgba(000, 000, 000, 0.3);
        }
    }

    /*********************
    #Table Search
    *********************/
    .table-search_wrapper {
        .table-search_input {
            background-color: rgba($color: $cl-white, $alpha: .09); 
            &:focus {
              
                color: $cl-black;
                background-color: rgba($color: $cl-white, $alpha: 0.9);
              
            }
        }
    }

    /*********************
    - Table
    *********************/
    .table {
        &.-highlight {
            tbody {
                tr:hover {
                    background-color: $cl-light-purple;
                }
            }
        }
        thead {
            th {
                color: $cl-white;
            }
        }
        tbody {
            // background-color: $cl-dark-purple;
            td {
                border-color: $border-color ;
                color: $cl-white;
                .table-data_action-bar{
                    a:after {
                        display: none !important
                    }
                }
                a:not(.btn) {
                    position: relative;
                    color: $cl-white;
                    &:after {
                        content:"";
                        display: block;
                        left: 0;
                        height: 2px;
                        width: 100%;
                        position: absolute;
                        bottom: -5px;
                        // border-bottom: 2px dotted rgba($color: white, $alpha: .2) ;
                    }
                    &:hover {
                        text-decoration: none;
                        &:after {
                            background: $cl-green
                        }
                    }
                }
                .spinner {
                    height: auto;
                    > div {
                        background-color: $cl-light-gray;
                    }
                }
            }
        }
        .table-data_name {
            p.small {
                color: $cl-white-60;
            }
        }
        .table-data_description {
            max-width: 800px;
            font-size: 0.8125rem;
            font-weight: normal;
            line-height: 1.0625rem;
            .star-rating-container {
                font-size: 1.3rem;
            }
        }
        .table-data_asin {
            a {
                color: $cl-light-gray;
                &:hover {
                    color: $cl-light-green
                }
            }
        }
        .table-data_extra-links {
            .badge {
                background-color : $cl-blue;
                
            }
        }
    }

    /*********************
    - Table : Manage Invite
    *********************/
    .admin_invite-stats {
        tbody {
            tr:last-child {
                background: lighten($color:$cl-dark-purple, $amount: 5%);
            }
        }
    }
    .admin_manage-invite {
        .manage-invite_legend {
            background-color: transparent;
            box-shadow: none;
            display: flex;
            justify-content: flex-end;
            ul {
                list-style-type: none;
                padding: 0;
                li {
                    float: left;
                    font-size: 13px;
                    margin-right: 10px;
                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }
        .table-data_action-bar {
            button {
                margin-right: 5px;
            }
        }
    }
    /*********************
    #Button
    *********************/
    .btn:not(.btn-link) {
        &.btn-light {
            color: $cl-dark-purple;
            background-color: lighten($color: $cl-light-purple, $amount: 40%);
            border: none;
            &:hover {
                transform: translateX(3);
                text-decoration: none;
                background-color:  lighten($color: $cl-light-purple, $amount: 30%);
            }
        }
        &:hover  {
            box-shadow: 0 11px 13px -7px rgba(000, 000, 000, 0.7);
        }
    
    }

    .btn-load-more,
    .btn-load-more:disabled,
    .btn-load-more:not(:disabled):not(.disabled):active {
        $color : lighten($color: $cl-light-purple, $amount: 10%);
        border-color: $color;
        background-color: $color;
        &:hover {
            $colorHover : lighten($color: $cl-light-purple, $amount: 15%);
            background-color: $colorHover;
            border-color: $colorHover;
        }
        &:disabled {
            pointer-events: none;
        }
    }

    /*********************
    #Action Bar
    *********************/
    .table .table-data_action-bar {
        a {
            &:after {
                height: 0;
            }
            
        }
        .btn-link {
            color: white;
        }
    }

    /*********************
    - Form
    *********************/
    form {
        input,
        textarea,
        select {
            border-color: lighten($color: $cl-light-purple, $amount: 10%);
            color: $cl-white;
            background-color: lighten($color: $cl-dark-purple, $amount: 5%);;
            option {
                color: $cl-gray;
            }
            &:focus {
                color:$cl-white;
                background-color: transparent;
                border-color:   lighten($color: $cl-light-purple, $amount: 20%);
                box-shadow:  0 0 2 0.2rem rgba(182, 182, 182, 0.20);
            }
            &::-webkit-input-placeholder {
                color: lighten($cl-gray, 0.03%) !important;
            }
             
            &:-moz-placeholder { /* Firefox 18- */
                color: lighten($cl-gray, 0.03%) !important;  
            }
             
            &::-moz-placeholder {  /* Firefox 19+ */
                color: lighten($cl-gray, 0.03%) !important;  
            }
             
            &:-ms-input-placeholder {  
                color: lighten($cl-gray, 0.03%) !important;  
            }
        }
        label {
            color:rgba($color: $white, $alpha: 0.7);
        }
        tbody {
            td {
                border-color: $border-color ;
                color: $cl-white;
            }
        }
        .deal-deck-wrapper {
            > div {
                border: 5px dotted $cl-gray;
            }
        }
        .list-group {
            .list-group-item {
                color: $cl-light-gray;
                border-color: $border-color ;
                background-color: $cl-light-purple;
            }
        }
        // .controller {
        //     &.--edit-mode {
        //         box-shadow: 0 15px 20px -10px rgba(0, 0, 0, 0.2);
        //         background-color: $cl-light-purple;
        //     }
        // }
    }
}

// remove in the future when approval gets removed
.admin_manage-invite-modal {
    // padding: 15px 20px;
    .analyzer_result {
        &.--error {
            p {
                line-height: 1.125rem;
                font-size: 0.875rem;
            }
            p.error-message {
                strong{
                    color: red;
                }
                margin-bottom:15px;
            }
            .modal-footer {
                margin-bottom: 15px;
            }
        }
    }
    .close {
        position: absolute;
        top: 10px;
        right: 15px;
    }
    .modal-header {
        padding: 0;
        margin-bottom:15px;
        h4 {
            width: 100%;
            margin-top: 20px;
            margin-bottom: 0;
            padding: 10px 0 0;
            font-size: 1.375rem;
            font-weight: bold;
            overflow-wrap: break-word;
            word-wrap: break-word;
            -webkit-hyphens: auto;
            -ms-hyphens: auto;
            hyphens: auto;
        }
    }
   
    .invite-modal_searching {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        min-height: 150px;
        margin-bottom: 40px;
        .spinner-wrapper {
            text-align: center;
            font-size: 40px;
        }
    }
    .modal-body {
        padding: 0 0 20px;
        overflow: scroll;
        .searching-result_total-review {
            margin-bottom: 10px;
        }
        .searching-result {
            font-weight: normal;
            margin-bottom: 10px;
            i {
                display: block;
                float: left;
                svg {
                    font-size: 18px;
                    margin-right: 1px;
                    margin-left: -4px;
                }
            }
        }
    }
    .modal-footer {
        border: 0;
        padding: 0;
        flex-direction: column;
        button {
            margin-right: 0!important;
            width: 100%;
            &.btn.--approve {
                padding: 10px 20px;
            }
            &.btn-link.--reject {
                color: $cl-red;
                padding: 10px;
            }
            // &.btn-danger {
            //     background-color: transparent;
            //     color: $cl-red;
            // }
        }
    }
}

.page_admin .modal {
    .form-group {
        label {
            color: $cl-black;
            display: inline-block;
            max-width: 100%;
            font-weight: 500;
            padding: 0;
        }
    }
    .table {
       
        th {
            color: #adadad;
            font-weight: bold;
        }
        tr {
            border-bottom: 1px solid #ececec;
            &:last-child {
                border-bottom: 0;
            }
        }
    }
    .analysis-data {
        // background: red
        th:nth-child(4),
        td:nth-child(4){
            text-align: center;
        }
        td {
            text-transform: capitalize;
        }
        tr.disabled {
            td:not(:first-child) {
                color: $cl-light-gray;
            }
        }
        .svg-inline--fa {
            font-size: 1.25rem;
        }
        .close {
            position: absolute;
            right: 15px;
            top: 15px;
        }
        .modal-header {
            padding-top: 45px;
            > div {
                width: 100%;
                @include media-breakpoint-up(lg) { 
                    display: flex;
                    justify-content: space-between;
                }
            }
            .user-profile {
                display: flex;
                .user-profile_image {
                    width: 100px;
                    height: 100px;
                    border-radius: 50%;
                    background-color: $cl-light-gray;
                    margin: 0 24px 0 0;
                    background-size: cover;
                    background-repeat: no-repeat;
                    // box-shadow: 0 4px 5px 0 rgba(0,0,0,0.30);
                }
                .user-profile_detail {
                    padding-top: 10px;
                    // margin-bottom: 24px;
                    .name {
                        color: $cl-black;
                        font-size: 16px;
                        line-height: 18px;
                        font-weight: 600;
                    }
                    .email {
                        font-weight: 400;
                        color: $cl-gray;
                    }
                }
            }
        }
        .user-approval {
            padding-left: 29%;
            margin-top: 10px;
            margin-bottom: 24px;
            display: flex;
            @include media-breakpoint-up(lg) { 
                align-items: center;
                padding: 0;
                margin: 0;
                justify-content: center;
                flex-direction: column;
            }
            button {
                // margin-top: -11px;
                font-size: 18px;
                // height: 40px;
                padding: 8px 2.5rem;
                margin-right: 0;
            }
            .secondary-approval {
                button {
                    padding: 0;
                    margin-top: 10px;
                    font-size: 12px;
                    color: $cl-gray;
                    margin-left: 20px;
                    @include media-breakpoint-up(lg) { 
                        margin-top: 10px;
                        margin-left: 0;
                    }
                }
            }
        }
        .user-score {
            margin-top: 8px;
            div {
                font-size: 24px;
                font-weight: normal;
                &.false {
                    color: $cl-red;
                }
                &.true {
                    color: $cl-green;
                }
            }
        }
    }
   
}