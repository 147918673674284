/*********************
Header

#Main Nav
#Main Nav : Left Wrapper
#Main Nav : Right Wrapper
#Side Nav
*********************/

@import '../../assets/styles/partials/_variables.scss';


/*********************
Main Nav
*********************/
nav,
nav.navbar-light {
    background-color: $cl-green;
    box-shadow: rgba(45, 45, 45, 0.05) 0px 2px 2px, 
    rgba(49, 49, 49, 0.05) 0px 4px 4px, 
    rgba(42, 42, 42, 0.05) 0px 8px 8px, 
    rgba(32, 32, 32, 0.05) 0px 16px 16px, 
    rgba(49, 49, 49, 0.05) 0px 32px 25px;

    > .container {
        display: flex;
    }

    .navbar-nav {
        margin-right: 10px;
        @include media-breakpoint-down(sm) { 
            margin-top: 10px;
        }
        .btn-dp--light-green {
            margin: 10px 0 15px;
            @include media-breakpoint-up(md) { 
                margin: 0 0 0 10px;
            }
            font-weight: bold;
        }
        .btn-dp--icon {
            margin-left: 10px;
        }
        a.nav-link {
            display: inline-block;
            color : $cl-white;
            font-size: 1rem;
            font-weight: 500;
            padding: 0.6rem 0;
            position: relative;
            width:100%;
            border-top: 1px solid #567e3f;
            text-transform: capitalize;
            @include media-breakpoint-up(md) { 
                width:auto;
                padding: .5rem .9rem;
                border: none;
            }
            @include media-breakpoint-up(md) { 
                &:after {
                    content:"";
                    opacity: 0;
                    width: 100%;
                    height: 3px;
                    display: block;
                    background-color: $cl-light-green;
                }
            }
            
            &:hover,
            &:focus,
            &.nav-link--current {
                &:after {
                    opacity: 1;
                }
                color: $cl-white;
                text-decoration: none;
            }
            &.nav-link--current { 
                color: $cl-white;
            }
        }
        .navbar-nav_profile {
            > a:hover::after {
                    display: block;
                    color: #ffffff;
                    content: "";
                    position: absolute;
                    top: -5px;
                    left: -3px;;
                    border-radius: 50%;
                    margin: 0;
                    border: 3px solid lighten($color: $cl-green, $amount: 30%);
                    width: 46px;
                    height: 46px;
                    background-color: rgba($color: $cl-white, $alpha: 0.3);
                    -webkit-transition:  border .1s ease, background-color .3s ease-out;
                    -moz-transition:  border .1s ease, background-color .3s ease-out;
                    -o-transition:  border .1s ease, background-color .3s ease-out;
                    -ms-transition: border .1s ease, background-color .3s ease-out;
                    transition: border .1s ease, background-color .3s ease-out; 
            }
        }
        .notification {
            font-size: 0.6875rem;
            line-height: 11px;
            padding: 5px; 
            color:$cl-white;
            background: $cl-orange;
            border-radius: 50%;
            height: 20px;
            width: 20px;
            @include media-breakpoint-up(md) { 
                padding: 4px; 
                position: absolute;
                top:0;
                right:0;
            }
        }
    }

    .navbar-nav_stats-wrapper {
        display: flex;
        align-items: center;
        @include media-breakpoint-down(md) { 
            display: none;
        }
    }

    .navbar-nav_stats{
        color: $cl-white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        line-height: 1rem;
        padding: 0 10px 0 20px;
        // margin-left: 20px;
        text-align: right;
        @include media-breakpoint-up(md) { 
            border-left: 1px solid rgba($color: $cl-white, $alpha: 0.3);
        }
        .stats_current-points_title {
            font-size: 0.75rem;
            margin-bottom: 2px;
            color:$cl-green;
        }
        .stats_current-points {
            font-size: 0.875rem;
            .spinner {
                text-align: right;
                > div {
                    width: 10px;
                    height: 10px;
                    text-align: right;
                    background-color: $cl-white;
                }
            }
        }
        .stats_pending-points {
            opacity: .5;
            display: flex;
            font-size: 0.625rem;
            .spinner {
                margin-left: 5px;
                text-align: right;
                > div {
                    width: 5px;
                    height: 5px;
                    text-align: right;
                    background-color: $cl-white;
                }
            }
        }
        &.navbar-nav_stats--mobile-only {
            color: $cl-gray;
            color: #525252;
            float: left;
            padding: 11px 17px;
            text-align: left;
            border-bottom: 1px solid lighten($color:$cl-light-gray, $amount: 32% );
            width: 100%;
            margin: auto;
        }
    }

    .navbar-nav_stats-wrapper_mobile {
        position: absolute;
        right: 0;
        z-index: 999999;
        @include media-breakpoint-up(lg) { 
            display: none;
            position: relative;
        }
    }

    .navbar-nav_stats {
        @include media-breakpoint-down(md) { 
            display: none;
        }
        &.navbar-nav_stats--mobile-only{
            display: none;
            @include media-breakpoint-down(md) { 
                display: block;
            }
        }
    }

    .navbar-nav_profile {
        transition: all 0.2s ease-in;
        background: transparent;
        border-radius: 50%;
        background-size:contain;
        background-color: darken($color: $cl-green, $amount: 10%);
        cursor: pointer;
        height: 33px;
        width: 33px;
        &.no-profile {
            &:before {
                color: $cl-white;
                top: -4px;
                left: -4px;
                position: absolute;
                font-size: 50px;
                font-family: 'dp' !important;
                font-style: normal;
                font-weight: normal;
                font-variant: normal;
                text-transform: none;
                line-height: 1;

                /* Better Font Rendering =========== */
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                content: "\e911";
            }
        }
        a{
            background: transparent;
            z-index: 2;
            width: 100%;
            position: relative;
            &:after {
                border: none;
            }
            &:hover {
                span {
                    color: $cl-white;
                }
            }
        }
        &:hover {
            background-color:  darken($color:$cl-green, $amount: 10%);
            box-shadow: 0 9px 9px 0 rgba(0, 0, 0, 0.3);
        }
    }
    .dropdown-menu {
        border: 0;
        box-shadow: 0 2px 4px 0 rgba(0,0,0,0.11);
        font-size: 0.875rem;
        padding: 0;
        max-width: 150px;
        margin-top: 5px;
        &::after {
            border-left: .3em solid transparent;
            border-top: 0;
            border-right: .3em solid transparent;
            border-bottom: .3em solid;
            display: inline-block;
            color: $cl-white;
            content: "";
            position: absolute;
            margin-left: .255em;
            right: 17px;
            top: -4px;
            vertical-align: .255em;
        }
        a {
            line-height: 1.3rem;
            white-space: normal;
            padding: 10px 15px;
            color:$cl-gray;
            &:first-child {
                border-top-left-radius:.25rem;
                border-top-right-radius:.25rem;
            }
            &:hover,
            &:active {
                background-color: lighten($color: $cl-green, $amount: 50%);
                color:$cl-gray;
            }
        }
        .dropdown-item:last-child {
            border-top: 1px solid $cl-border;
        }
    }

    /*********************
    Main Nav : Left Wrapper
    *********************/
    .navbar-nav_left-wrapper { 
        position: relative;
        display: flex;
        align-items: center;
        @include media-breakpoint-down(sm) { 
            padding: 0;
        }
        .navbar-brand {
            .nav_logo {
                margin-top: 2px;
            }
        }
        .navbar-toggler {
            margin-right: 10px;
            border: 0;
            padding: 0;
            &:focus {
                outline: none;
            }
            svg {
                color: $cl-white;
            }
        }
        .navbar-collapse {
            margin: 5px 0;
            @include media-breakpoint-down(sm) { 
                border-top: 1px solid rgba(255,255,255, .5); 
            }  
        }
    }

    /*********************
    Main Nav : Right Wrapper
    *********************/
    .navbar-nav_right-wrapper {
        display: flex;
        justify-content: flex-end;
        margin-left: 40px;
        max-width: 90%;
        padding: 0;
        @include media-breakpoint-up(md) { 
            margin: auto;
            max-width: 100%;
            .navbar-collapse {
                justify-content: flex-end;
                // padding-right: 20px; add this when bonus comes back
            }
        } 
    }
    .navbar-nav_left-wrapper,
    .navbar-nav_right-wrapper { 
        @include media-breakpoint-up(md) { 
            padding: 0;
        }
    }
}

.dropdown-toggle:empty::after {
    border: none;
}

.region-selector {
    border-left: 1px solid lighten($cl-green, 20% );
    margin-left: 5px;
    padding-left: 5px;
    button {
        background-color: transparent;
        border: none;
        &:first-child {
            padding: 0 ;
            padding-left:10px;
            pointer-events: none;
        }
        &:hover,
        &:active,
        &:focus,
        &.show > .btn-secondary.dropdown-toggle {
            background-color: transparent !important;
            box-shadow: none !important; 
            border: none !important;
        }
        img {
            width: 30px;
        }
    }
}

.region-selector_menu {
    padding: 0;
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.05);
    .dropdown-item,
    .dropdown-item.btn.btn-link {
        padding: 5px 10px 5px 15px;
        border-bottom: 1px solid lighten($cl-gray, 50%);
        &:last-child {
            border-bottom: none;
        }
    }
    img {
        width: 28px;
        margin-right: 5px;
    }
}

.dropdown-item.btn.btn-link {
    display: block;
    width: 100%;
    padding: 0.25rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
    line-height: 1.3rem;
    white-space: normal;
    padding: 10px 15px;
    color: #525252;
    font-size: 0.875rem;
}