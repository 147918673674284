@import '../../assets/styles/partials/_variables.scss';

/*********************
Reward Create

#Table
#Card Deck
#Dark Theme
    - Card Deck
*********************/

/*********************
#Table
*********************/
.rewards_overview table {
    .table-data_image {
        width: 5%;
        .image-wrapper_rewards {
            background-size: 85%;
        }
    }
    .table-data_name {
        width: 70%;
    }
    thead tr {
        .reward_img {
            width: 35px;
        }
    
        .reward_name {
            width: 65%;
        }
    }
}

/*********************
Reward Order

#Table
#Card Deck
#Dark Theme
    - Card Deck
*********************/

/*********************
#Table
*********************/
.rewards_orders table tbody{
    td {
        width: auto;
        &.td_button {
            button {
                width: 180px;
            }
        }
    }
}