@import '../../assets/styles/partials/_variables.scss';

/*********************
User Detail

#Detail
*********************/

.page_user-detail {
    .sub-header {
        h3 {
            font-size: 24px;
            font-weight: normal;
            margin-bottom: 24px;
            text-transform: uppercase;
        }
    }
    main section {
        width: 100%;
        padding: 20px ;
    }
    
    ul {
        font-size: 0.875rem;
        color: $cl-white ;
        padding-left: 0;
        list-style-type: none;
        margin-bottom: 0;
        li.user_profile-item {
            margin-bottom: 20px;
            margin-right: 24px;
            .user_profile-item_label {
                color: $cl-gray;
                font-size: 0.875rem;
            }
        }
    }
    
    .user_overview {
        h3 {
            font-size: 1.125rem;
        }
    }
    .user_profile {
        $detail-color : rgba($color: $cl-white, $alpha: 0.5);
        margin-bottom: 40px;
      
        position: relative;
        .action-bar_extra {
            position: absolute;
            top: 30px;
            right: 0;
            .dropdown-toggle  {
                color: white;
                &:hover {
                    color: $cl-green;
                }
            }
            #dropdown-basic {
                &::after {
                    display: none;
                }
            }
            .delete-user {
                color: red;
            }
        }
        .user-profile_wrapper {
            display: flex;
            position: relative;
            padding: 40px;
        }
        .user-profile_image {
            position: relative;
            width: 20%;
            width: 100px;
            height: 100px;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            border-radius: 50%;
            margin-right: 35px;
            background-color: red;
            .region {
                position: absolute;
                display: inline-block;
                right: 0;
                bottom: 0;
            }
        }
    
        .user-tags .badge {
            font-size: 11px;
            padding: 5px 10px;
            border-radius: 2px;
            margin-bottom: 8px;
        }
    
        .user-profile_detail {
            width: 80%;
            padding-bottom: 10px;
            font-weight: normal;
    
            .user-name {
                display: inline-block;
                .user_profile-item_label {
                    .btn-link {
                        font-weight: bold;
                        color: $cl-white;
                        font-size: 1.5rem;
                        padding: 0;
                        &:hover {
                            text-decoration: none;
                        }
                    }
                }
            }
    
            .user-email {
                font-size: 1rem;
                margin-bottom: 16px;
                color: $detail-color;;
            }
            ul {
                font-size: 0.875rem;
                color: $cl-white ;
                padding-left: 0;
                list-style-type: none;
                margin-bottom: 0;
                li {
                    margin-bottom: 20px;
                    margin-right: 24px;
                    .user_profile-item_label {
                        color: $cl-gray;
                        font-size: 0.875rem;
                    }
                }
            }
        }
    
        .user-profile_additional-info {
            display: inline-block;
            li {
                float: left;
            }
        }
    
        ul.user-profile_platform-id {
            display: inline-block;
            color: #939BA1;
            list-style-type: none;
            padding: 0;
            font-size: 11px;
            margin: 0 0 24px;
            i  {
                margin-right: 5px;
                color: $cl-gray;
                font-size: 0.875rem;
            
            }
            li {
                float: left;
                word-break: break-all;
                margin-bottom: 8px;
                padding-left: 1.5em;
                text-indent: -1.9em;
                line-height: 15px;
                margin-right: 16px;
                font-size: 12px;
                color: $cl-gray;
    
            }
        }
    
        ul.user-profile_quick-links {
            display: flex;
            align-items: center;
            li {
                margin-right: 5px;
                margin-bottom: 0;
                &:first-child {
                    .btn-link {
                        padding-left: 0;
                    }
                }
                .btn-link {
                    color: $cl-gray;
                    font-size: 16px;
                    padding: 10px;
                    &:hover {
                        text-decoration: none;
                        color: $cl-green;
                    }
                }
                &.amz {
                    letter-spacing: 1px;
                    text-transform: lowercase;
                    .btn-link {
                        font-size: 20px;
                    }
                }
            }
        }
    
        .quick-link-copied  {
            opacity: 0;
            color: $cl-green;
            font-weight: bold;
            margin-bottom: 8px;
            margin-left: 16px;
            transition: all 0.3s ease-in-out;
            &.--true {
                opacity: 1;
            }
        }
    }
    
    .user_activity {
        width: 100%;
        .table {
            .activity_date {
                width: 250px;
            }
            .no-activity {
                opacity: .7;
                font-size: 15px;
                margin-left: 15px;
                font-style: inherit;
            }
            svg {
                cursor: pointer;
                color: $cl-green; 
                &:hover {
                    color: rgba($color: $cl-green, $alpha: 0.5);
                }
            }
        }
    }

    .user_delete-confirmation-modal  {
        text-align: center;
        padding: 20px 40px;
        .modal-title {
            font-size: 24px;
            color: $cl-black;
            font-weight: bold;
            margin-bottom: 24px;
        }
        .modal-footer {
            border-top: 0;
            border-top: 0;
            flex-direction: column;
            button {
                &:first-child {
                    margin-bottom: 16px;
                }
                margin: 0;
            }
        }
    }
    
    .user_activity-modal {
        font-family: 'Montserrat', sans-serif;
        padding: 5px 10px;
        .modal-header {
            flex-direction: column;
        }
        .modal-title {
            font-weight: 600;
            line-height: 30px;
            margin-bottom: 10px;
        }
        .close {
            text-align: right;
            position: absolute;
            top: 10px;
            right: 10px;
        }
        
        ul {
            padding-left: 0;
            list-style-type: none;
            li {
                margin-bottom: 10px;
                color: $cl-gray;
                strong {
                    color: $cl-black;
                    display: block;
                }
            }
         
        }
    }
}
