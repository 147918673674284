/*********************
Page: FAQ

#Body
#Form
#Modal
*********************/
@import '../../assets/styles/partials/_variables.scss';

/*********************
#Body
*********************/

.page_faq {
    h3 {
        width: 100%;
        margin-left: 20px;
        text-transform: uppercase;
    }
    .accordion {
        margin-bottom: 30px;
    }
    .content {
        padding: 0;
        margin-left: 20px;
    }
    @include media-breakpoint-up(md) { 
        h3 {
            margin-left: 0;
        }
        .content {
            margin-left: 0;
        }
    }
}