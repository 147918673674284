/*********************
Page: Register

#Form
*********************/
@import '../../assets/styles/partials/_variables.scss';

/*********************
#Form   
*********************/
.register_form {
    padding-bottom: 40px; 
}