@import '_variables.scss';

/*********************
Modal

# General Modal
# Redemption Modal 
# Video Modal 
*********************/

/*********************
# General Modal
*********************/
.modal {
    font-family: 'Poppins', sans-serif;
    section{
        margin-top: 0;
    }

    .modal-title {
        font-size: 2rem;
        color: $cl-black;
        font-weight: bold;
        line-height: 2.5rem;
        
    }
    .modal-content {
        padding: 10px 20px;
        box-shadow: rgba(45, 45, 45, 0.05) 0px 2px 2px,
                    rgba(49, 49, 49, 0.05) 0px 4px 4px,
                    rgba(42, 42, 42, 0.05) 0px 8px 8px,
                    rgba(32, 32, 32, 0.05) 0px 16px 16px,  
                    rgba(49, 49, 49, 0.05) 0px 32px 32px, 
                    rgba(35, 35, 35, 0.05) 0px 64px 64px;
        border: none;
        .modal-header {
            border: 0;
            flex-direction: column;
        }
    }
    .page-header {
        margin-top: 0;
        @include media-breakpoint-down(sm) { 
            h1 {
                padding: 0;
            }
            .lead {
                padding: 0;
            }
        }
    }
    &.modal-alert {
        .modal-dialog {
            .page-header {
                margin-bottom: 0var;
                h1 {
                    padding: 0;
                }
            }
        }
    }
    .close {
        text-align: right;
        position: absolute;
        right: 10px;
        top: 10px;
    }
    .modal-footer {
        button.btn-link {
            color: $cl-gray;
            margin-right: 15px;
        }
        button:last-child {
            margin-right: 0;
        }
        .btn-dp:not(.btn-lg) {
            padding: 10px 30px;
        }
    }
}

/*********************
# Redemption Modal
*********************/
.modal.modal-redemption {
    .modal-content {
        padding: 20px 10px 0;
    }
    .modal-header {
        border: none;
        padding: 0 15px;
    }
    .modal-title {
        align-items: center;
        display: flex;
        font-size: 1.125rem;
        font-weight: 600;
        justify-content: space-between;
        width: 100%;    
    }
    .redemption_card {
        width: 100%;
        border-radius: 5px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        min-height: 260px;
        margin-bottom: 20px;
        border: 1px solid lighten($cl-light-gray, 30%);
    }
    .redemption_title {
        font-size: 1.25rem;
        color: $cl-gray;
    }
    .redemption_current-points {
        text-align: right;
       
        .point_status-wrapper {
            display: flex;
            align-items: center;
        }
        
        > span {
            color: #5f5f5f;
            display: inherit;
            font-size: 0.75rem;
            font-weight: bold;
            line-height: 21px;
        }
        
        i {
            color: $cl-black;
            font-size: 1.2rem;
            margin-right: 2px;
        }

        span.point_status-value {
            display: inline;
            color:$cl-green;
            font-size: 1.5rem;
            font-weight: 300;
            line-height: 1.5rem;
        }
    }
    form {
        .title {
            font-size: 0.875rem;
            font-weight: 500;
            margin-bottom: 10px;
        }
        .form-label {
            color: $cl-gray;
            font-size: 0.875rem;
        }
        .form_transaction-breakdown {
            border-top:1px solid $cl-border;
            border-bottom: 1px solid $cl-border;
            font-size: 0.875rem;
            padding: 10px 0;
            margin: 20px 0;
        }
        .form_select-amount {
            display: flex;
            justify-content: space-between;
            .select-amount_option {
                display: flex;
                flex: 1;
                &.disabled {
                    opacity: 0.4;
                    pointer-events: none;
                }
            }
            input[type="radio"] {
                display: none;
                &:checked {
                    &+label{ 
                        background-color: lighten($color: $cl-green, $amount: 50%);
                        border:1px solid $cl-green;
                        box-shadow:  rgba(45, 45, 45, 0.05) 0px 2px 2px,
                                 rgba(49, 49, 49, 0.05) 0px 4px 4px,
                                 rgba(42, 42, 42, 0.05) 0px 8px 8px,
                                 rgba(32, 32, 32, 0.05) 0px 16px 16px,  
                                 rgba(49, 49, 49, 0.05) 0px 32px 32px, 
                                 rgba(35, 35, 35, 0.05) 0px 64px 64px;
                                 transform: translate(0, -4px);
                    } 
                }
            }
            label {
                flex: 1;
                border: 1px solid $cl-border;
                border-radius: 5px;
                font-size: 16px;
                padding: 10px 0;
                text-align: center;
                transition: box-shadow .3s ease-out, transform .3s ease-out, opacity .2s ease-out;
                .select-amount_option-value {
                    font-size: 1.125rem;
                    font-weight: 600;
                }
                .select-amount_option-points {
                    font-size: 0.75rem;
                }
                &:hover {
                    background-color: lighten($color: $cl-green, $amount: 50%);
                    box-shadow:  rgba(45, 45, 45, 0.05) 0px 2px 2px,
                                 rgba(49, 49, 49, 0.05) 0px 4px 4px,
                                 rgba(42, 42, 42, 0.05) 0px 8px 8px,
                                 rgba(32, 32, 32, 0.05) 0px 16px 16px,  
                                 rgba(49, 49, 49, 0.05) 0px 32px 32px, 
                                 rgba(35, 35, 35, 0.05) 0px 64px 64px;
                    transform: translate(0, -4px);
                }
            }
          
           
        }
        .transaction-breakdown_item {
            padding: 5px 0px;
            text-align: right;
        }
        .transaction-breakdown_item-title {
            font-weight: 500;
        }
        .transaction-breakdown_item-value {
            display: inline-block;
            text-align: right;
            min-width: 70px;
            &.deduction {
                color: $cl-red;
            }
        }
        .form_footer {
            margin-bottom: 0;
            display: flex;
            align-items: inherit;
            justify-content: flex-end;
            button{
                padding: 5px 35px;
                margin: 0 5px;
                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
    .close {
        display: none;
    }
}

.modal.modal-redemption.modal-redemption--success {
    text-align: center;
    .modal-content {
        padding: 40px;
    }

    .modal-header{
        .modal-title  {
            color: $cl-green;
            flex-direction: column;
            
        }
        h2 {
            font-weight: 300;
        }
        p {
            font-weight: normal;
            line-height: 1.5em;
            margin: 0;
        }
    }
    .modal-footer {
        display: block;
        font-size: 0.75rem;
    }
    .redemption_current-points {
        padding: 6% 0 9%;
        text-align: center;
        .point_status-wrapper {
            justify-content: center;
            > span {
                font-size: 25px;
                color: $cl-green;
                font-weight: 600;
            }
        }

    }

    button {
        padding: 15px 30%;
    }
}

/*********************
# Video Modal
*********************/
.modal.modal-video {
    &.--borderless {
     
        .modal-body {
            border-radius: 5px;
            padding: 0;
            background-color: black;
            padding: 51px 15px 15px;
        }
    }
    .modal-content {
        padding: 0;
        img { width: 100%; }
    }
    .close-button {
        position: absolute;
        right: 5px;
        color: white;
        z-index: 30;
        top: 0px;
        border-radius: 40px;
        background-color: black;
        padding: 5px;
    }
    .modal-body {
        padding: 30px;
    }
    .modal-body_instruction {
        margin-top: 24px;
        padding: 0 10px;
        h4 {
            font-size: 1rem;
            font-weight: bold;
        }
        ol {
            font-size: 0.75rem;
            padding-left: 15px;
            li {
                margin-bottom: 8px;
            }
        }
    }
    .modal-header {
        flex-direction: column;
        font-weight: 800;
        padding: 30px 60px 0 30px;
        .header_sub-title {
            font-size: 1rem;
            color: lighten($color: $cl-light-gray, $amount: 0);
            width: 100%;
        }
        .header_title {
            font-size: 30px;
            line-height: 30px;
            color: $cl-green;
        }
        button.close {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            right: 30px;
            top: 35px;
            height: 40px;
            width: 40px;
            border: 3px solid $cl-gray;
            padding: 0;
            border-radius: 50%;
            span {
                margin-top: -6px;
                font-size: 2.1875rem;
                text-align: initial;
                color: $cl-gray;
            }
        }
    }
}

/*********************
# Video Modal
*********************/
.modal.modal-image { 
    // min-width: 3000px;
    .modal-dialog {
        max-width: 95%;
        // margin: auto;
    }

    .modal-content {
        max-width: 100%;
        margin: auto;
    }
    .modal-header {
        margin-bottom: 40px;
        // padding: 30px 20px 50px;
        // border-bottom: 1px solid $cl-border;
    }
    img {
        width: 100%;
        margin-bottom: 24px;
        border-radius: 10px;
    }
    .download-images {
        // position: sticky;
        margin-bottom: 24px;
        margin-top: 24px;
        // right: 65px;
        // bottom: 200px;
    }
}

/*********************
# Video Modal
*********************/
.modal.modal-upload-files{ 
    .temp-label {
        margin-bottom: 4px;
        font-size: 13px;
        color: $cl-gray;
    }
    .custom-file:not(:last-child) {
        margin-bottom: 16px;
    }
    .modal-footer {
        flex-direction: column;
        border: none;
        .btn-primary {
            width: 100%;
    
        }
    }
    .content-agreement {
        font-size: 12px;
        background-color: $cl-light-gray;
        padding: 10px;
        color: $cl-gray;
        .form-group {
            margin-bottom: 0;
        }
    }
}

/*********************
# Review Countdown
*********************/
.modal.modal-review-countdown {
    text-align: center;
    .modal-header {
        display: flex;
        align-items: center;
        padding-bottom: 0;
        p {
            font-weight: 500;
        }
    }
    .modal-body {
        .review-countdown_sub-title {
            font-size: 12px;
            font-weight: 600;
            margin-bottom: 8px;
            color: $cl-gray;
        }
        .review-countdown {
            display: flex;
            margin: auto;
            background-color: #B9D4A3;
            color: #597D3C;
            width: 180px;
            height: 180px;
            border-radius: 50%;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            margin-bottom: 32px;
            .review-countdown_number {
                font-size: 4.25rem;
                font-weight: 800;
                line-height: 4.25rem;
            }
        
            .review-countdown_description {
                font-size: 1.25rem;
                font-weight: 600;
                div:last-child {
                    font-size: 0.6875rem;
                    font-weight: 500;
                    color: #729059;
                }
            }
        }
  
    }

    .modal-footer {
        padding-top: 0;
        border-top: 0;
        display: flex;
        align-items: center;
        flex-direction: column;
        .step_additiona-note {
            background-color: transparent;
            color: $cl-gray;
            margin-bottom: 0;
        }
    }
}


