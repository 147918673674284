@import '../../assets/styles/partials/_variables.scss';

section.deal_create {
    h4 {
        color: $cl-white;
    }
    .deal-deck {
        margin-bottom: 0;
        .card {
            min-width: 270px;
        }
    }
    
    form.deal_create_form {
        .card {
            margin: 40px auto;
            max-width: 300px;
            position: relative;
            border: 0;
            min-height: 500px;
            box-shadow: 0 15px 16px -10px rgba(0, 0, 0, 0.6);
            .card-body_mobile-image {
                margin-bottom: 20px;
            }
        
            .deal-points {
                display: block;
            }
        }
        padding: 24px;
        > .form-row {
            margin-bottom: 24px;
        }
        input,
        textarea {
            margin-bottom: 0;
        }
        label {
            max-width: 100%;
        }
        .form-text {
            font-style: italic;
            margin-top: 5px;
            display: flex;
            line-height: 18px;
            justify-content: space-between;
            &.--tooltip {
                justify-content: end;
                p {
                    margin-right: 5px;
                }
            }
        }
        .deal_create_related-deals,
        .deal_create_add-keywords,
        .deal_create_add-competitors {
            margin-top: 8px;
            padding: 16px;
            border: 2px dotted $cl-gray;
            .deal_create_add-keywords_buttons {
                text-align: right;
                padding-right: 30px;
                button:not(:last-child) {
                    margin-right: 10px;
                }
            }
            button {
                text-decoration: none;
                margin-right: 0;
                font-weight: bold;
                padding: 7px 15px;
                border: none;
                border-radius: 100%;
                color: white;
                background-color:$cl-green;
                &.btn-disabled {
                    background-color: $cl-gray;
                }
                &.--delete {
                    background-color: $red;
                }
                &:focus {
                    text-decoration: none;
                }
            }
            .col-lg-9,
            .col-lg-2 {
                margin-bottom: 10px;
            }
        }
        .deal_create_amazon-brand {
            select {
                text-transform: capitalize;
            }
        }
        .deal_invited_emails {
            textarea {
                height: 200px;
            }
        }
        
        .deal_create_related-deals,
        .deal_create_amzon-seller-name {
            height: 300px;
            overflow: auto;
            padding:10px;
            .deal_create_related-deals_items,
            .deal_create_amzon-seller-name_items {
                padding: 10px 10px 10px 20px;
                display: flex;
                // align-items: center;
                cursor: pointer;
                input {
                    visibility: hidden;
                    margin-right: -24px;
                }
                &.--is-selected {
                    background-color: $cl-light-purple;
                    .fa-fw {
                        color: $cl-green;
                    }
                }
                .left,
                .right{
                    display: flex;
                    align-items: center;
                    width: 60%;
                }   
                .left {
                    align-items: flex-start;
                    .item-name {
                        font-size: 13px;
                        font-weight: 500;
                        margin-bottom: 10px;
                        line-height: 1rem;
                        text-transform: none;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 3;
                        -webkit-box-orient: vertical;
                    }
                    .info-additional-detail {
                        display: flex;
                        align-items: center;
                        small {
                            position: relative;
                            padding: 0 10px;
                            &::after {
                                content:"";
                                position: absolute;
                                right: 0;
                                top: 5px;
                                width: 1px;
                                height: 15px;
                                background-color:rgba($color: $cl-light-gray, $alpha: 0.5);
                            }
                            &.item-region {
                                height: 25px;
                                display: inline-block;
                                padding-left: 0;
                                .flag-container i {
                                    font-size: 25px;
                                }
                            }
                        }
                    }
                }
                .right {
                    justify-content: flex-end;
                    width: 40%;
                }
                .item-image {
                    min-width: 60px;
                    width: 60px;
                    height: 60px;
                    background-size: 90%;
                    background-color: white;
                    background-position: center;
                    background-repeat: no-repeat;
                    margin-right: 16px;
                }

          
                
            }
        }
        

        .deal_create_amzon-seller-name {
            height: 250px;
            border: 2px dotted #666666;
            .form-group {
                // border-bottom: 1px solid $cl-light-purple;
                // background: red;
                .left {
                    display: flex;
                    align-items: center;
                    .item-name {
                        margin-bottom: 0;
                        color: white;
                        font-size: 16px;
                    }
                }
            }
        }
        
        .deal_create_add-competitors {
            .competitor_name {
                font-size: 18px;
                margin-bottom: 24px;
            }
            .competitor-section {
                padding: 20px 0;
                border-top: 2px solid $cl-light-purple;
                
                &:first-child {
                    border: none;
                }
                .row .row {
                    margin-bottom: 16px;
                }
                label {
                    width: 255px;
                }
                @include media-breakpoint-up(md) { 
                    .row > div:last-child {
                        align-items: baseline;
                        justify-content: center;
                        display: flex;
                    }
                }
      
           
            }
            .input-group {
                flex-direction: column;
            }
            .competitor_input-wrapper {
                display: flex;
            }
            .compare-competitors {
                margin: 24px 0;
                padding: 24px;
                background-color: $cl-light-purple;
                .compare-competitors-header {
                    width: 100%;
                    display: flex;
                    h6 {
                        font-weight: 500;
                        color: $cl-light-gray;
                        font-size: 1rem;
                    }
                    &.--on {
                        margin-bottom: 5px;
                    }
                }
                .compare-competitors-header_desscription {
                    font-size: 0.875rem;
                    margin-bottom: 24px;
                    color: rgba(255, 255, 255, 0.7);
                }
                .form-row {
                    .input-group {
                        flex-direction: initial;
                    }
                    .form-text {
                        margin-top: 0;
                        margin-bottom: 16px;
                        display: inherit;
                        min-height: 40px;
                        @include media-breakpoint-up(xl) { 
                            min-height: none;
                        }
                        svg {
                            margin-left: 5px;
                        }
                    }
                    
                }
            }
        }

        .deal-deck-wrapper {
            height: 100%;
            margin: 25px 40px 0 ;
            // background: red;
            padding-bottom: 40px;
            > div {
                position: sticky;
                top: 30px;
            }
        }
    } 
}