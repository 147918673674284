@import '../../assets/styles/partials/_variables.scss';

.cookie-consent {
    background-color: $cl-black;
    z-index: 999999;
    position: fixed;
    bottom: 0;
    width: 100%;
    @include media-breakpoint-down(sm) { 
        z-index: 112;
        padding-bottom: 80px;
    }
    .container {
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        padding: 10px;
        font-size: 0.875rem;
        
        p {
            color: white;
            margin-bottom: 0;
            @include media-breakpoint-down(sm) { 
               margin-bottom: 16px;
            }
            a {
                position: relative;
                font-weight: bold;
                
                &:hover{
                    text-decoration: none;
                    color: lighten($color: $cl-green, $amount: 10%);
                    &:after {
                        content: "";
                        position: absolute;
                        bottom: -5px;
                        background-color: lighten($color: $cl-green, $amount: 10%);;
                        left: 0;
                        width: 100%;
                        height: 2px;
                
                    }
                }
            }
        }
        button {
            margin-right: 8px;
            padding: 0.20rem 1rem;
            @include media-breakpoint-up(md) { 
                padding: 0.25rem 1.4rem;
             }
        }
        div:nth-child(2) {
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }

    }
}

// div.consent {
//     z-index: 999999;
//     display: flex;
//     justify-content: space-around;
//     position: fixed;
//     bottom: 0px;
//     background: black;
//     color: white;
//     left: 0;
//     width: 100%;
//     text-align: center;
//     padding: 0px 10px;

//     span {
//         color:white;
//         font-size: 12px;
//     }
// }