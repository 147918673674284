@import '../../assets/styles/partials/_variables.scss';

/*********************
Admin Deals

#General

*********************/

/*********************
General
*********************/
.page_admin {
    table {
        tfoot {
            th {
                border: none !important;
                &.deals_img {
                    width: 40px;
                }
            }
        }
    }
    .deals_overview {
        .table-data_name {
            .name-title {
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3;  /* Number of lines displayed before it truncate */
                 overflow: hidden;
            }
            .name-product {
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;  /* Number of lines displayed before it truncate */
                 overflow: hidden;
            }

            p {
                line-height: 13px;
                margin-bottom: 0;
            }
        }
    }
}