@import '../../../assets/styles/partials/_variables.scss';


/*********************
Point Status

#General
*********************/

/*********************
#General
*********************/


.point-status {
    position: relative;
    background-color: rgba($color: white, $alpha: 0.7);
    border: 1px solid $cl-border ;
    border-radius: 5px;
    margin: 0 auto 56px;
    padding: 20px 10% 15px;
    text-align: center;
    .point-status_redeem-points {
        // position: absolute;
        // bottom: 0;
        display: inline-block;
        font-size: 10px;
        margin-top: 10px;
        text-decoration: underline;
        // right: 0;
    }
    .point_status-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 3px;
        .icon-points {
            font-size: 1.25rem;
        }
        > span {
            margin-left: 5px;
        }
        .spinner {
            display: flex;
            > div {
                width: 10px;
                height: 10px;
            }
        }
    }
 
    p {
        margin-bottom: 2px;
    }
    .point_status-value {
        // opacity:0;
        transition:opacity .5s linear;
    }
}

.point-status.point-status_floater {
    display: flex;
    opacity: 1;
    padding: 5px 24px;
    position: fixed;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 3;
    border-radius: 25px;
    width: 270px;
    @include media-breakpoint-up(md) { 
        bottom: 60px;
        left: auto;
        right: 20px;
        transform: translateX(0);

    }
    div {
        margin-right: 20px;
    }
    p {
        font-size: 11px;
        line-height: 12px;
    }
    .point_status-wrapper {
        align-items: flex-start;
        justify-content: flex-start;
        span {
            font-size: 16px;
        }
    }
}