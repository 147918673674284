@import '_variables.scss';

/*********************
Alert
*********************/
.dp_alert {
    display: none;
    opacity: 0;
    position: fixed;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    width: 80%;
}

.page-alert {
    margin: 30px 20px 020px;
    border-radius: 5px;
    padding: 20px;
    box-shadow: $box-shadow-hover;
    border: 0;
    @include media-breakpoint-up(md) { 
        margin: 30px 0 0;
    }
    .alert-title {
        display: block;
        margin-bottom: 10px;
        font-size: 18px;
    }
    p:last-child {
        margin-bottom: 0;
    }
}

.alert-outline {
    border: 1px solid $cl-gray;
}