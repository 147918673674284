/*********************
Page: Login

#Body
#Form
#Modal : Sign-in Email 
*********************/
@import '../../assets/styles/partials/_variables.scss';

/*********************
#Body
*********************/
body.page_login-in {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
    background: url(../../assets/images/bg_splash-page_m.jpg) no-repeat center;
    background-size: cover;
    height: 100vh;
    .contact-us_msg {
        display: block;
        margin-top: 15px;
        font-weight: 700;
        font-size: 12px;
        line-height: 15px;
    }
    @include media-breakpoint-short-height {
        height: initial;
    }
    @include media-breakpoint-up(md) { 
        background-image: url(../../assets/images/bg_splash-page.jpg);
    }
    a:hover {
        color: darken($cl-green, 20%);
    }
    .btn-rounded {
        letter-spacing: 0.3px;
        padding: 9px 20px;
        min-width: 228px;
        
    }
    main {
        margin: 0;
        @include media-breakpoint-short-height {
            margin:40px 0;
        }   
    }
    .alert-danger {
        font-size: 0.8125rem;
        line-height: 18px;
        .btn-link {
            padding: 0;
            font-size: 0.8125rem;
            color: #721c24;
            font-weight: bold;
            text-decoration: underline;
            margin-top: -3px;
        }
    }
}

/*********************
#Form
*********************/
.sign-in{
    text-align: center;
    background: rgba(255,255,255, .9);
    max-width: 575px;
    border-radius: 5px;
    padding: 0 7% 15%;
    box-shadow: rgba(45, 45, 45, 0.05) 0px 2px 2px, 
        rgba(49, 49, 49, 0.05) 0px 4px 4px, 
        rgba(42, 42, 42, 0.05) 0px 8px 8px, 
        rgba(32, 32, 32, 0.05) 0px 16px 16px, 
        rgba(49, 49, 49, 0.05) 0px 32px 25px;
        @include media-breakpoint-up(md) { 
            min-width: 550px;
            padding: 15px 40px;
        }

	h1 {
        color: $cl-green;
        @include media-breakpoint-up(md) { 
            font-size: 1.875rem;
        }
    }
    .sign-in_copy {
        margin-bottom: 24px;   
    }
    .sign-in_login-methods {
        text-align: center;
        margin-bottom: 42px;
        h2 {
            font-size: 1rem;
            font-weight: bold;
            margin-bottom: 16px;
        }
        .alert {
            text-align: left;
        }
    }
    .sign-in_create-account {
        max-width: 300px;
        margin: auto;
        text-align: center;
        font-size: 12px;
        border-top: 1px solid $cl-border;
        border-bottom: 1px solid $cl-border;
        padding: 8px 0;
        margin-bottom: 52px;
    }
    .sign-in_line-break {
        display: block;
        text-align: center;
        padding: 10px ;
        text-align: center;
        padding: 10px;
        font-size: 12px;
        position: relative;
        margin-bottom: 20px;
        &:after,
        &:before {
            content: "";
            height: 1px;
            width: 45%;
            position: absolute;
            background-color: $cl-light-gray;
            top: 20px;
            left: 0;
        }
        &:before {
            left: auto;
            right: 0;
        }
    }
    .btn-rounded {
        font-size: 15px;
        &.--email {
            margin-bottom: 10px;
            // display: inline-block;
            i {
                margin-right: 10px;
                font-size: 19px;
            }
            background-color: $cl-black;
            &:hover,
            &:active{
                background-color: $cl-black;     
            }
        }
    }
	.sign-in_forgot,
	.forgot-password_forgot{
		display: block;
	}
	.change-password_copy  {
		text-align: center;
	}
	.sign-in_logo {
		max-width: 150px;
    }
    .btn-block + .btn-block {
        margin-top: 0;
    }
   
    button.sign-in_forgot {
        font-size: 0.8125rem;
    }
    .sign-in_copy  {
        img {
            width: 100px;
        }
    }

}
    


.footer_link,
.footer_copyright {
    text-align: center;
    color: lighten($cl-gray, 10%);
    font-size: 10px;
    line-height: 16px;
    button {
        font-size: 10px;
        font-weight: 600;
        color: lighten($cl-gray, 10%);
        margin-right: 10px;
        padding: 0;
        &:last-child {
            margin-right: 0;
        }
    }
}

/*********************
#Modal : Sign-in Email 
*********************/
.modal_sign-in {
  
    .modal-content {
        padding: 45px !important;
    }
    .modal-header {
        text-align: center;
        .modal-title {
            margin: auto
        }
        p.lead {
            margin-bottom: 0;
        }
    }
    .close {
        text-align: right;
        position: absolute;
        top: 15px;
        right: 15px;
    }
    .sign-in_forgot-password {
        input {
            margin-bottom: 2px;
        }
        .sign-in_forgot {
            padding: 0;
            font-size: 14px;
            text-align: right;
        }
    }
    .forgot-password_form { 
        box-shadow: none;
        padding: 0;
        min-width: auto;
        .forgot-password_copy {
            text-align: center;
        }
    }

}