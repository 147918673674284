@import '../../assets/styles/partials/variables.scss';

/*********************
Apply

#Body
#Snippet
#Form
#Button
#Auto Complete
#Progress Bar
#Header
#Footer
#Step
#Step : START
#Step : PayPal
#Step : Confirmation
#Step : Thank you 
#Step : Amazon Profile Url
#Step : NDA
*********************/

$cl-text : #606060;
$cl-green : #639F42;
$cl-light-green : #61B134;
$cl-background : #FCFFFA;

.page_apply {

    // input:-webkit-autofill {
    //     // Much nicer
    //     background-color: bisque;
    //     display: none;
    // }

    &.--redirection {
        .spinner {
            position: absolute;
            top : 50%;
            left: 50%;
      
            transform: perspective(20px) translateX(-50%);
        }

    }

    /*********************
    #Body
    *********************/
    background-color: $cl-background;
    font-size: 16pt;
    p {
        color: lighten($color: $cl-text, $amount: 10%);
    }
    
    // FIX this , shouldn't need to use important to remove landing page background , looko into why even is still appenind on resize  
    &:not(.step_landing-page) {
        background-color: $cl-background !important;
        background-image: none!important;
    }

    /*********************
    #HOTJAR SURVERY
    *********************/
    ._hj-widget-container {
        form {
            min-width: auto;
        }
    }
    /*********************
    #Snippet
    *********************/

    mark {
        color: white;
        background-color: lighten($color: $cl-green, $amount: 20%);
    }

    .google-privacy-policy {
        line-height: 1rem;
        padding: 0 15px;
        max-width: 500px;
        margin: 15px auto 0;
        text-align: center;
    }

    .grecaptcha-badge {
        bottom: 40px!important;
        visibility: hidden;
    }

    

    .modal {
        .close {
            &:hover {
                box-shadow: none;
            }
        }
    }
    .modal-content--copy {
        .modal-header {
            font-size: 35px;
        }
        .modal-body {
            .content,
            .container {
                padding: 0;
            }
        }
        main {
            height: auto;
        }
    }

    hr {
        width: 100%;
        border-color: $cl-light-gray;
    }

    .error-message {
        color: $cl-red;
        width: 100%;
        text-align: center;
        padding: 10px;
    }

    h2 {
        font-size: 1.3125rem;
        color: $cl-gray;
    }

    .slick-slider {
        @include media-breakpoint-up(lg) { 
            margin-left: -13px;
      
       }
        .slick-arrow {
            background: transparent;
            border-radius: 0;
            position: absolute;
            padding: initial;
            box-shadow: none;
            height: 100%;
            width:  40px;
            z-index: 2;
            &::before {
               display: none;
            //    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.04);
               font-size: 40px;
               opacity: 1;
               text-shadow: 4px 5px 6px rgba(0, 0, 0, 0.2);
                @include media-breakpoint-up(lg) { 
                     display: block;
               
                }
            }
            &:hover {
                &::before {
                    color: $cl-light-gray;
                }
            }
        }
        .slick-prev {
            opacity: 0;
        }
        .slick-next {
            float: right;
            right: -45px;
            color: transparent!important;
        }
    }

    /*********************
    #Form
    *********************/

    form {
        width: 100%;
        @include media-breakpoint-up(md) { 
            width: auto;
            min-width: 768px;
        }
        @include media-breakpoint-up(lg) { 
            transform: translateY(-2vh);
            min-width: 1024px;
        }
        .invalid-feedback {
            margin-top: 2px;
            padding-right: 10px;
            text-align: left;
            font-size: 0.6875rem;
            line-height: 1.125rem;
        }
        input[type="date"] { 
            &::-webkit-calendar-picker-indicator { 
                // display: none; 
            }
        }
    }

    .form-group-radio {
        width: 200px;
        margin: auto;
        @include media-breakpoint-up(md) { 
            width: auto;
            display: flex;
        }

        .custom-radio{
            margin: 0 0 16px;
            padding: 10px 0;
            display: flex;
            @include media-breakpoint-up(md) { 
                padding: 0;
                margin: 0 20px 0 ;
                flex-direction: column;
                align-items: center;
            }
            label {
                font-size: 1rem;
                line-height: 1.2rem;
                margin-bottom: 5px;
                min-width: 150px;
                .label_text {
                    text-transform: initial;
                    text-align: left;
                    @include media-breakpoint-up(md) { 
                        text-align: center;
                    }
                }
            }
           


            input[type="radio"] {
                &:checked,
                &:not(:checked) {
                    position: absolute;
                    left: -9999px;
                }
                &:checked + label,
                &:not(:checked) + label {
                    position: relative;
                    cursor: pointer;
                    line-height: 20px;
                    display: inline-block;
                    padding: 5px 0 0 45px;
                    @include media-breakpoint-up(md) { 
                        position: relative;
                        padding: 0 0 50px;
                        line-height: 20px;
                    }
                }
                &:checked + label:before,
                &:not(:checked) + label:before {
                    content: '';
                    position: absolute;
                    left: 14px;
                    -ms-transform: translateX(-50%);
                    transform: translateX(-50%);
                    top: 0;
                    width: 30px;
                    height: 30px;
                    border: 3px solid $cl-green;
                    border-radius: 100%;
                    background: transparent;
                    @include media-breakpoint-up(md) { 
                        width: 40px;
                        height: 40px;
                        left: 50%;
                        -ms-transform: translateX(-50%);
                        transform: translateX(-50%);
                        bottom: 0;
                        top: auto;   
                    }
                }
                &:checked + label:after,
                &:not(:checked) + label:after {
                    content: '';
                    position: absolute;
                    left: 4px;
                    -ms-transform: translateX(-41%);
                    transform: translateX(-41%);
                    top: 5px;
                    -ms-transform: translateY(-9%);
                    transform: translateY(-9%);
                    width: 20px;
                    height: 20px;
                    background: $cl-green;
                    border-radius: 100%;
                    -webkit-transition: all 0.2s ease;
                    transition: all 0.2s ease;
                    @include media-breakpoint-up(md) { 
                        width: 28px;
                        height: 28px;
                        left: 61px;
                        top: auto;
                        -ms-transform: translateX(-41%);
                        transform: translateX(-41%);
                        bottom: 6px;
                        -ms-transform: translateY(-9%);
                        transform: translateY(-9%);
                    }
      
                }
                &:not(:checked) + label:after {
                    opacity: 0;
                    -webkit-transform: scale(0);
                    transform: scale(0);
                }
                &:checked + label:after {
                    opacity: 1;
                    -webkit-transform: scale(1);
                    transform: scale(1);
                }
            }    
    
            // For longer text
            &:nth-child(2) {
                input[type="radio"] {
                    &:checked + label:after,
                    &:not(:checked) + label:after {
                        @include media-breakpoint-up(md) {  
                            left: 69px;                  
                        }
                    }
                    
                }      
            }
    
        }

        &.--right-align {
            display: flex;
            width: auto;
            justify-content: center;
            .custom-radio{
                
                    margin-right: 0;
               
                label {
            
                    .label_text {
                        text-transform: initial;
                        text-align: left;
                        margin-left: 20px;
                    }
                }
                input[type="radio"] {
                
                    &:checked + label,
                    &:not(:checked) + label {
                        position: relative;
                        cursor: pointer;
                        line-height: 20px;
                        display: inline-block;
                        padding: 5px 0 0 15px;
                        @include media-breakpoint-up(md) { 
                            position: relative;
                            padding: 10px;
                            line-height: 20px;
                        }
                    }
                    &:checked + label:before,
                    &:not(:checked) + label:before {
                        @include media-breakpoint-up(md) { 
                            width: 40px;
                            height: 40px;
                            left: 0;
                            top: 0;   
                        }
                    }
                    &:checked + label:after,
                    &:not(:checked) + label:after {
                        @include media-breakpoint-up(md) { 
                            left: -14px;
                        }
        
                    }
                    &:nth-child(2) {
                        input[type="radio"] {
                            &:checked + label:after,
                            &:not(:checked) + label:after {
                                @include media-breakpoint-up(md) {  
                                    left: 0;                  
                                }
                            }
                        }      
                    }
                }   
            }
        }
    }


    

    .form-group-checkbox {
        
        -webkit-column-count: 2;
        -moz-column-count: 2;
            column-count: 2;
        -webkit-column-gap: 1rem;
            -moz-column-gap: 1rem;
            column-gap: 1rem;
             max-width: 400px;
             margin: auto;
             margin-left: 30px;
             @include media-breakpoint-up(md) { 
                margin-left: 5rem;
                margin: initial;
            }
            @include media-breakpoint-up(lg) { 
       
                padding-left: 0;
                -webkit-column-count: 3;
                -moz-column-count: 3;
                     column-count: 3;
                -webkit-column-gap: 8rem;
                -moz-column-gap: 8rem;
                     column-gap: 8rem;
                -moz-column-fill: auto;
                column-fill: auto;
                height: 185px;
                max-width: initial;
     
            }
            @include media-breakpoint-up(xl) { 
                margin-left: 7rem;
                padding-left: 0;
                -webkit-column-count: 3;
                -moz-column-count: 3;
                     column-count: 3;
                -webkit-column-gap: 8rem;
                -moz-column-gap: 8rem;
                     column-gap: 8rem;
                -moz-column-fill: auto;
                column-fill: auto;
                height: 185px;
                max-width: initial;
     
            }
    }
    
    
    .custom-checkbox{
        display: block;
        margin-bottom: 10px;
        float: left;
        @include media-breakpoint-up(lg) { 
            // padding: 0;
            // margin: 0 20px 15px;
            // flex-direction: column;
            // align-items: center;
            // &:nth-child(3) {
            //     margin-bottom: 0;
            // }
        }
        label {
            font-size: 1rem;
            line-height: 1.2rem;
            margin-bottom: 5px;
            min-width: 150px;
            .label_text {
                text-transform: initial;
                text-align: left;
                max-width: 150px;
                font-size: 12px;
                @include media-breakpoint-up(md) { 
                    max-width: 100%;
                    font-size: 1rem;
                }
            }
        }

        input[type="checkbox"] {
            &:checked,
            &:not(:checked) {
                position: absolute;
                left: -9999px;
            }
            &:checked + label,
            &:not(:checked) + label {
                position: relative;
                cursor: pointer;
                line-height: 20px;
                display: flex;
                padding: 0 0 0 35px;
                min-height: 40px;
                align-items: center;
                @include media-breakpoint-up(md) { 
                    position: relative;
                    padding: 1px 0;
                    line-height: 20px;
                    padding-left:  50px;
                }
            }
            &:checked + label:before,
            &:not(:checked) + label:before {
                content: '';
                position: absolute;
                left: 14px;
                -ms-transform: translateX(-50%);
                transform: translateX(-50%);
                top: 5px;
                width: 30px;
                height: 30px;
                border: 3px solid $cl-green;
                border-radius: 3px;
                background: transparent;
                @include media-breakpoint-up(md) { 
                    width: 40px;
                    height: 40px;
                    left: 0;
                    // -ms-transform: translateX(-50%);
                    transform: translateX(0);
                    bottom: 0;
                    top: auto;   
                }
            }
            &:checked + label:after,
            &:not(:checked) + label:after {
                content: '';
                position: absolute;
                left: 5px;
                -ms-transform: translateX(-41%);
                transform: translateX(-41%);
                top: 10px;
                -ms-transform: translateY(-9%);
                transform: translateY(-9%);
                width: 18px;
                height: 18px;
                background: $cl-green;
                border-radius: 3px;
                -webkit-transition: all 0.2s ease;
                transition: all 0.2s ease;
                @include media-breakpoint-up(md) { 
                    width: 25px;
                    height: 25px;
                    left: 7px;
                    top: auto;
                    -ms-transform: translateX(-41%);
                    transform: translateX(-41%);
                    bottom: 8px;
                    -ms-transform: translateY(-9%);
                    transform: translateY(-9%);
                }
  
            }
            &:not(:checked) + label:after {
                opacity: 0;
                -webkit-transform: scale(0);
                transform: scale(0);
            }
            &:checked + label:after {
                opacity: 1;
                -webkit-transform: scale(1);
                transform: scale(1);
            }
        }    

        &.--small {
            input[type="checkbox"] {
                &:checked + label:before,
                &:not(:checked) + label:before {
                    content: '';
                    position: absolute;
                    left: 14px;
                    -ms-transform: translateX(-50%);
                    transform: translateX(-50%);
                    top: 7px;
                    width: 25px;
                    height: 25px;
                    border: 2px solid $cl-green;
                    border-radius: 3px;
                    background: transparent;
              
                }
                &:checked + label:after,
                &:not(:checked) + label:after {
                    content: '';
                    position: absolute;
                    left: 5px;
                    -ms-transform: translateX(-41%);
                    transform: translateX(-41%);
                    top: 12px;
                    -ms-transform: translateY(-9%);
                    transform: translateY(-9%);
                    width: 18px;
                    height: 18px;
                    background: $cl-green;
                    border-radius: 3px;
                    -webkit-transition: all 0.2s ease;
                    transition: all 0.2s ease;
                   
      
                }
            }
            .label_text {
                padding-left: 16px;
                font-size: 0.75rem;
            }
        }
    }

    /*********************
    #Auto Complete
    *********************/
    
    .react-autosuggest__container{
        .react-autosuggest__suggestions-list
        {
            max-height: 200px;
            overflow-y: auto;
        }
        input {
            border: 1px solid $cl-border;
            height: calc(1.5em + 1rem + 2px);
            padding: 0.5rem 1rem;
            font-size: 1.25rem;
            line-height: 1.5;
            border-radius: 0.3rem;
            transition: all 0.2s ease-in-out;
            &:focus {
                box-shadow: 0px 1px 22px rgba($color: #82B856, $alpha: 0.2);
                outline: none;
            }
        }
    }

    .react-autosuggest__suggestions-container {
        width: 400px;
        margin-top: 20px;
        box-shadow: 0px 1px 22px rgba(130, 184, 86, 0.2);
        ul {
            padding: 10px 0 ;
            list-style-type: none;
            background-color: white;
            border: 1px solid  $cl-border;
            border-radius: 0.3rem;
            text-align: left;
            padding: 0;
            li {
                padding: 10px 20px;
                &:hover {
                    cursor: pointer;
                    background-color: lighten($color: $cl-light-gray, $amount: 5%);
                }
            }
            // box-shadow: 0 1px 10px  rgba($color: $cl-gray, $alpha: 0.2);
        }
    }


    /*********************
    #Button
    *********************/
    button {
        padding:10px 60px;
        font-size: 0.875rem;
        @include media-breakpoint-up(md) { 
            padding:5px 50px;
            font-size: 1rem;
            line-height:32px; 
        }

        position: relative;
        height: auto;
        display:inline-block;
        text-align:center;        
        -webkit-border-radius: 30px;
        -moz-border-radius: 30px;
        border-radius: 30px;
        transition: all 1.2s ease-in-out;
        -webkit-transition: all 0.2s ease-in-out;  
        // background-color: linear-gradient(82deg, $cl-green 22.81%, $cl-light-green 77.8%);
        box-shadow: 0px 4px 4px rgba(99, 159, 66, 0.26);
        background: linear-gradient(82deg, $cl-green 22.81%, $cl-light-green 77.8%);
        &:disabled {
            background: $cl-light-gray;
        }
        &.btn-fb {
            margin: auto;
            @include media-breakpoint-up(lg) { 
                margin-right: 12px;
            }
        }
        &.btn-sm {
            // padding: 0.25rem 0.5rem;
            font-size: 12px;
            padding: 0.5rem 1.875rem;
            @include media-breakpoint-up(md) { 
                padding: 0 1.875rem;
            }
        }
        &.btn-outline-success {
            background: transparent;
            box-shadow: none;
            &:hover {
                background: linear-gradient(82deg, $cl-green 22.81%, $cl-light-green 77.8%);
            }
        }
        svg {
            position: absolute;
            top: 12px;
            right: 10px;
            transition: all 1.2s ease-in-out;
            -webkit-transition: all 0.2s ease-in-out;  
            @include media-breakpoint-up(md) { 
                right: 15px;
            }
        }
        &:hover {
            // box-shadow: 0 7px 15px rgba($color: $cl-green, $alpha: 0.5), 0 8px 6px -8px rgba($color: $cl-green, $alpha: 0.5);
            // -webkit-transform: translateY(-1px);
            // transform: translateY(-1px);
            background-color: linear-gradient(82deg, darken($color: $cl-green, $amount: 5%)  22.81%, darken($color: $cl-light-green, $amount: 5%) 77.8%);
            svg {
                position: absolute;
              
                -webkit-transform: translateX(5px);
                transform: translateX(5px);
            }
        }
        &.btn-link {
            padding: 10px 0;
            background: transparent;
            box-shadow: none;
            &:hover {
                box-shadow: none;
                transform: none
            }
        }
        &.--skip {
            display: block;
            font-size: 0.875rem;
            font-weight: 500;
            color: $cl-light-green;
            margin: auto;
        }
        &.close {
            background: transparent;
        }
        &:disabled {
            box-shadow: none;
            background-color: $cl-light-gray;
        }
    }


    /*********************
    #Progress Bar
    *********************/
    .progress-container {
        margin-bottom: 40px;
        @include media-breakpoint-up(md) { 
            margin-bottom: 0;
        }
        .progress {
            z-index: 2;
            width: 100%;
            border-radius: 0;
            height: 20px;
            background-color: transparent;
            @include media-breakpoint-up(md) { 
                height: 35px;
            }
            &.--fixed {
                position: fixed;
                top: 0;
            }
            .progress-bar {
                background: linear-gradient(82deg, $cl-green 22.81%, $cl-light-green 77.8%);
    
            }
        }
        .progress-counter {
            position: absolute;
            right: 10px;
            top: 2px;
            color: rgba($color: $cl-green, $alpha: 0.7);
            font-size: 12px;
            @include media-breakpoint-up(md) { 
                top:5px;
            }
        }
    }


    
    /*********************
    #Header
    *********************/
    nav {
        background-color: transparent;
        box-shadow: none;
        height: 50px;
        .container {
            display: flex;
            align-items: center;
            justify-content: center;
            padding-right: 15px;
            padding-left: 15px;
            img {
                width: 100px;
            }
            @include media-breakpoint-up(md) { 
                justify-content: start;
                img {
                    width: 150px;
                }
            }
            
        }
       
    }

    /*********************
    #Footer
    *********************/
    nav.footer {
        position: fixed;
        bottom: 0;
        @include media-breakpoint-short-height {
            position: relative;
            height: auto;
        }
        @include media-breakpoint-down(md) { 
            position: relative;
            height: auto;
        }
        button  {
            font-size: 12px;
            font-weight: 500;
            margin-right: 10px;
            padding-top: 0;
            &:last-child {
                margin-right: 0;
            }
        }
        .container {
            // margin-bottom: 20px;
            flex-direction: column;
            justify-content: center;
            .gdpr-disclaimer {
                color: $cl-gray;
                font-size: 0.6875rem;
                font-style: italic;
                text-align: center;
            }
            button: {
                padding-bottom: 0;
            }
        }
    }

    /*********************
    #Step
    *********************/
   
    main {
        margin:  auto ;
        display: flex;
        align-items: center;
        justify-content: center;
        
        @include media-breakpoint-up(lg) {
            height: 95vh;
        }
        @include media-breakpoint-up(xl) {
            height: 90vh;
            max-width: 1200px;
        }
        @include media-breakpoint-short-height {
            height: auto;
        }
    }
    .page-header{
        padding: 0;
        display: inherit;
        margin-bottom: 0;
        @include media-breakpoint-up(md) { 
            margin: 42px auto 0 ;
            padding: 35px 8%;
            display: flex;
        }

        h1 {
            color: $cl-text;
            text-transform: initial;
            margin-bottom: 8px;
            line-height: 1.3em;
            &::before {
                border: none;
            }
        }
        .lead {
            font-size: 1.125rem;
            line-height: 1.7rem;
            width: 100%;
            @include media-breakpoint-up(md) { 
                font-size: 1.25rem;
                line-height: 1.8rem;
            }
            &.text-small {
                font-size: 14px;
                line-height: 18px;
            }
        }
        &.box {
            width: 100%;
            align-items: flex-start;
            background: $cl-white;
            box-shadow: 0 16px 29px 0 rgba(0,0,0,0.12);
            border-radius: 5px;  
            h1 {
                padding: 0;
            }
            @include media-breakpoint-up(md) { 
                max-width: 600px;
            }

        }
    }
    .apply-step_navigation {
        // display: flex;
        // justify-content: center;
        margin-top: 24px;
        flex-direction: column;
        @include media-breakpoint-up(md) { 
            display: inherit;
        }
        &.apply-step_navigation-buttons {
            button {
                position: relative;
                margin-right: 40px;
                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
    input {
        @include media-breakpoint-up(md) { 
            min-width: 400px;
        }
    }

    /*********************
    #Step : Amazon Profile Url
    *********************/

    &.step_amz-profile-url {
        .page-header {
            padding: 0;
        }
        .amz-profile-instruction {
            padding: 0 16px;
            margin-top: 32px;
            text-align: left;
            max-width: 500px;
            width: 100%;
            .invalid-feedback {
                padding-left: 0;
            }
            .instruction-video {
                min-width: 300px;
                p {
                    text-align: center;
                }
                .instruction-video_thumbnail {
                
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: $cl-gray;
                    background-size: contain;
                    text-align: center;
                    border-radius: 3px;
                    height: 200px;
                    width: 100%;
                    margin-bottom: 24px;
                    color: white;
                    font-weight: normal;
                    flex-direction: column;
                    font-size: 14px;
                    box-shadow: 0 10px 25px rgba(0,0,0,.15), 0 8px 10px -6px rgba(0,0,0,.2);
                    @include media-breakpoint-up(md) { 
                        height: 260px;
                    }
                    cursor: pointer;
                    .instruction-video_control {
                        z-index: 5;
                        margin-top: 25px;
                        transition: all 0.2s ease-in-out;
                        .text {
                            margin-top: 16px;
                            opacity: 0;
                        }
                    }
                    .instruction-video_overlay {
                        position: absolute;
                        top: 0;
                        height: 100%;
                        width: 100%;
                        background-color: rgba($color: $cl-black, $alpha: 0.9);
                        transition: all 0.2s ease-in-out;
                    }
                    &:hover {
                        .instruction-video_control {
                            color: $cl-green;
                            .text {
                                opacity: 1;
                                color: white;
                            }
                        }
                        .instruction-video_overlay {
                            background-color: rgba($color: $cl-black, $alpha: 0.95);
                        }
                    }
                }
                // background-color: $cl-light-gray;
            }
            h4 {
                font-weight: bold;
            }
            img,
            video {
                
                width: 100%;
                box-shadow: 0 10px 25px rgba(0,0,0,.15), 0 8px 10px -6px rgba(0,0,0,.2);
                background-color: rgba(0,0,0,.80);
                height: auto;
                @include media-breakpoint-up(md) { 
                    min-height: 250px;
                }
            }
            ol {
                padding-left: 20px;
                li {
                    margin-bottom: 10px;
                }
            }
            .apply-step_navigation-buttons {
                text-align: center;
            }
            .col-md-6 {
                align-items: center;
                justify-content: center;
                margin-bottom: 24px;
            }
          
            @include media-breakpoint-up(md) { 
                padding: 0;
                h4 {
                    margin-top: 10px;
                }
                .col-md-6:last-child{
                    padding: 0 0 0 24px;
                }
            }
                       
        }
        .help-video  {
            font-size: 0.75rem;
            display: flex;
            align-items: center;
            justify-content: center;
            font-style: italic;
            .how-to-video_wrapper  {
                display: inline-block;
                button {
                    font-style: italic;
                    margin-left: 5px;
                    font-size: 0.75rem;
                    display: inline-block;
                    padding: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
        
    }

    /*********************
    #Step : Websites
    *********************/
    &.step_websites,
    &.step_categories {
        h1 {
            @include media-breakpoint-up(md) { 
                padding: 0 44px;
            }
        }
        .custom-checkbox {
            float: initial;
            display: flex;
            align-items: center;
            margin-bottom: 20px;
           
        }
    }

    /*********************
    #Step : NDA
    *********************/

    &.step_nda {
        main {
            height: auto;
        }
        nav.footer {
            position: relative;
        }
        h1 {
            margin-bottom: 24px;
            @include media-breakpoint-down(md) { 
                font-size: 24px;
            }
        }
        .container-fluid {
            // padding: 0 10%;
            max-width: 750px;
        }
        button.btn-link {
            padding: 0;
            line-height: 24px;
        }
        .custom-radio label {
            @include media-breakpoint-down(md) { 
                min-width: 120px;
                margin-left: 0;
                .label_text {
                    font-size: 14px;
                }
            }
        }
        .--right-align {
            .custom-radio:first-child {
                @include media-breakpoint-up(md) { 
                    margin-left: 80px;
                }
            }
        }
        
    }
    
    /*********************
    #Step : Landing Page
    *********************/

    &.step_landing-page {
        // background-color:#7eced5 ;

        @include media-breakpoint-up(md) {  
            background-repeat: no-repeat;
            background-position-y: top;
            background-position-x: 150%;
            background-size: contain;
        }

        @include media-breakpoint-up(lg) { 
        
            background-position-y: top;
            background-position-x: 140%;
        }
        @include media-breakpoint-up(xl) { 
            background-position-y: top;
            background-position-x: 100%;
        }
     
    
        .progress-container {
            display: none;
        }
        nav {
            margin: 4px 0;
   
            .navbar-brand {
                margin-right: 0;
            }
            @include media-breakpoint-up(md) { 
                margin: 8px 0;
                padding: 0 5%;
           }
           @include media-breakpoint-up(lg) { 
                    margin-top: 0;
                    padding-top: 5%;
                
            }
           .container {
                justify-content: center;
                img {
                    width: 140px;
                }
                @include media-breakpoint-up(lg) { 
                    max-width: 1400px;
                    justify-content: start;
                    img {
                        width: 120px;
                    }
                }
            }
        }
     
        main {
            display: block;
            height: auto;
            max-width: none;
            @include media-breakpoint-up(md) { 
                padding: 2% 0;
                max-width: 1200px;
            }
            @include media-breakpoint-up(lg) { 
                max-width: none;
                // padding: 5% 5%;
                height: 100%;
            }
            .hero-image {
                // background-image: ;
                img {
                    width: 100%;
                }
                &.--mobile {
                    @include media-breakpoint-up(lg) { 
                         display: none;
                    }
                }
            }
            > section {
                @include media-breakpoint-down(md) { 
                    overflow: hidden;
               }
            }
            > section:last-child {
                @include media-breakpoint-down(md) { 
                    padding: 0;
                    max-width: initial;
               }
            }
            .container {
                @include media-breakpoint-up(lg) { 
                    max-width: 1400px;
               }
            }
            .fb-login-btn {
     
                .btn-fb {
                    color: white;
                    padding:15px 20px;
                    font-size: 0.875rem;
                    font-weight: bold;
                    @include media-breakpoint-up(md) { 
                        padding:10px 40px;
                        font-size: 1rem;
                        line-height:32px; 
                        margin-right: 15px;
                    }
                    position: relative;
                    height: auto;
                    display:inline-block;
                    text-align:center;        
                    -webkit-border-radius: 30px;
                    -moz-border-radius: 30px;
                    border-radius: 30px;
                    transition: all 1.2s ease-in-out;
                    -webkit-transition: all 0.2s ease-in-out;  
                    &:active {
                        background-color: #007bff;
              
                    }
                }
            }
        }

        .page-header, 
        .account-creation {
            @include media-breakpoint-down(lg) { 
                padding: 20px 24px;
            }
           
        }

        .page-header, 
        .account-creation,
        .testimonial-list {
            h2 {
               margin-bottom: 8px;
               @include media-breakpoint-down(md) { 
                   padding: 0 15%;
                   text-align: center;
               }
            }
        }

        .page-header {
            margin-top: 0;
            align-items: start;
            margin-bottom: 24px;
            text-align: center;
            
            .navbar-brand {
                img {
                    width: 150px;
                }
            }
            @include media-breakpoint-up(lg) { 
                padding: 0;
                text-align: left;
            }
            h1 {
                padding: 0;
                line-height: 32px;
            
                @include media-breakpoint-up(md) {        
                    font-size: 2.7rem;
                    line-height: 3.3rem;
                    margin-top: 15px;
                }
            }
            .lead {
                padding: 0;
                margin-bottom: 8px;

            }
            p.mute {
                opacity: 0.6;
                font-size: 16px;
                width: 100%;
            }
            .cta {
                display: block;
                margin: auto;
                padding: 15px 40px;
                color: white;
                @include media-breakpoint-up(md) { 
                   display: none;
                }
            }
        }

        .testimonial-list {
            @include media-breakpoint-down(md) { 
                text-align: left;
                overflow: hidden;
            }
       
            .slick-list {
                
                min-height: 260px;
                overflow: hidden;
               
                @include media-breakpoint-up(lg) { 
                    min-height:260px;
                    padding: 10px 0px!important;
                }
                @include media-breakpoint-up(xl) { 
                    min-height:280px;
                }
               
            }
            .slick-slide {
                padding:  0 15px 0 15px;
                .testimonial {
                    position: relative;
                    background-color: $cl-white;
                    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.02);
                    border-radius: 10px;
                    padding: 15px;
                    margin: 11px 10px;
                    // min-height: 215px;
                    @include media-breakpoint-up(lg) { 
                        margin-top: 14px ;
                    }
                    @include media-breakpoint-up(xl) { 
                        // min-height: 220px;
                        margin: 0 ;
                    }
                }
                .testimonial-content {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    min-height: 100px;
                    padding-top: 20px;
                    @include media-breakpoint-up(xl) { 
                        min-height: 201px;
                    }
                }
                .star-rating-container {
                    font-size: 1.4rem;
                    label {
                        margin-right: 4px;
                    }
                }
                p {
                    font-size: 12px;
                    line-height: 18px;
                    min-height: 100px;
                    @include media-breakpoint-up(xl) { 
                        min-height: auto;
                    }
                 
                }
                .testimonial_quotes {
                    position: absolute;
                    right: -10px;
                    top: -10px;
                    background: linear-gradient(82deg, $cl-green 22.81%, $cl-light-green 77.8%);
                    height: 50px;
                    width: 50px;
                    color: white;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 25px;

                }
                .testimonial_author {
                    display: flex;
                    align-items: center;
                    font-size: 12px;
                    line-height: 18px;
                    font-weight: normal;
                    // @include media-breakpoint-down(sm) { 
                    //     position: absolute;
                    //     bottom: 20px;
                    // }
                    // @include media-breakpoint-up(xl) { 
                    //     position: absolute;
                    //     bottom: 20px;
                    // }
                    .testimonial_author-image {
                        width: 40px;
                        height: 40px;
                        border-radius: 25px;
                        background-size: cover;
                        background-position: center;
                        margin-right: 16px;
                        background-color: $cl-light-gray;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        text-transform: uppercase;
                        font-weight: 700;
                    }.testimonial_author-name {
                        font-weight: 600;
                    }
                    .testimonial_author-date {
                        color: lighten($color: $cl-gray, $amount: 35%)
                    }
                }
            }
         
        }
        .account-creation {
            text-align: center;
            margin-bottom: 100px;
            h2 {
                margin-bottom: 3px;
            }
            p {
                margin-bottom: 15px;
            }
            @include media-breakpoint-up(lg) { 
                margin-bottom: 40px;
                text-align: left;
            }
            .wrapper {
                margin-bottom: 24px;
                @include media-breakpoint-up(lg) { 
                    display: flex;
                    align-items: center;
                    margin-bottom: 0;
                }
                > div {
                    text-align: center;
                    .facebook-button {
                        display: flex;
                        min-height: 40px;
                        border-radius: 30px;
                        padding: 10px;
                        min-width: 256px;
                        background-color: #1877f2;
                        justify-content: center;
                        max-width: 200px;
                        margin: auto;
                        @include media-breakpoint-up(lg) { 
                            margin: 0 15px 0 0;
                            max-width: unset;
                        }
                        ._8jan {
                            padding: 0 30px
                        }
                        
                    }
                    .fb-login-button-rerequest {
                        button {
                            color: white;
                            margin: 0 auto 15px  ;
                            @include media-breakpoint-up(lg) { 
                                margin-right: 15px;
                            }
                        }
                    }
                    small {
                        margin-left: 0;
                        font-size: 11px;
                        line-height: 14px;
                        opacity: .8;
                    }
                }
              
            }
            .log-in {
                border-top: .5px solid $cl-light-gray;
                border-bottom: .5px solid $cl-light-gray;
                font-weight: 500;
                padding: 16px 0;
                font-size: 13px;
                margin-top: 24px;
                text-align: center;
                margin-bottom: 0;
                @include media-breakpoint-up(lg) { 
                    border: 0;
                    margin-top: 0;
                    max-width: 390px;
                }
            }
            .facebook-msg {
                font-size: 0.6875rem;
                line-height: 0.875rem;
                max-width: 300px;
                margin: auto;
                &.--desktop {
                    display: none;
                }
                &.--error {
                    color: $cl-red;
                }
                @include media-breakpoint-up(lg) { 
                    padding:10px;
                    max-width: 260px;
                    text-align: left;
                    margin: 0;
                    &.--mobile {
                        display: none;
                    }
                    &.--desktop {
                        display: block;
                    }
                }
            }
        }

        // .mobile-footer {
        //     @include media-breakpoint-up(md) { 
        //         display: none;
        //     }
              
        // }
        .footer {
            position: relative;
            margin-top: 6%;
            padding: 0;
            z-index: 111;
            
            .container {    
                button {
                    padding-top: 0;
                    
                }
                @include media-breakpoint-up(lg) { 
                    flex-direction: column;
                    padding: 0;
                    align-items: baseline;
                    button {
                        color: #42777b;
                    }
                }
            }
            @include media-breakpoint-up(lg) { 
                margin: 0;
                padding: 0;
                // display: none;
            }
            @include media-breakpoint-up(xl) { 
                // border-top: 1px solid $cl-text;
            }
        }
        .--dark {
            $darkColor : #3e3e3e;
            h1 {
                color: $darkColor;
            }
            h2 {
                color: $darkColor;
            }
            p {
                color: $darkColor;;
            }
            a,button {
                
                @include media-breakpoint-up(lg) { 
                    color: #42777b;
                }
            }
      
        }
    }

    /*********************
    #Step : User Information
    *********************/
    &.step_user-information {
        height: auto;
        
        input[name=user_birthday].--empty {
            color: $cl-light-gray;
        }

        main {
            height: auto;
        }

        select {
            height: 50px !important;
            padding: 10px 12px;
        }
        .required-footnote {
            width: 100%;
            text-align: right;
            padding-bottom: 10px;
            font-size: 12px;
            font-weight: bold;
        }
        sup {
            font-weight: bold;
            font-size: 12px;
            color: $cl-green;
            top:  -.2em;
        }
        .page-header {
            margin-top: 50px;
            h1 {
                @include media-breakpoint-up(md) { 
                    font-size: 3.375rem;              
                }
            }
            p.lead {
                padding: 0;
            }
        }
        .optin-desktop {
            display: none;
            @include media-breakpoint-up(md) { 
                display: flex; 
             }
        }

        .optin-mobile {
            margin-bottom: 16px;
            @include media-breakpoint-up(md) { 
               display: none; 
            }
        }
        .form-shipping-info {
            background-color: #f0f7ec;
            margin-bottom: 5px;
            padding: 10px 20px;
        }
        .page-form {
            padding: 0 2%;
            @include media-breakpoint-up(md) { 
                padding: 0 8%;            
            }
            .form-subtitle {
                margin-top: 16px;
                margin-bottom: 16px;
                p {
                    margin-bottom: 0;
                    &.text-small {
                        font-size: 14px;
                    }
                }
            }
            .form-group {
                text-align: left;
                input {
                    min-width: initial;
                }
                small.text-muted {
                    font-size: 10px;
                    line-height: 16px;
                }
            }
            // .col-md-4:first-child {
            //     .form-group {
            //         @include media-breakpoint-down(md) { 
            //             margin-bottom: 0;         
            //         }
            //     }
            // }
            .custom-checkbox {
                margin-top: 8px;
                margin-left: 15px;
                .label_text {
                    max-width: initial;
                    @include media-breakpoint-down(md) { 
                        padding-left: 0;
                    }
                }
            }
            .apply-step_navigation-buttons {
                margin: auto;
                margin-top: 24px;
            }
        }

    
        .footer {
            position: relative;
            margin-top: 40px;
        }
  
    }

    /*********************
    #Step : PayPal
    *********************/
    &.step_pp-account {
        .custom-checkbox {
            margin-top: 16px;
            margin-left: 0;
            width: 100%;
            label {
                margin-bottom: 0;
            }
            .label_text {
                color: $cl-green;
                max-width: 100%;
                @include media-breakpoint-down(sm) { 
                    padding-left: 0;
                }
            }
        }
        .apply-step_navigation-buttons {
            button {
                margin-top: 24px;
            }
        }
        .custom-label-msg {
            width: 370px;
            text-align: left;
            padding-left: 45px;
            font-weight: normal;
            opacity: 0;
            transition: all 0.2s ease-in-out;
            display: none;
            &.--show {
                opacity: 1;
                display: block;
            }
        }
    }

    /*********************
    #Step : Confirmation
    *********************/
    &.step_confirmation {
        margin-top: 0;
        @include media-breakpoint-up(md) { 
            margin-top: 2%;
        }
        @include media-breakpoint-up(xl) { 
            margin-top: 24px;
        }
        p {
            text-align: left;
        }
        .box {
            margin: auto;
            max-width: 570px;
        }
        .page-header {
            text-align: left;
            padding: 20px 6% 5px;
         
        }
        hr {
            margin-top: 0;
        }
        .form-group {
            width: 100%;
            margin-bottom: 0;
        }
        .confirmation_results {
            margin-bottom: 16px;
            .result-container {
                text-align: left;
                margin-bottom: 16px;
                > p{
                    margin-bottom: 0;
                }
                p {
                    font-weight: 500;
                }
                .result-container_answer {
                    color: $cl-green;
                    width: 100%;
                    overflow-wrap: break-word;
                    word-wrap: break-word;
                    hyphens: auto;
                }
            }
        }
        label {
            font-weight: 500;
            font-size: 15px;
        }
        .apply-step_navigation-buttons  {
            width: 100%;
            display: flex;
            justify-content: flex-end;
            margin-top: 16px;
            margin-bottom: 16px;
        }
    }

    /*********************
    #Step : Thank You 
    *********************/
    &.step_thank-you {
        .progress-counter {
            display: none;
        }
        @include media-breakpoint-up(md) { 
            height: 60vh;
        }
        section {
            max-width: 1000px;
        }
        .box {
            padding: 20px;
        }
        .page-header  {
            text-align: center;
        }
        svg {
            display: block;
            margin: 0 auto 15px;
            @include media-breakpoint-up(md) { 
                margin-bottom: 24px;
            }
            path {
                fill: $cl-light-green ;
            }
        }
      
    }
   
}