@import '../../assets/styles/partials/variables.scss';

/*********************
Bonus Invite

#Table

*********************/

/*********************
Table
*********************/
.bonus_invite table{
    thead {
        th {
            &:first-child {
                padding-left: 0;
            }
        }
    }
}