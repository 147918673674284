@import '../../assets/styles/partials/_variables.scss';

/*********************
Profile

#General
#Mobile Only
#React Table
*********************/


/*********************
General
*********************/
.main-content.profile {
    @include media-breakpoint-up(md) { 
        padding-top: 100px;
        padding-bottom: 100px;
    }
}
#profile-page {
    h1 {
        font-size: 1.125em;
        font-weight: 200;
        margin-bottom: 50px; 
        span {
            color: $cl-green;
            font-size: 1.563em;
            font-weight : 600;
            display: block;
        }
    }
    
    .side-nav {
        display: none;
        color: $cl-green;
        background: #FFFFFF;
        box-shadow: 0 7px 10px rgba(45,45,45,.2);
        border-radius: 5px;
        font-size: 0.875em;
        // padding: 0 30px; //uncomment when point system is back
        padding: 20px 30px;
        margin-bottom: 32px;
        // min-height: 350px; //uncomment when point system is back
        min-height: 100px;
    
        @include media-breakpoint-up(md) { 
            display: block;
            position: -webkit-sticky;
            position: sticky;
            top: 80px;
            z-index: 1020;
        }
    }
    
    .profile_side-nav {
        .side-nav_point-info {
            color:$cl-light-gray;
            border-bottom : 1px solid $cl-border;
            font-size : 1em;
            margin-bottom: 20px;
            font-weight: 600;
            text-align: center;
            p {
                color: $cl-light-gray;
                font-size: 16px;
                margin-bottom: 10px;
            }
        }
        button {
            color: $cl-gray;
            border: none;
            padding: 2px 0;
            font-weight: 500;
            transition: 0.3s;
            &.active,
            &:hover {
                color: $cl-green;
                background-color: transparent;
            }
            &:focus {
                outline: none;
            }
        }
    }
    
    .profile_detail {
        padding-bottom: 40px;
  
        h2 {
            display: flex;
            align-items: center;
            position: relative;
            
            font-size: 2rem;
            color: $cl-green;
            font-weight: 400;
            .new-badge {
               margin-left: 10px;
            }
        }
        h3 {
            display: flex;
            align-items: center;
            font-size: 1.0625rem;
            font-weight: 600;
            color: $cl-gray;
            margin-bottom: 5px;
            .new-badge {
                margin-left: 10px;
             }
        }
        section {
            border-radius: 0;
            margin-bottom: 10px;
            padding: 20px 0;
            -webkit-animation: 1.9s both ease-in-out;
            animation: 1.9s both ease-in-out;
            // background-color: lighten($color:  $cl-light-gray, $amount: 10%);
            border-bottom: 1px solid $cl-light-gray;
            &:last-child {
                border-bottom: none;
             
            }
            &.new-feature {
                -webkit-animation-name: newFeatured;
                animation-name: newFeatured;
                
            }
            @keyframes newFeatured {
                0% {
                    background-color: transparent
                }
                50% {
                    background-color: lighten($color: $cl-green, $amount: 40%);
                }
                100% {
                    background-color: transparent
                    
                }   
            }
            
        }
        .section_item {
            padding:  10px 0 20px;
            button.btn-success {
                margin-top: 0;
                padding: 0.25rem 1.9rem;
                font-weight: normal;
            }
            // border-bottom: 1px solid $cl-light-gray;
            &:last-child {
                border-bottom: none;
            }
        }
        ul{
            list-style-type: none;
            padding: 0;
            li {
                margin-bottom: 5px;
                &.admin-only {
                    color: $cl-green;
                }
                .label {
                    color: $cl-black;
                    font-weight: 500;
                    width: 30%;
                    display: inline-block;
                   
                }
            }
        }
        .how-to-video_wrapper  {
            display: inline-block;
            button {
                margin-left: 3px;
                padding: 0;
            }
        }
   
        .redirect-link {
            display: block;
            color: $cl-green;
            text-decoration: underline;
            padding: 10px;
            text-align: right;
            font-size: 0.875em;
        }
        .quick-submission {
            border: 1px solid $cl-border;
            position: relative;
            max-width: 450px;
            height: 52px;
            .form-group {
                margin-bottom: 0;
            }
            input {
                border: 0;
                padding-right: 30%;
            }
            button  {
                padding: 7px 1.3rem;
                width: 29%;
                position: absolute;
                right: 5px;
                top:5px;
                display: flex;
                align-items: center;
                justify-content: center;
                svg {
                    margin-right: 5px;
                }
            }
        }
    }

    .profile_detail__amazon-information {
        &.new-feature {
            min-height: 230px;
        }
        p {
            padding-right: 30%;
        }
    }

    .profile_detail__account-setting {
        .form-group {
            margin-bottom: 5px;
        }
        .delete-button {
            padding-left: 0;
            color: $cl-red;
            text-decoration: underline;
        }
    }
    
    .point-status {
        border: none;
        padding: 20px 0;
        margin: 0;
        color: $cl-gray;
        font-weight: 500;
        p {
            color: $cl-gray !important;
        }
    }
    
}




/*********************
React Table
*********************/
.ReactTable {

    .rt-thead,
    .rt-td {
        font-size: 0.875em;
    }
    .rt-tbody {
        .rt-td {
            text-align: center;
        }
    }
    .reward_type {
        text-transform: capitalize;
    }

    .-pagination {
        .-pageInfo,
        .-btn {
            font-size: 0.750em;
        }
    }

}