@import '_variables.scss';

/*********************
Generals
*********************/
@font-face {
  font-family: 'dp';
  src:  url('../fonts/dp.eot?cbudxo');
  src:  url('../fonts/dp.eot?cbudxo#iefix') format('embedded-opentype'),
    url('../fonts/dp.ttf?cbudxo') format('truetype'),
    url('../fonts/dp.woff?cbudxo') format('woff'),
    url('../fonts/dp.svg?cbudxo#dp') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'dp' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  letter-spacing: 0;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-location:before {
    content: "\e957";
}

.icon-crown:before {
  content: "\e901";
  color: #666;
}

.icon-arrow:before {
  content: "\e902";
  color: #666;
}

.icon-airplane:before {
  content: "\e900";
  color: #666;
}

.icon-minus:before {
  content: "\e903";
  color: #666;
}
.icon-plus:before {
  content: "\e904";
  color: #666;
}

.icon-avatar:before {
  content: "\e905";
  color: #717171;
}

.icon-play:before {
    content: "\e910";
}

.icon-add-solid:before {
    content: "\e912";
}

.icon-close-solid:before {
    content: "\e913";
}

.icon-play:before {
    content: "\e910";
  }

/* Points Icons =========== */ 
.icon-points {
    padding-right: 5px;
}
.icon-points .path1:before {
  content: "\e906";
  color: rgb(250, 221, 93);
}
.icon-points .path2:before {
  content: "\e907";
  margin-left: -1.0419921875em;
  color: rgb(187, 145, 8);
}
.icon-points .path3:before {
  content: "\e908";
  margin-left: -1.0419921875em;
  color: rgb(255, 255, 255);
}
.icon-points .path4:before {
  content: "\e909";
  margin-left: -1.0419921875em;
  color: rgb(187, 145, 8);
}
.icon-points .path5:before {
  content: "\e90a";
  margin-left: -1.0419921875em;
  color: rgb(187, 145, 8);
}
.icon-points .path6:before {
  content: "\e90b";
  margin-left: -1.0419921875em;
  color: rgb(187, 145, 8);
}
.icon-points .path7:before {
  content: "\e90c";
  margin-left: -1.0419921875em;
  color: rgb(255, 255, 255);
}
.icon-points .path8:before {
  content: "\e90d";
  margin-left: -1.0419921875em;
  color: rgb(187, 145, 8);
}
.icon-points .path9:before {
  content: "\e90e";
  margin-left: -1.0419921875em;
  color: rgb(187, 145, 8);
}
.icon-points .path10:before {
  content: "\e90f";
  margin-left: -1.0419921875em;
  color: rgb(187, 145, 8);
}
/* Points Icons End=========== */ 

/* Flags : United Kingdom  =========== */ 
.icon-united-kingdom .path1:before {
    content: "\e94d";
    color: rgb(65, 71, 155);
  }
  .icon-united-kingdom .path2:before {
    content: "\e94e";
    margin-left: -1em;
    color: rgb(245, 245, 245);
  }
  .icon-united-kingdom .path3:before {
    content: "\e94f";
    margin-left: -1em;
    color: rgb(255, 75, 85);
  }
  .icon-united-kingdom .path4:before {
    content: "\e950";
    margin-left: -1em;
    color: rgb(255, 75, 85);
  }
  .icon-united-kingdom .path5:before {
    content: "\e951";
    margin-left: -1em;
    color: rgb(255, 75, 85);
  }
  .icon-united-kingdom .path6:before {
    content: "\e952";
    margin-left: -1em;
    color: rgb(255, 75, 85);
  }
  .icon-united-kingdom .path7:before {
    content: "\e953";
    margin-left: -1em;
    color: rgb(255, 75, 85);
  }
/* Flags : UK Ends =========== */ 

/* Flags : Geremany  =========== */ 
.icon-germany .path1:before {
    content: "\e914";
    color: rgb(70, 70, 85);
  }
  .icon-germany .path2:before {
    content: "\e915";
    margin-left: -1em;
    color: rgb(255, 225, 90);
  }
  .icon-germany .path3:before {
    content: "\e916";
    margin-left: -1em;
    color: rgb(255, 75, 85);
  }

  /* Flags : Geremany Ends =========== */ 

  /* Flags : US   =========== */ 
  .icon-united-states .path1:before {
    content: "\e917";
    color: rgb(245, 245, 245);
  }
  .icon-united-states .path2:before {
    content: "\e918";
    margin-left: -1em;
    color: rgb(255, 75, 85);
  }
  .icon-united-states .path3:before {
    content: "\e919";
    margin-left: -1em;
    color: rgb(255, 75, 85);
  }
  .icon-united-states .path4:before {
    content: "\e91a";
    margin-left: -1em;
    color: rgb(255, 75, 85);
  }
  .icon-united-states .path5:before {
    content: "\e91b";
    margin-left: -1em;
    color: rgb(255, 75, 85);
  }
  .icon-united-states .path6:before {
    content: "\e91c";
    margin-left: -1em;
    color: rgb(255, 75, 85);
  }
  .icon-united-states .path7:before {
    content: "\e91d";
    margin-left: -1em;
    color: rgb(255, 75, 85);
  }
  .icon-united-states .path8:before {
    content: "\e91e";
    margin-left: -1em;
    color: rgb(255, 75, 85);
  }
  .icon-united-states .path9:before {
    content: "\e91f";
    margin-left: -1em;
    color: rgb(65, 71, 155);
  }
  .icon-united-states .path10:before {
    content: "\e920";
    margin-left: -1em;
    color: rgb(245, 245, 245);
  }
  .icon-united-states .path11:before {
    content: "\e921";
    margin-left: -1em;
    color: rgb(245, 245, 245);
  }
  .icon-united-states .path12:before {
    content: "\e922";
    margin-left: -1em;
    color: rgb(245, 245, 245);
  }
  .icon-united-states .path13:before {
    content: "\e923";
    margin-left: -1em;
    color: rgb(245, 245, 245);
  }
  .icon-united-states .path14:before {
    content: "\e924";
    margin-left: -1em;
    color: rgb(245, 245, 245);
  }
  .icon-united-states .path15:before {
    content: "\e925";
    margin-left: -1em;
    color: rgb(245, 245, 245);
  }
  .icon-united-states .path16:before {
    content: "\e926";
    margin-left: -1em;
    color: rgb(245, 245, 245);
  }
  .icon-united-states .path17:before {
    content: "\e927";
    margin-left: -1em;
    color: rgb(245, 245, 245);
  }
  .icon-united-states .path18:before {
    content: "\e928";
    margin-left: -1em;
    color: rgb(245, 245, 245);
  }
  .icon-united-states .path19:before {
    content: "\e929";
    margin-left: -1em;
    color: rgb(245, 245, 245);
  }
  .icon-united-states .path20:before {
    content: "\e92a";
    margin-left: -1em;
    color: rgb(245, 245, 245);
  }
  .icon-united-states .path21:before {
    content: "\e92b";
    margin-left: -1em;
    color: rgb(245, 245, 245);
  }
  .icon-united-states .path22:before {
    content: "\e92c";
    margin-left: -1em;
    color: rgb(245, 245, 245);
  }
  .icon-united-states .path23:before {
    content: "\e92d";
    margin-left: -1em;
    color: rgb(245, 245, 245);
  }
  .icon-united-states .path24:before {
    content: "\e92e";
    margin-left: -1em;
    color: rgb(245, 245, 245);
  }
  .icon-united-states .path25:before {
    content: "\e92f";
    margin-left: -1em;
    color: rgb(245, 245, 245);
  }
  .icon-united-states .path26:before {
    content: "\e930";
    margin-left: -1em;
    color: rgb(245, 245, 245);
  }
  .icon-united-states .path27:before {
    content: "\e931";
    margin-left: -1em;
    color: rgb(245, 245, 245);
  }
  .icon-united-states .path28:before {
    content: "\e932";
    margin-left: -1em;
    color: rgb(245, 245, 245);
  }
  .icon-united-states .path29:before {
    content: "\e933";
    margin-left: -1em;
    color: rgb(245, 245, 245);
  }
  .icon-united-states .path30:before {
    content: "\e934";
    margin-left: -1em;
    color: rgb(245, 245, 245);
  }
  .icon-united-states .path31:before {
    content: "\e935";
    margin-left: -1em;
    color: rgb(245, 245, 245);
  }
  .icon-united-states .path32:before {
    content: "\e936";
    margin-left: -1em;
    color: rgb(245, 245, 245);
  }
  .icon-united-states .path33:before {
    content: "\e937";
    margin-left: -1em;
    color: rgb(245, 245, 245);
  }
  .icon-united-states .path34:before {
    content: "\e938";
    margin-left: -1em;
    color: rgb(245, 245, 245);
  }
  .icon-united-states .path35:before {
    content: "\e939";
    margin-left: -1em;
    color: rgb(245, 245, 245);
  }
  .icon-united-states .path36:before {
    content: "\e93a";
    margin-left: -1em;
    color: rgb(245, 245, 245);
  }
  .icon-united-states .path37:before {
    content: "\e93b";
    margin-left: -1em;
    color: rgb(245, 245, 245);
  }
  .icon-united-states .path38:before {
    content: "\e93c";
    margin-left: -1em;
    color: rgb(245, 245, 245);
  }
  .icon-united-states .path39:before {
    content: "\e93d";
    margin-left: -1em;
    color: rgb(245, 245, 245);
  }
  .icon-united-states .path40:before {
    content: "\e93e";
    margin-left: -1em;
    color: rgb(245, 245, 245);
  }
  .icon-united-states .path41:before {
    content: "\e93f";
    margin-left: -1em;
    color: rgb(245, 245, 245);
  }
  .icon-united-states .path42:before {
    content: "\e940";
    margin-left: -1em;
    color: rgb(245, 245, 245);
  }
  .icon-united-states .path43:before {
    content: "\e941";
    margin-left: -1em;
    color: rgb(245, 245, 245);
  }
  .icon-united-states .path44:before {
    content: "\e942";
    margin-left: -1em;
    color: rgb(245, 245, 245);
  }
  .icon-united-states .path45:before {
    content: "\e943";
    margin-left: -1em;
    color: rgb(245, 245, 245);
  }
  .icon-united-states .path46:before {
    content: "\e944";
    margin-left: -1em;
    color: rgb(245, 245, 245);
  }
  .icon-united-states .path47:before {
    content: "\e945";
    margin-left: -1em;
    color: rgb(245, 245, 245);
  }
  .icon-united-states .path48:before {
    content: "\e946";
    margin-left: -1em;
    color: rgb(245, 245, 245);
  }
  .icon-united-states .path49:before {
    content: "\e947";
    margin-left: -1em;
    color: rgb(245, 245, 245);
  }
  .icon-united-states .path50:before {
    content: "\e948";
    margin-left: -1em;
    color: rgb(245, 245, 245);
  }
  /* Flags : US Ends  =========== */ 

  /* Flags : Canada  =========== */ 

  .icon-canada .path1:before {
    content: "\e949";
    color: rgb(245, 245, 245);
  }
  .icon-canada .path2:before {
    content: "\e94a";
    margin-left: -1em;
    color: rgb(255, 75, 85);
  }
  .icon-canada .path3:before {
    content: "\e94b";
    margin-left: -1em;
    color: rgb(255, 75, 85);
  }
  .icon-canada .path4:before {
    content: "\e94c";
    margin-left: -1em;
    color: rgb(255, 75, 85);
  }
  /* Flags : Canada Ends  =========== */ 

  .icon-facebook:before {
    content: "\e954";
  }

  .icon-amz-logo:before {
    content: "\e955";
  }

  .icon-paypal:before {
    content: "\e958";
  }
  
  .icon-birthday-cake:before {
    content: "\e956";
  }

  .icon-envelope:before {
    content: "\e959";
  }