@import '_variables.scss';

/*********************
Accordion
*********************/
.accordion {
        width: 100%;
        .card {
            background-color: transparent;
            border: 0;
            border-radius: 0;
            padding: 10px 0 0;
            cursor: pointer;           
            &:first-child {
                padding-top: 0;
            }
        }
        .card-header {
            color: $cl-green;
            background-color: transparent;
            border: 0; 
            letter-spacing: 0.08px;
            font-size: 1rem;
            padding:0;
            i {
                font-size: 1.5625rem;
                margin-right: 10px;
            }
            // Remove this button when we convert FAQ page to react
            button {
                color: $cl-green;
                cursor: pointer;
                background-color: lighten($cl-gray, 55%);
                text-align: left;
                padding: 20px;
                width: 100%;
                text-decoration: none;
                white-space: normal;
                &.collapsed {
                    color: $cl-gray;
                    background-color: transparent;
                }
            }
        }
        .card-body {
            font-size: 0.875rem;
            padding: 5px 0 15px ;
        }
        button {
            color: $cl-green;
            background-color: transparent;
            border: 0; 
            letter-spacing: 0.08px;
            font-size: 1.25rem;
            padding: 20px 0;
            text-align: left;
            &:focus {
                outline: none;
            }
        }
    }