@import '../../assets/styles/partials/_variables.scss';

.page_admin {
    .admin-users {
        transition: all .3s ease-in-out;
        &.--loading {
            opacity: .5;
            pointer-events: none;
        }
        .table {
            .table-data_name {
                width: 45%;
            }
        }
    }
}