@import '_variables.scss';

/*********************
Typography
*********************/
.important-text {
    color: $cl-red;
}

p {
    color: $cl-gray;
}

h1 {
    @include media-breakpoint-down(md) { 
        font-size: 25px;
        line-height: 30px;
        margin-bottom: 15px;
    }
}

p {
    &.lead {
        font-size: 1.125rem;
        line-height: 1.6875rem;
    }
}

h2 {
    // font-size: 16px;
    font-weight: 500;
    // color: $cl-light-green;
    letter-spacing: 0.07px;   
    @include media-breakpoint-down(md) { 
        font-size: 1.250em;
    }
}

h6 {
    font-weight: bold;
    margin-bottom: 2px;
}