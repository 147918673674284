@import '_variables.scss';

/*********************
Button

#General
#Group Toggle
#Toggle Switch
*********************/


/*********************
General
*********************/
.btn-dp,
.btn-success {
    background: $cl-green;
    color: $cl-white;
    padding: 0.9rem 1.3rem;
    white-space: inherit;
    font-weight: 500;
    letter-spacing: 1.3px;
    border:none;
    &:hover,
    &:active,
    &:hover:active,
    &:not(:disabled):not(.disabled):active {
        background-color: darken($cl-green, 10%);
        color: $cl-white;
    }
    &.disabled,
    &:disabled {
        background-color: $cl-gray;
        pointer-events: none;
    }
    // &.btn-sm {
    //     padding:0.25rem 0.5rem;
    // }
    &.btn-shadow {
        box-shadow: rgba(45, 45, 45, 0.05) 0px 2px 2px, 
            rgba(49, 49, 49, 0.05) 0px 4px 4px, 
            rgba(42, 42, 42, 0.05) 0px 8px 8px, 
            rgba(32, 32, 32, 0.05) 0px 16px 16px, 
            rgba(49, 49, 49, 0.05) 0px 32px 25px;
    }
    i:before {
        color: $cl-white;
    }
    &:focus {
        box-shadow: none;
    }
    &.btn-outline,
    &.btn-outline-success {
        background: transparent;
        border: 1px solid $cl-gray;
        color: $cl-gray;
    }
}
.btn-rounded {
    display: flex;
    min-height: 33px;
    border-radius: 30px;
    padding: 7px;
    max-width: 232px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}
.btn-outline-success {
   border-color: $cl-green; 
   color: $cl-green;
   &:hover {
        background-color: $cl-green;
        border-color: $cl-green; 
    }
}

.btn-link {
    color: $cl-green;
    &:hover {
        color: darken($color: $cl-green, $amount: 20%)
    }
}



.btn-dp--icon {
    display: flex;
    align-items: center;
    i {
        margin-left: 5px;
        font-size: 1.4em;
    }
}

.btn-dp--orange {
    background: $cl-orange;
    &:hover {
        color: $cl-white;
        background-color: darken($cl-orange, 10%);
    }
}

.btn-dp--purple {
    background:#6c5ce7;
    color: darken($color: #6c5ce7, $amount: 50%);
    &:hover {
        color: $cl-white;
        background-color: darken(#6c5ce7, 10%);
    }
}

.btn-dp--yellow {
    background: $cl-yellow;
    &:hover {
        color: $cl-white;
        background-color: darken($cl-orange, 10%);
    }
}

.btn-dp--light-green {
    background: $cl-light-green;
    &:hover {
        color: $cl-white;
        background-color: darken($cl-light-green, 10%);
    }
    i:before {
        color: $cl-white;
    }
}

/*********************
Facebook btn
*********************/
button.btn-fb {
    background: #1877f2;
    border: 0;
    font-weight: bold;
    padding: 15px 18px;
    display: flex;
    align-items: center;
    margin-right: 15px;
    box-shadow: none;
    color: $cl-white !important;
    &.btn-fb-error {
        background-color: #236dce;
    }
    &.btn-primary:not(:disabled):not(.disabled):active {
        background-color: darken($color: #1877f2, $amount: 10%);
    }

    i {
        font-size: 25px;
        margin-right: 10px;
    }
}


/*********************
Group Toggle
*********************/
.btn-group-toggle {
    .btn:not(:last-child):not(.dropdown-toggle),
    .btn:not(:first-child)  {
        border-radius: .25rem;
        margin: 0 10px 0 0;
    }

    .btn-outline-primary,
    .btn-outline-primary:not(:disabled):not(.disabled).active {
        color: $cl-green;
        border:1px solid $cl-green;
        border-radius: .25rem;
        padding: 4px 4px;
        min-width: 150px;
    }

    .btn-outline-primary:not(:disabled):not(.disabled).active {
        background: $cl-green;
        color: $cl-white;
    }

    .btn-outline-primary:hover {
        background: $cl-green;
        color: $cl-white;
        .btn-outline-primary:not(:disabled):not(.disabled):active {
            background: $cl-green;
            color: $cl-white; 
        }
    }

    .btn-outline-primary:not(:disabled):not(.disabled).active:focus {
        box-shadow: 0 0 0 0.2rem rgba(114, 255, 145, 0.5);
    }
}

.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled):active {
    border:none;
    background-color: $cl-green;
}

.btn-primary:not(:disabled):not(.disabled):active:focus {
    box-shadow: none;
}
