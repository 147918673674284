/*********************
Modal : Global Message

#General
*********************/

@import '../../assets/styles/partials/_variables.scss';


/*********************
#General
*********************/
.modal_global-message {
    overflow: auto;
    .modal-body {
        padding-bottom: 5px;
        @include media-breakpoint-up(lg) { 
            padding-bottom: 10px;
        }
    }
    .modal-content {
        background-image: url(../../assets/images/sun-ray.png);
        background-repeat: no-repeat;
        background-position: top right;
        background-size: 70%;
        @include media-breakpoint-down(md) { 
            height: 90vh;
            overflow: auto;
            padding: 5px;
        }
        @include media-breakpoint-up(lg) { 
            padding-top: 30px;
        }
        
    }
    .modal-header {
        padding-bottom: 0;
        .modal-title {
            font-size: 1.0625rem;
            line-height: 1.75rem;
            font-weight: 600;
            .modal-title_name {
                display: block;
                font-size: 2.1875rem;
            }
        }
    }
    .modal-footer {
        border: 0;
        justify-content: center;
        padding-top: 0;
        margin:24px 0;
        @include media-breakpoint-down(md) { 
            margin:24px 0;
        }
        button {
            @include media-breakpoint-up(lg) { 
                width: 50%;
            }
            padding: 15px 30px;
            text-align: center;
            text-decoration: none;
            display: inline-block;
            margin: 4px 2px;
            cursor: pointer;
            border-radius: 25px;
            box-shadow: rgba(45, 45, 45, 0.05) 0px 2px 2px, 
                        rgba(49, 49, 49, 0.05) 0px 4px 4px, 
                        rgba(42, 42, 42, 0.05) 0px 8px 8px, 
                        rgba(32, 32, 32, 0.05) 0px 16px 16px, 
                        rgba(49, 49, 49, 0.05) 0px 32px 32px, 
                        rgba(35, 35, 35, 0.05) 0px 64px 64px
           
        }
    }
    h6 {
        color: $cl-green;
    }
    ol {
        font-size: 15px;
        font-weight: 300;
        padding-left: 18px;
        li {
            margin-bottom: 5px;
        }
    }
    p.footnote {
        font-size: 0.625rem;
        line-height: 0.8125rem;
        font-weight: 500;
        font-style: italic;
        padding: 0 5%;
        margin: 15px 0 0;
    }
    strong{
        font-weight: 700;
    }
}