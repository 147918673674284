@import '../../../assets/styles/partials/variables.scss';

/*********************
Admin

#General
#Button
#Table Search
#Table
#Table : Manage Invite
#Table : Bonus Invite
#Form
#Email
#Tooltip
#Page : Export
#Dark Theme
    - General 
    - Button
    - Table
    - Form
*********************/

/*********************
General
*********************/
.page_error {
    /* OLDCOLORS/Gradient */
    background: linear-gradient(87.65deg, #639F42 22.81%, #61B134 77.8%);
    margin-top: 20px;
    padding: 20px;
    @include media-breakpoint-up(md) { 
        margin-top: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .logo {
        text-align: center;
        margin-bottom: 24px;
        @include media-breakpoint-up(md) { 
            margin-bottom: 40px;
        }
        img {
            max-width: 120px;
        }
    }
    
    .dialog {
        width: 100%;
        max-width: 688px;
        text-align: center;
        background: #FFFFFF;
        border-radius: 20px;
        padding: 30px 40px;
        box-shadow: 4px 16px 27px rgba(99, 159, 66, 100%);
        .dialog-header {
            img {
                max-width: 80px;
                margin-bottom: 24px;
            }
            h1 {
                font-family: 'Montserrat', sans-serif;
                font-weight: bold;
                font-size: 24px;
                line-height: 30px;
                text-align: center;
                letter-spacing: 2px;
                color: $cl-gray;
                @include media-breakpoint-up(md) { 
                    font-size: 42px;
                    line-height: 45px;
                    margin-bottom: 16px;
                }
            }
        }

        .dialog-body {
            margin-bottom: 40px;
            p {
                font-size: 14px;
                line-height: 20px;
                color: #8B8B8B;
                font-weight: 500;
                @include media-breakpoint-up(md) { 
                    font-size: 18px;
                    line-height: 30px;
                }
            }
           
        }
        .dialog-footer {
            margin-bottom: 40px;
            button{
                font-weight: 600;
                min-width: 150px;
                &.btn-success {
                    
                    background: linear-gradient(82deg, $cl-green 32.81%, $cl-light-green 127.8%);
                }
            }
        }

    }
    
    .modal {
        h2 {
            color: $cl-black;
        }
        ol {
            margin: 0 0 24px;
            padding: 0 20px;
        }
        img {
            width: 100%;
        }
    }
}

.page_error.--cookie {
    .modal {
        overflow: auto;
        ol {
            margin-bottom: 0;
        }
        img {
            margin-bottom: 24px;
        }
    }
    .btn-link {
        font-weight: bold;
        text-decoration: underline;
    }
}