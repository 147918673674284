@import '../../assets/styles/partials/_variables.scss';

/*********************
Page: Deal

#Page
#Deck
#Preloader
*********************/


/*********************
#Page
*********************/
body.page_deal {   
    .card-body_tags-tooltip {
        top: -80px !important;
    } 
    background-image: url('../../assets/images/deal-background_m.png');
    background-position: top center;
    background-size: contain;
    background-repeat: no-repeat;
    position: relative;
    
    @include media-breakpoint-up(md) { 
        background-image: url('../../assets/images/deal-background.png');
     }  
    main {
        margin-bottom: 0;
    }
    .spinner {
        margin: auto;
    }
    .page-header {
        margin-bottom: 0;
        padding: 90px 10px;
        @include media-breakpoint-up(md) { 
            padding: 90px;
        }  
    }
    .point-status {
        box-shadow: rgba(45, 45, 45, 0.2) 0px 7px 10px;
        background-color: white;
        margin-bottom: 0;
    }
    .sub-title {
        display: block;
        width: 100%;
        font-weight: 600;
        padding-left: 20px;
        color: darken($color: $cl-green, $amount: 25%);
        @include media-breakpoint-up(md) { 
           padding-left: 0;
        }  
    }
    .lead {
        padding: 0 20px;
        @include media-breakpoint-up(md) { 
            padding-left: 0;
         }
    }
    .page-filter {
        margin: auto;
        text-align: center;
        margin-bottom: 40px;
        p {
            font-weight: 600;
            margin-bottom: 8px;
        }

        .filter_item {
           
            button {
                &:first-child {
                    margin-right: 10px;
                }
                border-radius: 24px;
                padding: 8px 24px;
            }
        }
    }
}

/*********************
Deck
*********************/
.deal-deck.card-deck {
    width: 100%;
    .new-deal {
        .stock-level {
            display: block;
            font-size: 0.625rem;
            margin-top: -3px;
            opacity: .6;
        }
    }
    .progress_wrapper {
        margin-top: 10px;
        .progress{
            height: 10px;
            margin-bottom: 5px;
        }
        .progress_text {
            display: flex;
            justify-content: space-between;
            font-size: 0.625rem;
            line-height: 0.625rem;
        }
    }
    .card {
        .cta {
            font-weight: 500;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            border-bottom-left-radius: 1rem;
            border-bottom-right-radius: 1rem;
            padding: 0.625rem 1.3rem;
            min-height: 70px;
        }
        .cta.btn-link {
            color: $cl-gray;
            background-color: transparent;
            border-top: 1px solid $cl-border;
            &:hover {
                text-decoration: none;
            }
            &.--pending {
                color: $cl-dark-yellow
            }
            &.--processing {
                color: #6293A8;
            }
        }
    }
}

/*********************
Preloader
*********************/
.deal-deck.preloader {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 40vh;
}

.card-body {
    .btn-dp {
        width: 100%;
        margin-bottom: 5px;
    }
}
