@import '_variables.scss';


/*********************
Global

#Tag
#Star Ratings
#App
#Point status
#progress Bar
#How to video
#Flag
#Toggle Switch
*********************/


* {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.icon-airplane:before {
  content: "\e900";
}

.empty-state {
    margin: 30px auto;
    font-size: 20px;
    font-weight: 500;
    font-style: italic;
    text-align: center;
    line-height: 30px;
    color: darken($color: $cl-green, $amount: 25%);
}

/*********************
#Tag
*********************/

html {
    position: relative;
    min-height: 100%;
}

body {
    font-weight: 500;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;


    &:not(.page_admin) {
        > #root {
             // DISABLED TEXT HIGHTLIGHT
            -webkit-touch-callout: none; /* iOS Safari */
            -webkit-user-select: none; /* Safari */
                -khtml-user-select: none; /* Konqueror HTML */
                -moz-user-select: none; /* Old versions of Firefox */
                -ms-user-select: none; /* Internet Explorer/Edge */
                    user-select: none; /* Non-prefixed version, currently
                                            supported by Chrome, Edge, Opera and Firefox */
        }
    }

    
    > #root {
        &.--role-admin {
            -webkit-touch-callout: auto; /* iOS Safari */
            -webkit-user-select: auto; /* Safari */
                -khtml-user-select: auto; /* Konqueror HTML */
                -moz-user-select: auto; /* Old versions of Firefox */
                -ms-user-select: auto; /* Internet Explorer/Edge */
                    user-select: auto; /* Non-prefixed version, currently
                                            supported by Chrome, Edge, Opera and Firefox */
        }
        font-size: 14px;
        line-height: 24px;
        color: $cl-gray;
        font-family: 'Poppins', sans-serif;
        letter-spacing: 0.25px;
        @include media-breakpoint-up(md) { 
            font-size: 16px;
        }
    }
    > section {
        margin-top: 59px;
        @include media-breakpoint-up(md) { 
            margin-top: 65px;
        }
    }

}

main {
        margin-top: 59px;
        margin-bottom: 100px;
        @include media-breakpoint-up(md) { 
            margin-top: 65px;
            margin-bottom: 160px;
        }
        &.full-background {
            margin: 0;
        }
    }
    .page-narrow {
        max-width: 960px;
    }
    > section {
        padding: 40px 0;
        min-height: 100%;
        @include media-breakpoint-up(md) { 
            margin-top: 65px;
        }
        /*&.full-height {
            @include media-breakpoint-up(md) { 
                height: 77vh;
            }
            @media (width: 1024px) and (height: 1366px) { 
                height: auto;
            }
            @media (width: 768px) and (height: 1024px) { 
                height: auto;
            }
            @media (max-height: 800px) {
                height: auto;
            }
        }*/
}

strong {
    font-weight: 800;
}

a {
    color: $cl-green;
    &:hover {
        color: darken($cl-green, 20%);
    }
    &.link--override {
        color: $cl-gray;
    }
}

p {
    line-height: 1.5rem;
    font-weight: 300;
}

.content {
    @include media-breakpoint-down(md) { 
        padding: 0 30px 20px;
    }
    h2 {
        color: $cl-gray;
        font-size: 1.875rem;
    }
    .lead {
        padding: 0;
    }
}


/*********************
#Star Ratings
*********************/
.star-rating-container {
    display: flex;
    align-items: center;
    font-size: 2.625rem;
    color: $cl-yellow;
    label {
        margin-right: 5px;
    }
    .star-rating-msg {
        display: inline-block;
        font-size: 16px;
        margin-left: 15px;
    }
}



/*********************
#App
*********************/
#app {
    margin-top: 65px; 
}

.main-content {
   padding-top: 20px;
    @include media-breakpoint-up(md) { 
        padding-top: 40px;
        padding-bottom: 20px;
    }
    > section {
        padding: 30px;
        @include media-breakpoint-up(md) { 
            padding: 40px 0 100px;
        }
    }
}

.new-badge {
    background-color: $cl-orange;
    border-radius: 3px;
    padding: 2px 10px;
    font-size: 8px;
    font-weight: bold;
    letter-spacing: 1.1px;
    color: $cl-white;
}

.sticky-bottom {
    position: -webkit-sticky; /* Safari */
    position: sticky;
    bottom: 20px;
}

.full-background {
    .full-background--valign {
        background-size: cover;
        width: 100vw;
        padding: 50px 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        @include media-breakpoint-up(sm) { 
            height: 100vh;
        }
    }
    .logo {
        width: 120px;
    }
}

.lead {
    line-height: 1.3rem;
    &:last-child {
        margin-bottom: 0;
    }
    button {
        padding: 0;
    }
}

.tnc-confirmation {
    label {
        // color: lighten($color: $cl-yellow, $amount: 5%);
        // color: $cl-red;
        font-size: 15px;
        font-weight: bold;
    }
    a {
        // color: $cl-red;
        font-size: 12px;
        margin-left: 21px;
        text-decoration: underline;
        font-weight: bold;
    }
}


.deal-status,
.deal-platform {
    color: $cl-white; 
    background-color: lighten($cl-green, 25%);
    font-size: 0.750em;
    padding: 3px 10px; 
    &.deal-status--claimed {
        background-color: lighten($cl-gray, 40%)
    }
    &.deal-status--out-of-stock {
        background-color: lighten($cl-gray, 30%);
    }
    &.deal-status--coming-soon {
        background-color: $cl-yellow;
    }
}

.deal-platform {
    background-color: black;
    margin-right: 5px;
    padding: 2px 10px;
    font-size: 11px;
    @include media-breakpoint-down(md) { 
        font-size: 0.625em;
    }
}

.deal-badge{
    display: flex;
    align-items: center;
    justify-content: center;
    position:absolute;
    top:18px;
	right:-3px;
    color:$cl-white;
	background-color:$cl-gray;
    font-size: 0.625em;
    line-height: 13px;
	width:90px;
    height:30px;
    text-align: center;
    -webkit-box-shadow: 0px 11px 33px -12px rgba(0,0,0,0.34);
    -moz-box-shadow: 0px 11px 33px -12px rgba(0,0,0,0.34);
    box-shadow: 0px 11px 33px -12px rgba(0,0,0,0.34);
    strong {
        margin-right: 2px;
    }
	&:before{
		content: '';
		position: absolute;
		z-index: 2;
		left: -19px;
		bottom: 10px;
		border-left: 15px solid $cl-gray;
		border-right: 15px solid $cl-gray;
        border-bottom: 10px solid transparent;
        transform: rotate(90deg);
	}
	&:after{
		content:'';
		// width:200px;
		height:270px;
		position: absolute;
		z-index: -1;
		left: 0;
		bottom:-120px;
		background-color:$cl-gray;
		transform:skewY(35deg) skewX(0);
    }
    @include media-breakpoint-up(sm) { 
        flex-direction: column;
        width:70px;
        height:50px;
        top:-3px;
	    right:10px;
        strong {
            margin-right: 0;
        }
        &:before{ 
            left: 0;
            bottom: -14px;
            border-left: 35px solid $cl-gray;
            border-right: 35px solid $cl-gray;
            border-bottom: 14px solid transparent; 
            transform: rotate(0deg);
        }
    }
    &.badge--black {
        background-color:$cl-black;
        &:before{ 
            border-color: $cl-black; 
            border-bottom-color: transparent;
        }
        &:after{ 
            background-color: $cl-black; 
        }
    }
    &.badge--red {
        background-color: lighten($cl-red, 15%);
        &:before{ 
            border-color: lighten($cl-red, 15%); 
            border-bottom-color: transparent;
        }
        &:after{ 
            background-color: lighten($cl-red, 15%); 
        }
    }
    &.badge--orange {
        background-color: lighten($cl-orange, 15%);
        &:before{ 
            border-color: lighten($cl-orange, 15%); 
            border-bottom-color: transparent;
        }
        &:after{ 
            background-color: lighten($cl-orange, 15%); 
        }
    }
    
    &.badge--green {
        background-color: lighten($cl-green, 15%);
        &:before{ 
            border-color: lighten($cl-green, 15%); 
            border-bottom-color: transparent;
        }
        &:after{ 
            background-color: lighten($cl-green, 15%); 
        }
    }
}

.show > .btn-secondary.dropdown-toggle {
    background-color: transparent ;
    box-shadow: none ; 
    border: none ;
}

.page-header {
    margin: 0 0 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    // margin: 0px 0 40px; 
    padding: 35px 20px 30px;
    text-align: center;
    h1 {
        position: relative;
        display: inline-block;
        color: darken($color: $cl-green, $amount: 25%);
        font-size: 3.5rem;
        font-weight: 800;
        padding: 0 40px;
        margin-bottom: 0;
        // text-transform: uppercase;
        &::before { 
            content: '';
            border-bottom: 2px solid $cl-green;
            width: 50%;
            position: absolute;
            left: 25%;
            bottom: -5px;
            z-index: -1;
            @include media-breakpoint-up(md) { 
                visibility: hidden;
            }
        }
        @include media-breakpoint-down(sm) { 
            font-size: 2em;
            margin-bottom: 15px;
            padding: 0;           
        }

       
    }

    h2 {
        color: $cl-green;
        display: inline-block;
        font-size: 2.2em;
        margin-bottom: 20px;
        position: relative;
        &::before {
            content: '';
            border-bottom: 6px solid lighten($cl-light-green, 5%);
            width: 85%;
            position: absolute;
            margin: 0 14%;
            left: -7%;
            top: 0;
            bottom: -10px;
            @include media-breakpoint-down(sm) { 
                   visibility: hidden;
            }
        }
    }
    &.no-lead {
        margin-bottom: 40px;
        padding-bottom: 0;
        &::before {
            top: 50%;
        }
        h2 {
            margin-bottom: 20px;
        }
    }
    .lead {
        padding: 0 15%;
        @include media-breakpoint-down(sm) { 
            padding: 0;
        }
        @include media-breakpoint-up(md) { 
            font-size: 24px;
        }
    }
}



/*********************
Progress Bar
*********************/
.progress-bar {
    background-color: $cl-green;   
}

/*********************
How to video
*********************/
label {
    .step_help {
        display: inline-block;
        margin: 0;
        .how-to-video_wrapper  {
            display: inline-block;
        }
    }
}
.step_help {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75rem;
    font-style: italic;
    color: $cl-gray;
    margin-top: 25px;
    background-color: #F8F8F8;
    padding: 15px 0 ;
    .how-to-video_wrapper {
        display: inline;
        margin-left: 5px;
        button {
            font-style: italic;
            font-size: 0.75rem;
            padding: 0;
        }
        i {
            margin-right: 2px;
        }
    }
}

/*********************
Flag
*********************/
.flag-container  {
    display: inline-block;
    i {
        font-size: 35px;
    }
    .unknown {
        background: $cl-light-gray;
        color: $cl-gray;
        display: inline-block;
        width: 40px;
        text-align: center;
        border-radius: 3px;
    }   
}

/*********************
Ribbon
*********************/
.corner-ribbon {
    width: 85px;
    height: 88px;
    overflow: hidden;
    position: absolute;
    top: 0;
    right: 0;
    .ribbon-green {
        font: bold 15px Sans-Serif;
        color: $cl-white;
        text-align: center;
        -webkit-transform: rotate(45deg);
        -moz-transform:    rotate(45deg);
        -ms-transform:     rotate(45deg);
        -o-transform:      rotate(45deg);
        position: relative;
        padding: 7px 0;
        left: -5px;
        top: 15px;
        width: 120px;
        background-color: $cl-green;
        

        // &:before {
        //     left: 0;
        // }

        // &:after {
        //     right: 0;
        // }​
     
    }
}

/*********************
Ribbon
*********************/
.corner-ribbon {
    width: 85px;
    height: 88px;
    overflow: hidden;
    position: absolute;
    top: 0;
    right: 0;
    .ribbon-green {
        font: bold 15px Sans-Serif;
        color: $cl-white;
        text-align: center;
        -webkit-transform: rotate(45deg);
        -moz-transform:    rotate(45deg);
        -ms-transform:     rotate(45deg);
        -o-transform:      rotate(45deg);
        position: relative;
        padding: 7px 0;
        left: -5px;
        top: 15px;
        width: 120px;
        background-color: $cl-green;
        

        // &:before {
        //     left: 0;
        // }

        // &:after {
        //     right: 0;
        // }​
     
    }
}

/*********************
Toggle Switch
*********************/
.toggle-switch {
    display: flex;

    &.--admin-privilege {
        background-color: $cl-black;
        border-radius: 20px;
        color: white;
        padding: 10px 20px;
        position: fixed;
        z-index: 999999;
        bottom: 90px;
        right: 20px;
        font-size: 0.75rem;

        .toggle_switch_label {
            margin-right: 6px;
        }

    }
}
  
/*********************
UPload FIles
*********************/
.upload-files {
    position: relative;
    background-color: white;
    min-height: 200px;
    border: 3px dotted $cl-light-gray;
    margin: 20px 0;
    transition: all 0.3s ease-in-out;
    padding: 15px ​15px;
    font-size: 12px;

    cursor: pointer;
    ul {
        list-style-type: none;
        margin: 0;
        padding: 5px 15px ;
        font-size: 12px!important;
        li {
            padding: 5px 0;
            display: flex;
            align-items: center;
            .upload-image {
                width: 30px;
                height: 30px;
                float: left;
                border-radius: 2px;
                margin-right: 5px;
                background-size: cover;
                background-position: center;
            }
        }
        li:not(:last-child) {
            margin-bottom: 5px;
            border-bottom: 1px solid $cl-light-gray;
        }
    }
    &.--disable {
        pointer-events: none;
        opacity: 0.5;
    }
    &.--empty {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .upload-files_list-detail {
        position: absolute;
        bottom: 0;
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: 5px 20px;
        color: $cl-gray;
        font-weight: 600;
        border-top: 1px solid $cl-light-gray;
        .upload-files_edit-link {
            color: $cl-green;
        }
    }
    .upload-files_controller {
        display: flex;
        align-items: center;
        flex-direction: column;
        svg {
            color: $cl-light-blue;
            transition: all 0.3s ease-in-out;
        }
        .btn {
            // background-color: $cl-light-blue;
            pointer-events: none;
            color: #627D88;
            // border-color: $cl-light-blue;
            font-weight: 700;
            padding-bottom: 0;
        }
        p {
            font-size: 12px;
            font-weight: 500;
            color: lighten($color: $cl-gray, $amount: 20%) ;
        }
    }
    &:hover {
        background-color: $cl-light-blue;
        border-color: #627D88;
        .upload-files_controller {
            svg {
                color: #627D88;
            }
        }
    }
}

  

 