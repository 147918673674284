@import 'partials/_variables.scss';

$tile_margin: 7px;

.card-deck {
    width: 100%;
    padding: 0 20px;
    margin-bottom: 100px;
    flex-direction: column;
    flex: auto;
    @include media-breakpoint-up(sm) { 
        flex-direction: row;
    }
    @include media-breakpoint-up(md) { 
        // padding: 0;.9
    }
    .spinner {
        margin: auto;
    }
    .card {
        flex: 0 1 45.87%;
        -webkit-flex: 0 1 45.87%; /* Safari 6.1+ */
        -ms-flex: 0 1 45.87%; /* IE 10 */ 
        flex-direction: row;
        background: #FFFFFF;
        border: none;
        -webkit-box-shadow:inset 0px 0px 0px 1px $cl-border;
        -moz-box-shadow:inset 0px 0px 0px 1px $cl-border;
        box-shadow:inset 0px 0px 0px 1px $cl-border;
        margin-right: $tile_margin;
        margin-left: $tile_margin;
        border-radius: 1rem;
        &.disabled,
        &.cool-down {
            pointer-events: none;
            opacity: .5;
        }
        &.--inactive {
            pointer-events: none;
        }
        &.cool-down {
            .btn {
                font-size: 0.75rem;
                line-height: 1rem;
                padding: 10px;
            }
        }
        &.disabled button {
            background-color: $cl-light-gray;
            border: none;
        }
        // &:first-child {
        //     margin-left: 0;
        // }
        
        .inactive-message {
            opacity: 0;
            position: absolute;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: opacity 0.2s ease-in-out;
            background-color: rgba($color: $cl-dark-purple, $alpha: 0.5);
            font-size: 20px;
            z-index: 2;
            text-align: center;
            color: white;
        }

        .content-wrapper {
            width: 100%;
            .card-body_mobile-description {
                width: 100%;
            }
        }

        &.--inactive {
            // background-color: rgba($color: white, $alpha: 0.05);
            .content-wrapper {
                opacity: 0.4;
            }
            .inactive-message {
                opacity: 1.0; 
            }
        }

        @include media-breakpoint-down(md) {
            flex: 0 1 49.3%;
            -webkit-flex:0 1 49.3%;
            -ms-flex:0 1 49.3%;
            // &:nth-child(odd){
            //     margin-left: 0;
            // }
            // &:nth-child(even){
            //     margin-left: 0;
            //     margin-right: 0;
            // }
        }
        @include media-breakpoint-up(lg) {
            min-width:210px;
            flex-direction: column; 
            flex: 0 1 23.5%;
            -webkit-flex:0 1 23.5%;
            -ms-flex:0 1 23.5%;
        }
        @include media-breakpoint-up(xl) {
            min-width:250px;
            flex: 0 1 23.7%;
            -webkit-flex:0 1 23.7%;
            -ms-flex:0 1 23.7%;
        }
        margin-bottom: $tile_margin + 7;
        transition: box-shadow .3s ease-out, transform .3s ease-out, opacity .2s ease-out;
        transition-delay: .04s;
        transform: translateZ(0);
        &.card-deck--3-col {
            flex: 0 1 100%;
            -webkit-flex: 0 1 100%; /* Safari 6.1+ */
            -ms-flex: 0 1 100%; /* IE 10 */ 
            flex-direction: column;
            background: #FFFFFF;
            box-shadow: 0 4px 5px 0 rgba(0,0,0,0.11);
            border: 1px solid lighten($cl-gray, 50%);
            @include media-breakpoint-down(sm) {
                margin: 10px 0;
            }
            @include media-breakpoint-up(md) {
                flex: 0 1 48.5%;
                -webkit-flex: 0 1 48.5%; /* Safari 6.1+ */
                -ms-flex: 0 1 48.5%; /* IE 10 */ 
                // &:nth-child(1n){
                //     margin-left: 0;
                // }
                // &:nth-child(even){
                //     margin-right: 0;
                // }
            }
            @include media-breakpoint-up(lg) { 
                flex-direction: column; 
                flex: 0 0 1 32.5%;
                -webkit-flex:0 0 1 32.5%;
                -ms-flex:0 0 1 32.5%;
                // &:nth-child(1n){
                //     margin-left: 0;
                // }
                // &:nth-child(even){
                //     margin-right: 7px;
                // }
            }
            @include media-breakpoint-up(xl) {
                flex: 0 1 32%;
                -webkit-flex:0 1 32%;
                -ms-flex:0 1 32%;
                // margin-right: 14px;
                // margin-left: 14px;
            
            }
        }
        cursor: pointer;
        transition: all 0.5s ease;
        &:hover {
            box-shadow: $box-shadow-hover;
            transform: translate(0, -4px);
            border-color: none;
            button {   
                background-color: darken($cl-green, 10%);
            }
        }
        .card-image {
            display: none;
            justify-content: center;
            height: 200px;
            width: 70%;
            margin: auto;
            text-align: center;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            margin: 16px auto;
            @include media-breakpoint-up(md) { 
                display: flex;
            }
            .card-img-top {
                width: 100%;
                max-width: 170px;
                min-width: 100px;
                margin: auto;
                padding: 20px 0 0 ;
                @include media-breakpoint-up(md) { 
                    padding: 10px;
                }
            }
           
        }
        .card-body {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            border-radius: 1rem;
            @include media-breakpoint-up(md) { 
                padding-left: 1.25rem
            }
            
            .card-body_top-items_mobile-wrapper{
                display: flex;
                margin-bottom: 20px;
                flex-direction: column;
                .card-body_mobile-image {
                    margin-right: 10px;
                    max-width: 130px;
                    display: flex;
                    align-items: center;
                    padding: 15px;
                    @include media-breakpoint-up(md) { 
                        display: none;
                    }
                }
            }
            
            .card-body_tags {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 10px;
                .tags_left-side {
                    img {
                        // max-width: 80px;
                        @include media-breakpoint-down(md) { 
                            width: 60px;
                        }
                        &.--IHERB {
                            width: 53px;
                        }
                        &.--TARGET {
                            width: 75px;
                        }
                        &.--AMAZON {
                            width: 60px;
                        }
                        &.--ANTHROPOLOGIE {
                            width: 140px;
                        }
                    }
                }
            }

            // .deal-status {
            //     font-size:  0.625em;
            //     padding: 2px 10px;
            //     display: inline-block;
            //     text-align: center;
            //     @include media-breakpoint-up(md) { 
            //         font-size:  11px;
            //         padding: 2px 10px;
            //     }
            //     @include media-breakpoint-up(lg) { 
            //         display: block;
            //         max-width: 60%;
            //     }
            //     &.deal-status--claimed {
            //         @include media-breakpoint-up(lg) { 
            //             max-width: 80%;
            //         } 
            //     }
            // }

           

            .deal-points {
                display: block;
                // margin: 0 5px;
                font-size: 0.75rem;
                line-height: 0.8125rem;
                margin-bottom: 5px;
                color: lighten($color: $cl-gray, $amount: 10%) ;
                span {
                    // color: $cl-orange;
                    font-weight: bold;
                }
                .icon-points {
                    margin-left: 4px;
                }
            }
            .card-img_background-image {
                background-size: 60%;
                background-repeat: no-repeat;
                background-position: center;
                height: 200px;
                width: 100%;
            }
            .btn-dp {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0.7em;
                .stock-level {
                    display: inline-block;
                    font-size: 0.7em;
                    margin-left: 5px;
                    opacity: .6;
                }
                &:hover {
                    background-color: $cl-green;
                }
            } 

        }
    }

    .card-body_bottom-items {
        .deal-stock-message {
            font-size: 13px;
            text-align: center;
            margin-bottom: 20px;
            font-weight: bold;
            span {
                color: $cl-red;
            }
        }
        small {
            margin-top: 5px;
            font-size: 10px;
            line-height: 13px;
        }
    }

    .admin_ready-only {
        font-size: 0.625rem;
        line-height: 0.875rem;
        display: none;
        margin: 15px;
        border-radius: 10px;
        background-color: #cce5ff;
        padding: 10px 15px;
        p {
            margin-bottom: 0px;
            color: #4e639e;
        }
        @include media-breakpoint-up(md) { 
            display: block;
        }
        ul {
            list-style-type: none;
            margin: 0;
            padding: 0;
        }
    }
}


.deal-deck {
    .deal-item {
        min-width: 100%;
        display:inline-block;
        @include media-breakpoint-up(lg) { 
            min-width: auto;
            display:inherit;
        }
        > .deal-badge {
            display: none;
            @include media-breakpoint-up(md) { 
                display: flex;
            }
        }
    }
    .card-title {
        color: $cl-green;
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 16px;
        min-height: 70px;
    }
    .deal_content {
        margin-bottom: 18px;
        .deal_content-title {
            font-weight: 700;
            font-size: 0.875rem;
            line-height: 25px;
            color:$cl-gray;
            margin-bottom: 0;
        }
        p {
            font-weight: normal;
            font-size: 0.75rem;
            line-height: 1.125rem;
            color: $cl-gray;
        }
        &.deal_content--reward {
            font-size: 1.5rem;
            font-weight: 500;
            .reimburserment {
                margin-bottom: 12px;
                .dp-points {
                    color: $cl-dark-yellow;
                }
            }
            .disclaimer {
                font-weight: 400;
                font-size: 12px;
                font-style: italic;
                line-height: 16px;
            }
        }
    }
    // .deal-price {
    //     font-size: 0.875rem;
    //     margin-bottom: 15px;
    //     .deal-price--current {
    //         margin-right: 2px;
    //         font-weight: 500;
    //     }
    //     .deal-price--before {
    //         // margin-bottom: 8px;
    //         display: inline-block;
    //         // text-decoration: line-through;
    //     }

    // }
    .card-text {
        font-size: 0.75rem;
        line-height: 1rem;
        &.cart-text_bullet {
            white-space: pre-wrap;
        }
    }
    .deal-item.out-of-stock {
        .card-title {
            color: $cl-gray;
        }
    }
    .deal-item.coming-soon {
        pointer-events: none;  
        .deal-stock-level  {
            display: none;
        }
        &.cool-down {
            opacity: 1 !important;
        }
    }
    .deal-item.claimed {
        &.cool-down {
            opacity: 1 !important;
            pointer-events: inherit;
        }
    }
    .deal-item { 
        .btn-dp.btn-outline {
            font-size: 0.813em;
            padding: 5px;
        }
    }
   
    .deal-stock-level{
        padding: 10px 0 ;
        color: $cl-orange;
        font-size: 90%;
        font-weight: 400;
    }

}

.bonus-deck {
    .bonus-card {
        @include media-breakpoint-down(md) { 
            flex-direction: column;
        }
        @include media-breakpoint-down(xl) { 
            min-height: 470px
        }

        
        .card-sub-title,
        .card-title {
            text-align: center
        }
        .card-body {
            padding: 1.25rem;
            justify-content: initial;
        }
        .card-sub-title {
            font-size: 0.688em;
            font-weight: 500;
            color: lighten($color: $cl-gray, $amount: 30%);
            text-transform: uppercase;
        }
        .card-img_background-image {
            background-size: contain !important;
            margin-bottom: 15px;
            width: 70% !important;
            margin: 0 auto 10px;

        }
        .card-title {
            color: $cl-gray;
            font-size: 1em;
            font-weight: 700;
            // padding: 0 10%;
        }

        img {
            min-height: 150px;
            background: $cl-gray;
            margin-bottom : 16px;
        }
        .bonus-card_point-value {
            color: $cl-green;
        }

        .card-text {
            font-size: 0.875rem;
            line-height: 1.25rem;
        }
    }
    
    .bonus-card_review_bottom-portion {
        .card-subtitle {
            color: $cl-green;
        }
    }
    
    .bonus-card_limited-time {
        box-shadow: none;
        .card-logo {
            text-align: center;
            img {
                width: 20%;
                background-color: transparent;
                height: auto;
                min-height: auto;
                margin-bottom : 16px;
                padding: 10px 0 ;
                @include media-breakpoint-up(md) { 
                    width: 30%;
                }
            }
        }
        .card-body {
            .card-text {
                font-size: 0.75rem;
                line-height: 1.125rem;
                margin-bottom: 40px;
            }
            .card-sub-title {
                opacity: .5;
            }
            .card-title {
                margin-bottom: 20px;
            }
            .card-sub-title,
            .card-title,
            .card-text {
                color: $cl-white;
                letter-spacing: 1.3px;
            }
            button {
                border-radius: 3px;
                padding: 9px 30px;
            }
        }
       
        .card-footer {
            border: none;
            position: absolute;
            bottom: 0;
            width: 100%;
            text-align: center;
            a {
                display: block;
                color: $cl-white;
                font-size: 0.6875rem;

            }
        }
    }

    .bonus-card_amazon {
        $color : #FF9400;
        background-color: #282828;
        text-align: center;
        &:hover {
            button {   
                background-color: darken($color:  $color, $amount: 10%);
               
            }
        }
        .card-text {
            strong {
                color: lighten($color: $color, $amount: 20%);
            }
        }
        .card-logo { 
            margin-bottom: 25px;
        }
        button {
            background-color: $color;
        }
       
    }

    .bonus-card_referral {
        text-align: center;
        a {
            display: block;
        }
        .card-body {
            justify-content: center;
            background-color: lighten($color:$cl-light-green, $amount: 23%);
            background-image: url(../../assets/images/bonus_card-background_invite_mobile.jpg);
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            padding-top: 9%;
            padding-bottom: 21%;
            @include media-breakpoint-up(md) { 
                background-image: url(../../assets/images/bonus_card-background_invite.jpg);
            }
            strong{
                color: #a0d62c;
                font-weight: 600;
            }
        }
    }
}

.rewards-deck {
    .rewards-card {
        @include media-breakpoint-down(md) {
           
            &:nth-child(3n){
                margin-right: $tile_margin !important;
                margin-left: 0;
            }
            
    
        }

        @include media-breakpoint-up(lg) {
           
            &:nth-child(3n +1){
                margin-right: $tile_margin;
                margin-left: $tile_margin;
            }
            &:nth-child(5n){
                margin-right: $tile_margin;
                margin-left: $tile_margin;
            }
    
        }
        &.card {
            box-shadow: none;    
            border: none;
            .card-body {
                display: flex;
                background-color: $cl-gray;
                background-size: 50%;
                background-repeat: no-repeat;
                background-position: center;
                border-radius:5px;
                padding: 2.5% 2.5%;
                justify-content: space-between;
                height: 260px;
                transition: box-shadow .3s ease-out, transform .3s ease-out, opacity .2s ease-out;
                transition-delay: .04s;
                transform: translateZ(0);
                @include media-breakpoint-up(md) { 
                    height: 250px;
                }
                @include media-breakpoint-up(xl) { 
                    height: 200px;
                }
                .rewards-card_selector {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;
                    height: 20px;
                    width: 20px;
                    background-color:$cl-white;
                    .card_selector-indicator {
                        background-color:$cl-light-gray;
                        opacity: 0;
                        border-radius: 50%;
                        display: block;
                        height: 12px;
                        width: 12px;
                        -webkit-transition: opacity .3s ease-out;
                        -moz-transition: opacity .3s ease-out;
                        -o-transition: opacity .3s ease-out;
                        transition: opacity .3s ease-out;
                    }
                }
                
                .rewards-card_value {
                    color: $cl-white;
                    font-size: 1rem;
                    text-align: right;
                }

                &.light {
                    border: 1px solid $cl-light-gray;
                    .rewards-card_selector {
                        border:1px solid $cl-light-gray;
                    }
                    .rewards-card_value {
                        color: $cl-gray;
                    }
                    .card_selector-indicator {
                        background-color: $cl-light-gray !important;
                    }
                }

            }

            .card-footer {
                align-content: center;
                background: transparent;
                border: none;
                display: flex;
                font-size: 0.75rem;
                padding: 2% 3%;
                justify-content: space-between;
                .rewards-card_title {
                    font-weight: 500;
                }
                .rewards-card_redemption {
                    color: $cl-green;
                }
            }

            
            &:hover {
                transform: initial;
                .card-body {
                    box-shadow: $box-shadow-hover;
                    transform: translate(0, -4px);
                }
                .rewards-card_selector {
                    .card_selector-indicator {
                        opacity: 1;
                    }
                }
                
            }
        
           
        }
        
    }

    .rewards-card_referral {
        text-align: center;
        a {
            display: block;
        }
        .card-body {
            background-color: #F7FFF3;
            padding-top: 21%;
            padding-bottom: 21%;
        }
    }
}