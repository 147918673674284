@import '../../assets/styles/partials/_variables.scss';

/*********************
Reward Create

#General
#Card Deck
#Dark Theme
    - Card Deck
*********************/

/*********************
#General
*********************/
.reward_create_card-placeholder {
    display: flex;
    align-items: center;
}

/*********************
#Card Deck
*********************/
.reward_create .rewards-deck{
    flex-direction: column;
    
    .rewards-card.card {
        margin: auto;
        background-color: transparent;
        pointer-events: none;
        min-width: auto;
        width: 100%;
        max-width: 400px;
        .card-body {
            box-shadow: 0 15px 16px -10px rgba(0, 0, 0, 0.6);
            justify-content: flex-end;
            background-color: transparent; 
            height: 245px;
           
        }
        .rewards-card_redemption {
            color: $cl-light-green;
        }
    }
    &.rewards-deck--empty {
        .rewards-card.card {  
            .card-body {
                box-shadow: none;
                border: 2px dashed $cl-light-gray;
                &.light {
                    border: 2px dashed $cl-light-gray !important;
                }
            }    }
    }
    // .btn-danger {
    //     float: right;
    // }
}

/*********************
#Dark Theme
*********************/
$border-color : lighten($color: $cl-dark-purple, $amount: 10%);

.admin_wrapper--dark {
    background-color: $cl-dark-purple;
    /*********************
    - Card Deck
    *********************/
    .reward_create .rewards-deck{
        .rewards-card_title {
            color: $cl-white;
        }
        &.rewards-deck--empty {
            .card-body {
                box-shadow: none;
                border-color:  $cl-gray;
                &.light {
                    border-color:  transparent;
                }
            }
        }
    }
}