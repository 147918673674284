/*********************
Page: Compare

#Page
#Page - Walkthrough
#Page - NDA
#Page - Shipping Address
#Page - Order Number
#Modal - AMZ Request
#Page - Complete
#Product Detail
#Point Status
*********************/
@import '../../assets/styles/partials/variables.scss';


$mobile_form-height: 84vh;

/*********************
#Page
*********************/
body.page_compare {  
    footer {
        margin-top: 30px;
        position: relative;
        @include media-breakpoint-down(md) {
            margin-top: 20px;
        }
    }
    .important-text{
        font-size: 0.75rem;
        line-height: 1.125rem;
    }
}


/*********************
#Product Detail
*********************/

.page_compare {
    .questionnaire form {
        padding-right: 0;
        margin-bottom: 160px;
        // @include media-breakpoint-up(xl) {
        //     margin-bottom: 40px;
        // }
    }
    .tnc-confirmation {
        a {
            margin-left: 20px;
        }
    }
    > form {
        box-shadow: none;
        width: 100%;
        min-width: auto;
        padding: 0;
        @include media-breakpoint-down(sm) {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly; 
        }
    }
    .product-detail {
        position: relative;
        margin-bottom: 26px;
        @include media-breakpoint-down(md) { 
            display: none;
        }
        .product-detail_image {
            // box-shadow: rgba(45, 45, 45, 0.05) 0px 2px 2px, rgba(49, 49, 49, 0.05) 0px 4px 4px, rgba(42, 42, 42, 0.05) 0px 8px 8px, rgba(32, 32, 32, 0.05) 0px 16px 16px, rgba(49, 49, 49, 0.05) 0px 32px 25px;
            padding-right: 0;
            .product-detail_image-wrapper {
                text-align: center;
                width: 80px;
                height: 80px;
                padding:10px;
                .product-image {
                    width: 100%;
                    height: 100%;
                    border-radius: 5px;
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: center;
                }
                @include media-breakpoint-up(md) { 
                    border: 1px solid $cl-light-gray;
                }
            }
            img {
                padding: 10px;
                width: 100%;
                height: auto;
                max-width: 100px;
                max-height: 80px;
                @include media-breakpoint-down(sm) { 
                    border: 1px solid lighten($color: $cl-light-gray, $amount: 30%);
                }
            }
        }
        .product-detail_stock {
           display: flex;
           color: $cl-light-gray;
           align-items: center;
           font-size: 0.75rem;
           font-weight: 500;
           justify-content: flex-end;
        }
        .product-detail_text {
            display: flex;
            justify-content: center;
            flex-direction: column;
            img {
                max-width: 70px;
                margin-bottom: 8px;
                &.ANTHROPOLOGIE {
                    max-width: 150px;
                }
            }
            .product-detail_url {
                color: $cl-gray;
                text-decoration: underline;
                font-size: 0.75rem;
            }
            .lead {
                padding: 0;
                margin-bottom: 0;
            }
        }
    }
    .step-detail {
        display: flex;
        // align-items: center;
        margin-bottom: 52px;
        .step-detail_image {
            @include media-breakpoint-down(sm) { 
                margin-bottom: 15px;
            }
            .step-detail_image-wrapper { 
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
                min-height: 300px;
                padding: 15px;
                box-shadow: rgba(45, 45, 45, 0.05) 0px -1px 2px, rgba(49, 49, 49, 0.05) 0px 3px 3px, rgba(42, 42, 42, 0.05) 0px 8px 8px, rgba(32, 32, 32, 0.05) 0px 16px 16px, rgba(49, 49, 49, 0.05) 0px 32px 25px;
                text-align: center;  
                border-radius: 0.5rem;  
                &.--portrait {
                    
                    min-height: 450px;
                    background-color: white;
                }
                &.--paypal {
                    background-size: 70%;
                }       
                img {
                    width: 65%;
                    max-width: 100%;
                    padding: 0;
                    @include media-breakpoint-up(md) { 
                        width: auto;
                        max-height: 100px;
                        min-height: 320px;
                    }
                }
            }
        }
        .steps_description {
            font-size: 0.9375rem;
            font-weight: 300;
            color: $cl-gray;
            line-height: 1.5rem;
            // text-align: center;
            margin-bottom: 12px;
            @include media-breakpoint-down(md) { 
                font-size: 16px;
           }
           &strong {
            color: darken($color: $cl-black, $amount: 5%)
           }
           &:last-child {
               margin-bottom: 25px;
           }
        }
        .steps_description_footnote {
            font-size: 0.625rem;
            font-weight: 200;
            line-height: 0.8125rem;
            font-style: italic;
            margin: 15px 0 0;
        }
        .steps_number {
            color: $cl-green;
            font-weight: 800;
            font-size: 1.2em;
            @include media-breakpoint-up(md) { 
                margin: 0 auto 5px;
                margin-bottom: 18px;
                text-transform: uppercase;
            }
        }
    }
    .important-msg {
        color: $cl-gray;
        padding: 10px;
        font-size: 12px;
        line-height: 18px;
        font-style: italic;
        margin-bottom: 18px;
        margin-top: 5px;
        background-color: #F8F8F8;
        span {
            &.red {
                color: $cl-red;
            }
        }
    }
    .step-detail--search {
        .keywords {
            @include media-breakpoint-down(sm) { 
                display: none;
            }
            position: absolute;
            top: 60.5%;
            left: 50%;
            -webkit-transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
        }
        &.--category {
            .step-detail_image-wrapper {
                padding: 0;
                background-size: cover;
                background-position: top;
            }
            .category_fake-dropdown {
                background-color: white;
                position: absolute;
                width: 250px;
                top: 113px;
                left: 30px;
                text-align: left;
                padding: 20px 20px 80px;
                &:after  {
                    content: "";
                    position: absolute;
                    top: -10px;
                    left: 110px;
                    border-style: solid;
                    border-width: 0 10px 10px;
                    border-color: #FFFFFF transparent;
                    display: block;
                    width: 0;
                    z-index: 1;
                }
                @include media-breakpoint-up(lg) { 
                    width: 55%;
                    top: 99px;
                    left: 101px;
                    &:after  {
                        top: -10px;
                        left: 85px;
                    }
                }
                @include media-breakpoint-up(xl) { 
                    width: 55%;
                    top: 113px;
                    left: 99px;
                    &:after  {
                        top: -10px;
                        left: 125px;
                    }
                }

             
                h6 {
                    color: #1976d2;
                    text-transform: capitalize;
                    margin-bottom: 16px;
                }
             
                ul {
                    list-style-type: none;
                    margin: 0;
                    padding: 0;
                    color: black;
                    li {
                        display: flex;
                        text-decoration: underline;
                    }
                    .--cursor {

                        background-image: url("https://s3.amazonaws.com/assets.dealpanda.com/category-search_cusor.png");
                        width: 45px;
                        height: 34px;
                        margin-top: -3px;
                        margin-left: 8px;
                        background-size: contain;
                        background-repeat: no-repeat;
                    }

                }
            }
        }
    }
    .step-detail_steps {
        .form-group {
            margin-top:8px;
        }
        @include media-breakpoint-up(md) { 
            padding: 0 35px;
            // margin-top: -20px;
        }
        ul,ol {
            margin-left: 0;
            padding-left: 20px;
            font-weight: 400;
            font-size: 14px;
            li {
                margin-bottom: 8px;
            }
        }

        ol {
            li {
                margin-bottom: 16px;
            }
        }
        
    }
    .step_additiona-note {
        background-color: $cl-light-blue;
        font-size: 0.75rem;
        padding: 15px 20px;
        font-style: italic;
        font-weight: 400;
        line-height: 1.25rem;
        border-radius: 5px;
        margin-bottom: 16px;
        color: #627D88;
    }
    .step-detail--competitors {
        .form-label{ 
            text-align: left;
            margin-bottom: 5px;
        }
        .step-detail_image img {
            padding: 15px;
        }
    }
    .step-detail--last-step {
        max-width: 700px;
        text-align: center;
        justify-content: center;
        flex-direction: column;
        margin: 20px auto 0;
        .steps_description_footnote {
            font-size: 0.625rem;
            font-weight: 200;
            line-height: 0.8125rem;
            font-style: italic;
            margin: 15px 0 0;
        }
        .product-detail_image {
            max-width: 500px;
            margin: 0 auto 30px;
            img {
                height: auto;
                width: auto;
                max-width: 500px;
                max-height: 250px;
                position: relative;
            }
        }
        form {
            box-shadow: none;
            width: 100%;
            min-width: auto;
            padding: 0 20%;
        }
        h1 {
            color: $cl-green;
            font-size: 1.5625rem;
        }
        .see-more {
            padding: 20px 0;
        }
        p.lead {
            margin-bottom: 24px;
            font-size: 18px;
            padding: 0;
        }
        p.title {
            margin-bottom: 0;
        }
     
        ol {
            font-weight: normal;
        }
        .--invite {
            max-width: 400px;
            margin: auto;
        }
        .step-detail--last-step_status {
            background-color: #F8F8F8;
            padding: 10px 24px;
            margin-bottom: 40px;
            > span {
                color: $cl-gray;
                &.paid {
                    color: $cl-green;
                }
            }
        }
        ol {
            text-align: center;
            list-style-position: inside;
        }
    }
   
    .step-detail_cta {
        display: flex;
        flex-direction: column;
        margin-bottom: 24px;
        align-items: center;
        button:not(:last-child) {
            margin-bottom: 16px;
        }
    }
    .instruction-navigation {
        >  div {
            display: flex;
            align-items: center;
        }
        button {
            padding: 11px 5px;
            border: none;
            @include media-breakpoint-down(md) { 
                padding: 10px 5px;
                font-size: 12px;
            }
        }
        .progress {
            $bar-height : 35px;
            width: 100%;
            height: $bar-height;
            .progress-bar {
                height: $bar-height;
            }
            @include media-breakpoint-up(md) { 
                $bar-height : 45px;
                margin-top: 1px;
                height: $bar-height;
                .progress-bar {
                    height: $bar-height;
                }
            }
        }
        .progress-bar_label {
            position: absolute;
            right: 25px;
            font-size: 0.8125rem;
            font-weight: 600;
            color: $cl-gray;
            &.progress-bar_label--complete {
                color: transparent;
            }
        }
    }
}

/*********************
#Page - Walkthrough
*********************/
body.page_compare,
body.page_compare-shipping-address,
body.page_compare-walkthrough,
body.page_compare-request-amz-url,
body.page_compare-nda {
    main {
        height: initial;
        // margin-top: 100px;
    }
 
    .walkthrough_image {
        @include media-breakpoint-up(md) { 
            display: block!important;
        }
  
        .image-tile {
            position: sticky;
            top: 100px;
            border-radius: 1rem;
            background: #FFFFFF;
            box-shadow: 0 4px 8px 0 rgba(0,0,0,0.11);
            padding: 30px;
            margin-bottom: 24px;
            img {
                display: block;
                width: 65%;
                margin: auto;
                margin-bottom: 20px;
            }
            h5 {
                font-size: 1.0625rem;
                font-weight: 600;
                // color: $cl-green;
            }
            h6 {
                padding: 15px 10px ;
                font-size: 20px;
                font-weight: 400;
                text-align: center;
                background-color: lighten($color: $cl-light-gray, $amount: 7%);
            }
            small {
                display: block;
                font-size: 10px;
                margin: 5px auto 0;
                opacity: 0.7;
                text-align: center;
            }
            ul {
                font-size: 0.875rem;
                margin-left: 0;
                font-weight: 400;
                padding-left: 18px;
                color: $cl-gray;
                margin-bottom: 25px;
                // padding-bottom: 10px;
                li {
                    margin-bottom: 2px;
                }
                // border-bottom:1px solid $cl-light-gray;
            }
            .deal-stock-message {
                margin: 16px 0 0;
                font-size: 12px;
                text-align: center;
                span {
                    color: $cl-red;
                    font-weight: bold;
                }
            }
            &.--tall {
                img {
                    width: 100%;
                    max-width:200px;
                }
            }
        }
        // align-items: center;
        justify-content: center;
        
    }

    .walkthrough_content {
        p {
            font-size: 16px;
            line-height: 24px;
        }
        h5 {
            font-weight: 700;
            padding: 0; 
            margin-bottom: 8px;
        } 
        ol,
        ul {
           
            font-size: 16px;
            padding-left: 20px;
            font-weight: normal;
            margin-bottom: 24px;
            li {
                line-height: 1.3rem;
                margin-bottom: 16px;
                line-height: 24px;
                font-size: 0.875rem;
            }
        }
        .content-header {
            img {
                max-width: 100px;
                margin-bottom: 16px;
                &.ANTHROPOLOGIE {
                    max-width: 150px;
                }
            }
            // h1 {
            //     // font-weight: 600;
            //     font-size: 1.600rem;
                
            // }
            margin-bottom: 35px;
        }
        .content-agreement {
            background-color: #F8F8F8;
            padding: 10px;
            .form-group {
                display: flex;
                span {
                    color: $cl-red;
                    margin-right: 5px;
                }
            }
            .form-check {
                line-height: 16px;
            }
            .form-check-label {
                line-height: 18px;
                padding-top: 2px;
            }
        }
        .content-body {
            .content-body--new {
                color: darken($color: $cl-light-blue, $amount: 40%);
                font-weight: 800;
            }
        }
        .bonus-message {
            margin-bottom: 23px;
            > span {
                color: $cl-orange;
                font-weight: 600;
            }
        }
        .btn {
            margin-top: 24px;
        }
        .form-group {
            margin-bottom: 6px;
            a {
                display: inline;
                font-size: 0.750em;
                margin-left: 20px;
                position: relative;
                &::before {
                    content: "";
                    position: absolute;
                    bottom: -5px;
                    height: 2px;
                    width: 100%;
                    background-color: $cl-green;
                }
            }
        }
    }

    .step-detail.walkthrough {
        // transition: all 0.2s ease-in-out;
        &.--blur {
            filter: blur(1px);
    
            /* Firefox version 34 and earlier */
            // filter: url("blur.svg#gaussian_blur");
        
            /* Webkit in Chrome 52, Safari 9, Opera 39, and earlier */
            -webkit-filter: blur(3px);
        }
    }
   
}

/*********************
#Page - Shipping Address
*********************/
body.page_compare-shipping-address {
    .content-header h1 {
        font-size: 35px;
        line-height: 42px;
    }
    .content-agreement {
        // margino
        // border-top: 1px solid $cl-light-gray;
        // background-color: transparent !important;
        padding: 20px !important;
        font-size: 14px !important;
        line-height: 24px;
        font-weight: normal;
        // font-size: 14px;
    }
    
    form select.form-control:not([size]):not([multiple]) {
        height: calc(3rem + 2px);
      
    }
    select {
        &::before {
            right: 5px;
        }
    }
    .btn-dp {
        float: right;
    }
}

/*********************
#Page - Nda
*********************/
body.page_compare-nda {

    .nda-agreement {
        display: flex;
        padding: 20px 0;
        .form-check {
            margin-right: 24px;
        }
    }
   
}

/*********************
#Page - Request AMZ URL
*********************/
.page_compare-request-amz-url {
    .content-header {
        margin-bottom: 16px;
    }
    .how-to-video_wrapper  {
        .btn.btn-link {
            margin-top: 0;
        }
    }
    .invalid-feedback {
        margin-top: 5px;
    }
    .step_help {
        margin-bottom: 24px;
    }
}

/*********************
#Modal - AMZ Request
*********************/

.modal.modal_request-amz {
    overflow-x: hidden;
    overflow-y: auto;
    .modal-header {
        .modal-title {
            margin-bottom: 8px;
            line-height: 2.4rem;
        }
        p {
            margin: 0;
        }
    }
    video {
        border: 1px solid $cl-light-gray;
    }
   
    .amz-profile-instruction  {
        margin: 0;
        .form-group:first-child {
            margin-bottom: 32px;
            width: 100%;
        }
    }
    
    label {
        font-size: 1rem;
        font-weight: bold;
        margin-bottom: 8px;
    }
}

/*********************
#Page - Order Number 
*********************/
body.page_compare-order-number { 

    .questionnaire {
        height: auto
    }
    .form-label {
        margin-bottom: 0 !important;
        font-weight: 600 !important;
    }
    small {
        // margin-top: -2px !important;
        margin-bottom: 8px;
    }
  
    .important-msg  {
        // margin-top: 25px;
        font-size: 11px;
        line-height: 16px;
        background-color: transparent;
        font-weight: 400;
       
    }
    .order-confirmation {
        margin-bottom: 16px;
        .form-group {
            margin-bottom: 0;
        }
        .btn.btn-link {
            font-weight: bold;
            padding: 0;
            font-size: 12px;
            text-decoration: underline;
            font-style: italic;
            margin-bottom: 8px;
            margin-top: 24px;
        }
    }

    .form-control {
        margin-bottom: 6px;
    }

    p.subtitle {
        font-weight: 600;
        margin-bottom: 0;
    }

    .step-detail .step-detail_image   {
        > div {
            position: sticky;
            top: 100px;
        }
        small {
            display: block;
            text-align: right;
            font-style: italic;
            padding: 10px;
        }
        img {
            min-height: initial;
            max-height: initial;
        }
    }

   .need-help {
       margin-top: 16px;
       display: block;
       line-height: 16px;
       padding: 0 5px;
   }

    .order-confirmation.--lookup {
        background-color: #F8F8F8;
        padding: 20px 20px;
        font-size: 14px;
        ol {
            p {
                font-weight: 600;
                margin-left: -15px;
                margin-bottom: 0;
            }
        }
    }

    .tabular-navigation {
        margin-top: 24px;
        button {
            font-weight: bold;
            margin-right: 5px;
            border-radius: 0;;
            padding: 15px;
            font-size: 12px;
            background-color: transparent;
            color: $cl-green;
            &.--active {
                background-color: #F8F8F8;
                color: $cl-gray;
                
            }
            &.btn-success:not(:disabled):not(.disabled):active {
                background-color: #F8F8F8;
                color: $cl-gray;
            }

        }
    }

    .tabular-content {
        background-color: #F8F8F8;
        padding: 24px;
        p {
            font-size: 0.875rem;
            line-height: 20px;
        }
        .form-label {
            font-size: 14px;
        }
        .form-group {
            margin-top: 0;
        }
        li {
            line-height: 18px;
        }
        small {
            font-size: 12px;
        }
    }


}

/*********************
#Page - Complete
*********************/
body.page_compare-complete {
    .deal_compare {
        margin-top: 50px;
    }
}

/*********************
#Page - Compare Competitor
*********************/
body.page_compare-compare-competitor {
    // .compare-competitor_highlight-product {
    //     height: 300px;
    //     width: 200px;
    //     position: absolute;
    //     z-index: 9999;
    //     background-size: contain;
    //     background-repeat: no-repeat;
    //     background-position: center;
    //     right: -20px;
    //     top: -20px;
    //     border: 2px solid $cl-gray;
    // }
    .compare-competitor_text-orange {
        color: #c45500;
    }

    .step-detail .step-detail_image .step-detail_image-wrapper {
        display: flex;
        @include media-breakpoint-up(xl) { 
            align-items: center;
        }
        position: relative;
        p {
            font-weight: 600;
            text-align: left;
            font-size: 12px;
            
            @include media-breakpoint-up(xl) { 
                margin-bottom: 18px;
            }
            color: #c45500;

        }
        .compare-competitor_main-image {
            position: absolute;
            top: 60px;
            @include media-breakpoint-up(xl) { 
                top: 75px;
            }
            left: 50%;
            -webkit-transform: translateX(-50%);
            -ms-transform: translateX(-50%);
            transform: translateX(-50%);
            height: 250px;
            width: 180px;
            background-size: 95%;
            background-position: top center;
            background-repeat: no-repeat;
            background-color: white;
            border: 1px solid $cl-light-gray;
            border-radius: 5px;
            box-shadow: rgba(45, 45, 45, 0.05) 0px -1px 2px, rgba(49, 49, 49, 0.05) 0px 3px 3px, rgba(42, 42, 42, 0.05) 0px 8px 8px, rgba(32, 32, 32, 0.05) 0px 16px 16px, rgba(49, 49, 49, 0.05) 0px 32px 25px;

        }
        img {
            border: 1px solid $cl-light-gray;
            padding: 10px;
            max-height: initial;
            min-height: initial;
            opacity: 0.4;
        }
    }
}



/*********************
#PointStatus
*********************/
.page_compare .point-status {
    display: inline-block;
    border: none;
    padding: 0;
    margin: 0 0 5px;
    &.point-status_step-2 {
        display: flex;
        align-items: center;
        .additiona-points_title {
            color: $cl-green;
            margin-right: 10px;
        }
        .additional-points_value {
            color: $cl-green;
        }
    }
    .point_status-wrapper {
        justify-content: flex-start;
        color:$cl-light-gray;
    }
    .icon-points {
        font-size: 1.5625rem;
        margin-right: 5px;
    }
}
