@import '_variables.scss';
@import "../../../node_modules/react-table/react-table.css";

/*********************
Table

#ReactTable
*********************/


/*********************
ReactTable
*********************/
.ReactTable {
    // box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    .rt-thead {
        text-align: left;
        padding: 10px 0px;
    }
    .rt-td {
        padding: 5px 20px;
    }
    .-pagination {
        .-next,
        .-previous {
            button {
                background-color: $cl-green;
                color: $cl-white;
                &:not([disabled]):hover {
                    background-color: $link-hover;
                }
            }
        }
        .-btn[disabled] {
            background-color: $cl-light-gray;
        }
    }
}