@import '../../assets/styles/partials/variables.scss';

/*********************
How To Video

#General
*********************/

/*********************
General
*********************/
.how-to-video_wrapper {
    button {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.875rem; 
        margin: auto;
    }
    i {
        margin-right: 2px;
    }
    
}
