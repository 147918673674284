/*********************
Page: FAQ

#Body
#Form
#Modal
*********************/
@import '../../assets/styles/partials/_variables.scss';

/*********************
#Body
*********************/
.page-contact {
    form {
        margin: auto 20px;
        width: 100%;
    }
    .row.form-wrapper {
        justify-content: center;
    }
}