@import '_variables.scss';

/*********************
form
#Custom Radio
*********************/
form {
    .form-control {
        padding: 15px 12px;
        margin-bottom: 10px;
        height: 50px;
        &:last-child {
            margin-bottom: 5px;
        }
    }

    select {
        &.form-control:not([size]):not([multiple]) {
            height: calc(3.2rem + 2px);
        }
        
    }

    textarea.form-control {
        padding-top: 5px;
        height: auto;
    }
    label {
        margin-bottom: .1rem;
        font-size: 0.750em;
    }
    &.container--narrow  {
        margin: auto;
        @include media-breakpoint-up(md) { 
            max-width: 35rem;
        }
    }
    input {
        &::-webkit-input-placeholder {
            color: lighten($cl-gray, 40%) !important;
        }
         
        &:-moz-placeholder { /* Firefox 18- */
            color: lighten($cl-gray, 40%) !important;  
        }
         
        &::-moz-placeholder {  /* Firefox 19+ */
            color: lighten($cl-gray, 40%) !important;  
        }
         
        &:-ms-input-placeholder {  
            color: lighten($cl-gray, 40%) !important;  
        }
    }
    .invalid-feedback {
        margin-top: -5px;
        font-size: 12px;
        line-height: 1.125rem;
    }
    .was-validated .form-control:invalid {
        border-color: $cl-red;
    }

    textarea {
        height: auto;
    }
}

.input-group-append {
    button {
        padding: 0.8rem 1.3rem;
    }
}

.form--float {
    .form_wrap {
        padding: 0 50px
    }
    .btn-dp[type="submit"] {
        border-radius: 0 0 5px 5px ;
        padding: 1.2rem 1.3rem;
        margin:0 ;
    }
}

/*********************
#Custom Text Area
*********************/
.textarea-count {
    font-size: 11px;
    text-align: right;
    &.--success {
        color: $cl-green;
    }
}


/*********************
#Custom Radio
*********************/
.custom-radio {
    input[type='radio']:after {
        width: 30px;
        height: 30px;
        border-radius: 30px;
        top: -6px;
        left: -2px;
        position: relative;
        background-color: white;
        content: '';
        display: inline-block;
        visibility: visible;
        border: 2px solid $cl-green;
    }
    
    input[type='radio']:checked:after {
        width: 30px;
        height: 30px;
        border-radius: 30px;
        top: -6px;
        left: -2px;
        position: relative;
        background-color: $cl-white;
        content: '';
        display: inline-block;
        visibility: visible;
        border: 2px solid $cl-green;
    }

    input[type='radio']:checked:before {
        width: 19px;
        height: 19px;
        border-radius: 20px;
        top: -1px;
        left: 3px;
        position: absolute;
        background-color: $cl-green;
        content: '';
        display: inline-block;
        visibility: visible;
        border: 2px solid $cl-green;
        z-index: 2;
    }
    label {
        margin-left: 15px;
        font-size: 16px;
        color: $cl-green;
    }
}
