/*********************
Footers

#Footer
*********************/

@import '../../assets/styles/partials/_variables.scss';

/*********************
Footer
*********************/
.footer {
    display: flex;
    color: $cl-white;
    align-items: center;
    background-color: $cl-green;
    bottom: 0;
    font-size: .65em;
    padding: 10px;
    position: absolute;
    width: 100%;
    img {
        width: 100px;
        opacity:.3;
        margin-bottom:20px;
    }
    .footer_link {
        line-height: 1.3em;
        margin-bottom: 5px;
        a {
            color: $cl-white;
            margin-right: 10px;
            font-weight:500;
        }
    }
    .footer_copyright {
        color: $cl-white;
        line-height: 1em;
        font-size: 0.65rem;
        opacity: .5;
        @include media-breakpoint-down(sm) { 
            margin-bottom: 0;
        }
    }
    .brand_footer {
        svg {
            width:100px;
        }
    }
    
    @include media-breakpoint-up(md) { 
        margin-top: 28px;
    }
}