@import '../../assets/styles/partials/_variables.scss';

/*********************
General
*********************/
.deals_manage{
    .table-search_wrapper .table-search_filter-wrapper {
        padding-top: 0;
    }
    .table {
        tbody  {
        }
        .table-date {
            max-width: 100px;
            > p {
                color: $cl-white;
                line-height: 16px;
            }
        }
        .table-data_order-number {
            min-width: 160px;
            font-size: 12px;
        }
        .table-data_screenshot {
            button {
                background-size: cover;
                max-height: 50px;
                border: none;
                color: white;
                padding: 20px;
                text-align: center;
                text-decoration: none;
                display: inline-block;
                font-size: 16px;
                margin: 4px 2px;
                border-radius: 50%;
                box-shadow: 0 4px 5px 0 rgba(0,0,0,0.90);
                transition:  all 0.3s ease-in-out;
                &:hover {
                    opacity: 0.8;
                }
            }
           
        }
    }
    .alert-warning {
        a {
            color: #856404 !important; 
        }
    }
    .deals_manage_product {
        position: sticky;
        top: 15px;
        padding: 20px;
        .product_image {
            padding: 20px;
            border-radius: 5px;
            background-color: white;
            margin-bottom: 24px;
            img {
                width: 100%;
            }    
        }
        .product_detail {
            list-style-type: none;
            padding: 0;
            margin: 0;
            .product_detail-item {
                font-size: 12px;
                line-height: 20px;
                margin-bottom: 16px;
                font-weight: normal;
                .item-title {                    
                    line-height: 1rem;
                    margin-bottom: 6px;
                    
                }
                img {
                    max-width: 70px;
                }
            }
        }
        
    }
}

.modal.deal-manage_qanda {

    .deal-manage_qanda-wrapper {
        overflow: auto;
        font-size: 0.875rem;
        color: $cl-black;
        line-height: 1.125rem;
        margin-bottom: 20px;
        > div {
            margin-bottom: 20px;
        }
        .question {
            font-weight: bold;
            text-transform: capitalize;
            margin-bottom: 6px;
        }
        .answer {
            font-weight: normal;
            color: $cl-gray;
            font-style: italic;
        }
    }
    .modal-footer {
        flex-direction: column;
        border-top: 0;
        .btn-primary {
            margin-right: 0;
            margin-bottom: 8px;
        }
        .btn-link {
            margin-left: 0;
        }
    }
}
.modal.amazon_reimbursement {
    .modal-header {
        display: block;
        text-align: center;
        padding-bottom: 0;
        .modal-title {
            font-size: 16px;
            color: lighten($color: $cl-gray, $amount: 20%);
        }
    }
    .modal-content {
        padding: 30px 40px;
    }
    .modal-footer {
        border-top: 0;
        flex-direction: column;
        .btn-primary {
            margin-bottom: 16px;
            margin-right: 0;
        }
    }
    .modal-body {
        padding-top: 0;
    }
    .reimbursement-amount {
        font-size: 40px;
        text-align: center;
        margin-bottom: 15px;
    }
    .reimbursement-breakdown {
        position: relative;
        padding: 13px;
        background-color: lighten($color: $cl-light-gray, $amount: 5%);
        margin-bottom: 10px;
        &:before {
            content: "";
            position: absolute;
            top: -14px;
            left: 50%;
            margin-left: -15px;
            width: 0; 
            height: 0; 
            border-left: 15px solid transparent;
            border-right: 15px solid transparent;
            
            border-bottom: 15px solid lighten($color: $cl-light-gray, $amount: 5%);
        }
        .reimbursement-breakdown_item {
            display: flex;
            justify-content: space-between;
            padding: 8px 0;
        }

        // background-color: lighten($cl-light-gray, 30%);
    }
    .reimbursement-breakdown_additional-info {
        // border-top: 1px solid lighten($cl-light-gray, 30%);
        padding: 0 12px;
        .reimbursement-breakdown_item {
            display: flex;
            justify-content: space-between;
            padding: 8px 0;
        }
        .item-lable {
            min-width: 125px;
        }
        .item-email {
            font-size: 12px;
            word-break: break-word;
            text-align: right;
        }
        .shipping {
            font-weight: normal;
            // padding: 10px ;
            margin-bottom: 0;
            text-align: center;
            // border: 1px solid lighten($cl-light-gray, 30%);
            color: $cl-green;
            &.not-shipped {
                color: $cl-red;
            }
        }
    }
  
    &.--error {
        .modal-title  {
            font-size: 24px;
            color: $cl-gray;
            margin-bottom: 24px;
        }
        .modal-content  {
            font-style: 1rem;
            text-align: center;
            font-style: italic;
            margin-bottom: 0;
            p {
                font-size: 16px;
                margin-bottom: 0;
            }
        }
        .modal-footer {
           flex-direction: column;
           .btn-dp {
               margin-bottom: 16px;
           }
        }
    }
}


/*********************
Dark Mode
*********************/
.admin_wrapper--dark .deals_manage{
    .deals_manage_product {
        .product_detail {
            .product_detail-item {
                color: $cl-white-60;
                .item-title {
                    color: $cl-white-86;
                }
                &.--retail-logo {
                    img {
                        background: white;
                        padding: 5px 10px;
                        border-radius: 5px;
                    }
                }
            }
        }
    }
}