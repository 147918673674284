@import '../../assets/styles/partials/_variables.scss';

/*********************
Redeem

#General
#Redemption Modal
*********************/


/*********************
#General
*********************/

.page_redeem {
    .point-status {
        box-shadow: 0 7px 10px rgba(45,45,45,.2);
    }
    .rewards-deck {
        display: flex;
        align-content: center;
        margin: 0 auto 100px;
    }
}

