@import '../../assets/styles/partials/_variables.scss';

/*********************
Side Bar

#Primary Nav
#Sub Nav
#Footer Nav
#Button 
#Dark Theme
*********************/

/*********************
#Primary Nav
*********************/

.side-bar_nav {
    letter-spacing: 1px;
    h6 {
        padding: 5px 25px;
        opacity: .4;
        text-transform: uppercase;
        font-size: 0.8125rem;
        font-weight: 400;
    }
    .list-group {
        width: 100%;
        padding: 20px 0 ;
    }   
    .list-group-item {
        background-color: transparent;
        padding: 0;
        border: 0;
        margin-bottom: 1px;
        > a {
            align-items: center;
            display: flex;
            background-color: transparent;
            color: $cl-gray;
            font-size: 0.8125rem;
            line-height: 1.2rem;
            position: relative;
            padding: 5px 25px;
            &:before {
                content: "";
                position: absolute;
                top: 0 ;
                left:0;
                width: 0;
                height: 100%;
                background : transparent;
                -webkit-transition: all 0.5s ease;
                -moz-transition: all 0.5s ease;
                -o-transition: all 0.5s ease;
                -ms-transition: all 0.5s ease;
                transition: all 0.5s ease;
            }
            @include media-breakpoint-up(md) {
                font-size: 0.75rem;
            }
            @include media-breakpoint-up(lg) {
                font-size: 0.875rem;
            }
            .small {
                margin-left: 5px;
                opacity: 0.7;
            }
            &:hover {
                color: $cl-light-green;
                text-decoration: none;
                background-color: #f9f9f9;
                &:before {
                    width: 10px;
                    background-color: $cl-light-green;
                }
            }
        }
        &.active {
            $activeColor: $cl-light-green;
            background-color: transparent;
            > a {
                color: $activeColor;
                &:before {
                    background: $activeColor;
                    width: 10px;
                }
            }
        }
    }
}


/*********************
#Sub Nav
*********************/
.side-bar_nav--level-2 {
    padding: 20px 0;
    display: flex;
    justify-content: center;
}

/*********************
#Button
*********************/
.btn-back {
    display: inline-block;
    color: $cl-gray;
    
    span {
        flex-direction: column;
        display: flex;
        align-items: center;
        svg{
            -webkit-transition: all 0.5s ease;
            -moz-transition: all 0.5s ease;
            -o-transition: all 0.5s ease;
            -ms-transition: all 0.5s ease;
            transition: all 0.5s ease;
           
        }
        margin-bottom: 10px;
    }
    &:hover {
        text-decoration: none;
        svg{ 
            color: darken($color: $cl-gray, $amount: 5%);
            -webkit-filter: drop-shadow(5px 0px 2px rgba(0, 0, 0, 0.2));;
            margin-left:-10px;
            filter: drop-shadow(5px 0px 2px rgba(0, 0, 0, 0.2));;
        }
    }
}

/*********************
#Footer Nav
*********************/
.side-bar_footer {
    padding: 20px;
    display: flex;
    .toggle-switch {
        align-items: center;
        display: flex;
        justify-content: center;
        margin: auto;
        .react-switch-bg{
            background-color: rgba($color: #000000, $alpha: .2) !important;
        }
        .react-switch-handle {
            background-color: $cl-light-gray !important;
        }

        .toggle_switch_label {
            color: $cl-light-gray;
            font-style: italic;
            font-size: 0.625rem;
            margin-left: 8px;
        }
       
        &.dark {
            .toggle_switch_label {
                color: $cl-light-green;
            }
            .btn {
                &.active {
                    background-color: $cl-light-green;
                }
            }
        }
    }
}

/*********************
#Dark Theme
*********************/
.admin_wrapper--dark {
    h6 {
        color: $cl-white;
    }
    .btn-back {
        color: $cl-light-green;
        &:hover {
            svg{ 
                color: rgba($color: $cl-light-green, $alpha: .8);
                -webkit-filter: drop-shadow(3px 8px 5px rgba(0, 0, 0, 0.7));
                filter: drop-shadow(3px 8px 5px rgba(0, 0, 0, 0.7));
            }
        }
    }
    .side-bar_nav {
        .list-group-item {
            padding: 0;
            border: 0;
            > a {
                color: $cl-white;
                &:hover {
                    background: darken($color: $cl-dark-purple, $amount: 10%)
                }
            }
            &.active {
                > a {
                    color: $cl-light-green;
                    &:hover {
                        background: darken($color: $cl-dark-purple, $amount: 10%)
                    }
                }
               
            }
        }
        
    }

    .toggle-switch {
        .react-switch-handle {
            background-color: $cl-light-green !important;
        }
    }
}